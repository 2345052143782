.arizona-landing {
  .main {
    display: flex;
    flex-direction: column;
    padding: 0;
    height: 100vh;
  }
  .container--language {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    h3 {
      font-size: 1.5em;
    }
  }
  .hero {
    height: 40vh;
    padding-bottom: 0;
    .hero__background {
      background-position: 15% center;
    }

    @media #{$large} {
      height: 400px;
    }

    @media #{$massive} {
      height: 45vh;
    }
  }
  .buttons--language {
    a {
      border-color: $accent;
      color: $accent;
    }
  }
  .logo {
    fill: $white;
  }
}

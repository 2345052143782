
/*============================================================================
  FORMS
==============================================================================*/

form {
  margin: 0;
}

legend {
  border: 0; padding: 0; white-space: normal; *margin-left: -7px;
  font-size: 18px;
}

button, input, select, textarea, .button {
  margin: 0; vertical-align: baseline; *vertical-align: middle;
  transition: $transitionDefault;
}

button, input {
  line-height: normal;
}

button, select {
  text-transform: none;
}

// When having a body font-size < 16px
// Prevent zoom on touch devices in active inputs
// @media #{$maxMedium} {
//   input, textarea {
//     font-size: 16px;
//   }
// }

label, .label, input, select, textarea {
  @include default-font();
  vertical-align: middle; color: $black;
}


label, .label {
  display: block; margin-bottom: 8px;
}

button, input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: button;  cursor: pointer; *overflow: visible; border: none;
}

input[type="checkbox"], input[type="radio"] {
  cursor: pointer;
}

input[type="text"], input[type="search"], input[type="password"],
input[type="email"], input[type="tel"], input[type="date"], input[type="number"], textarea, select {
  padding: 6px 14px;
  margin-bottom: 0;
  display: block;
  background: #fff;
  height: 40px;
  line-height: inherit;
  width: 100%;
  border: 1px solid $accent;
  border-radius: 5px;
  @include fs(14);
  color: $accent;

  &:focus {
    outline: 0;
    border-color: $accent;
  }
}

input[type="email"] {
  display: block;
}

textarea {
  overflow: auto;
  vertical-align: top;
  resize: none;
  width: 100%;
  height: 125px;
}

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0; padding: 0;
}

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  width: auto;
  height: auto;
  padding: 0;
  margin: 3px 0;
  *margin-top: 0;
  *height: 13px;
  *width: 13px;
  line-height: normal;
  border: none;
}

input[type="file"] {
  background-color: #fff;
  padding: initial;
  border: initial;
  line-height: initial;
}

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}

// Buttons
input[type="reset"], input[type="submit"], .button {
  float: left;
  display: block;
  clear: both;
  transition: $transitionDefault;
  outline: 0;
  line-height: 1.2em;
  padding: 10px 20px;
  margin: 0;
  background-color: transparent;
  text-decoration: none;
  text-transform: lowercase;
  color: $accent;
  @include roboto-slab;
  font-weight: 700;
  letter-spacing: 2px;
  @include fs(14);
  border-radius: 5px;
  border: 2px solid $accent;

  .icon-chevron-right {
    display: inline-block;
    transition: $transitionDefault;
  }

  &:hover, &:focus, &:active {
    background-color: transparent;

    .icon-chevron-right {
      transform: translateX(3px);
    }
  }

  &:active {
    box-shadow: inset 0 1px 2px rgba(0,0,0,0.2);
  }

  &::-moz-focus-inner {
    border: 0;
  }

  .text-center & {
    float: none;
    display: inline-block;
  }
}

.file-input {
  position: relative;

  &:focus {
    outline: 0;
    border-color: $accent;
  }

  label {
    position: relative;
    display: block;
    padding: 10px 15px 10px 65px;
    margin: 0;
    cursor: pointer;
    border: 1px solid $accent;
    border-radius: 5px;
    background: white;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 60px;
      background: url("../images/upload.svg") center no-repeat;
      background-size: 13px;
      border-radius: 5px;
    }
  }

  input[type="file"] {
    position: absolute;
    top: 1px;
    left: 1px;
    width: 1px;
    height: 1px;
    z-index: -1;
    outline: 0;
  }
}

button, input[type="text"], input[type="submit"], input[type="button"], input[type="reset"],
input[type="email"], input[type="tel"], input[type="number"], textarea {
  -webkit-appearance: none;
}

button, input[type="reset"], input[type="submit"], .button, a.button, select {
  &:focus {
    outline: 0; *outline: inherit;
  }
}

.button--divided {
  padding: 0;

  a {
    text-decoration: none;
    padding: 10px 20px;
    line-height: 1.2em;
    display: inline-block;
    float: left;
  }
}

.button--download {
  background-color: $accent;
  height: 50px;
  display: inline-block;
  color: $white;
  @include display-font;
  padding: 15px 25px;
  border-radius: 5px;
  text-transform: lowercase;
  text-decoration: none;
  margin-bottom: 30px;
  transition: $transitionDefault;

  span {
    color: $white;
    vertical-align: middle;
  }

  .icon {
    fill: white;
    width: 12px;
    height: 16px;
    margin-left: 5px;
  }

  &:hover, &:focus {
    opacity: 0.8;
  }
}

::placeholder {
  color: $accent;
}

// Hide clear text field ie 10+
::-ms-clear { display: none; }

// Remove the rounded corners
input[type="search"] { -webkit-appearance: none; }

// Hide the cancel button
::-webkit-search-cancel-button { -webkit-appearance: none; }

// Hide the magnifying glass
::-webkit-search-results-button { -webkit-appearance: none; }

::-webkit-textfield-decoration-container { }

// Hide number input spinner
::-webkit-inner-spin-button { -webkit-appearance: none; }
::-webkit-outer-spin-button { -webkit-appearance: none; }

// Style file upload button
::-ms-browse, ::-webkit-file-upload-button { }

.checkbox, .radio {
  position: relative;

  label {
    padding-left: 35px;
    cursor: pointer;
    @include fs(14);

    &:before, &:after {
      content: "";
      display: block;
      position: absolute;
      transition: $transitionDefault;
    }

    &:before {
      left: 0; top: 0;
      width: 20px; height: 20px;
      border: 1px solid $accent;
    }

    &:after {
      top: 2px; left: 6px;
      border: solid $accent;
      border-width: 0 3px 3px 0;
      width: 8px; height: 13px;
      transform: rotate(45deg);
      opacity: 0;
    }
  }

  input[type="checkbox"], input[type="radio"] {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    visibility: hidden;

    &:checked + label {
      &:before {
        border-color: $accentHover;
      }

      &:after {
        opacity: 1;
      }
    }
  }
}

.checkbox {
  label {
    &:before {
      border-radius: 5px;
    }
  }
}

.checkbox-other {
  input {
    background-color: transparent;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid $accent;
  }
}

.radio {
  label {
    &:before, &:after {
      border-radius: 100%;
    }

    &:after {
      border: 0;
      transform: none;
      top: 4px; left: 4px;
      width: 12px; height: 12px;
      background-color: $accentHover;
    }
  }
}

.grid__item > .radio:last-child label {
  margin-bottom: 0;
}

// Forms
form {
  .row {
    margin-bottom: 0;
  }
}

fieldset {
  width: auto;

  p, ul, li {
    @include clearfix;
  }

  ul {
    margin-left: -20px;

    li {
      list-style: none;
      background: none;
      margin: 0 0 20px;
      float: left;
      width: 100%;
      padding: 0 0 0 20px;

      &.error {
        input {
          border-color: $error;
        }
      }

      &.space {
        margin-top: 25px;
      }

      @media #{$medium} {
        &.one-half { width: 50%; }
        &.one-fourth { width: 25%; }
      }
    }
  }
}

.buttons {
  @include clearfix;

  button, input[type="reset"], input[type="submit"], .button, a.button {
    float: left;
    clear: none;
    margin-right: 15px;

    &.right {
      float: right;
      margin-right: 0;
      margin-left: 15px;
    }
  }
}

.buttons--language {
  text-align: center;

  a.button,
  .button {
    @include roboto-mono;
    font-weight: 700;
    @include fs(18);
    text-transform: none;
    letter-spacing: 0;
    padding: 10px 12px;
    color: $white;
    border-color: $white;
    float: none;
    display: block;
    vertical-align: top;
    width: 175px;
    margin: 0 auto 10px;
  }

  .button--divided {
    padding: 0;
    width: 175px;

    a {
      padding: 10px 10px;
      position: relative;
      color: $white;
      width: 170px;
      float: none;

      + a:before {
        content: '';
        position: absolute;
        width: 18px;
        height: 2px;
        background: $white;
        top: 1px;
        left: 74px;
      }

      &:first-child {
        padding-left: 12px;
      }

      &:last-child {
        padding-right: 15px;
      }
    }
  }

  span {
    vertical-align: middle;
  }

  .icon-flag {
    width: 15px;
    height: 15px;
    vertical-align: middle;
  }

  @media #{$medium} {
    a.button,
    .button {
      width: auto;
      display: inline-block;
      margin: 0 6px 10px 0;
    }

    .button--divided {
      a {
        float: left;
        width: auto;

        + a:before {
          width: 2px;
          height: 18px;
          top: 13px;
          left: -2px;
        }
      }
    }
  }
}

.a-c {
  .button, a.button, button {
    float: none; clear: none; display: inline-block;
  }
}

// Recaptcha
.grecaptcha-badge {
  display: none;
}

// Newsletter form
.newsletter {
  margin-bottom: 30px;
  @include clearfix;
  max-width: 400px;

  label {
    color: $accent;
    margin-bottom: 22px;
  }

  input {
    float: left;
    width: calc(100% - 40px);
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-width: 1px;
    border-right: none;
    border-color: $accent !important;
    color: $accent !important;
  }

  button {
    background-color: $accent;
    width: 40px;
    height: 40px;
    line-height: 0;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    float: right;

    .icon {
      fill: $white;
      width: 14px;
      height: 14px;
    }

    &:hover, &:focus {
      background-color: $accentHover;
    }
  }

  ::placeholder {
    color: $accent !important;
  }
}



// Little miracle form
.little-miracle-form {
  $paddingSize: 32px;
  color: $accent;

  input {
    @media #{$medium} {
      padding: $paddingSize;
    }
  }

  select {
    @media #{$medium} {
      height: calc(1.4rem + #{$paddingSize} * 2);
      text-indent: $paddingSize;
      padding: 0;
    }
  }

  .btn--green {
    background-color: $jade;
    border: none;
    width: 100%;
    color: $white;
    &:hover {
      background-color: darken($jade, 7%);
    }
  }

  .form__terms-conditions {
    padding-left: $paddingSize;
    font-size: .9em;
  }
}

.js-expand-form {
  text-decoration: underline;
  font-weight: 700;
  font-size: 1.2em;
  cursor: pointer;
  margin: 0;
}
.form__item--optional {
  display: none;
}

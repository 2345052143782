/**
 * OVERVIEW
 *
 * IMPORTS
 * reset, vars, mixins, core, plugins
 *
 * LAYOUT
 * Helpers
 * Header / Main / Footer
 * Components
 * Pages (page overrides)
 *
 * Hooks (IE, ...)
 * Print
 */
/**
 * GUIDELINES
 * CLASSES
 * Naming: BEM - http://getbem.com/
 * Abbreviations: Emmet - http://docs.emmet.io/cheat-sheet/ | example: .va-m
 *
 * MEDIAQUERIES
 * @media $small, $medium, $maxMedium, $large, $larger, $huge, $massive
 */
/* =============================================================================
   IMPORTS
   ========================================================================== */
/*============================================================================
  RESET

  Adapted version of:
  - Eric Meyer reset
  - Boilerplate Normalize.css
  - Normalize opentype
==============================================================================*/
html {
  overflow-y: scroll;
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%; }

html, body {
  margin: 0;
  padding: 0; }

h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, cite, code, del,
dfn, em, img, q, s, samp, small, strike, sub, sup, tt, var, dd, dl, dt, li, ol, ul, fieldset,
form, label, legend, button, table, caption, tbody, tfoot, thead, tr, th, td {
  margin: 0;
  padding: 0;
  border: 0;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  line-height: 1;
  font-family: inherit; }

article, aside, details, figcaption, figure, footer, header, hgroup, nav, time, section, main {
  display: block; }

*, *:before, *:after {
  box-sizing: border-box; }

audio, canvas, video {
  display: inline-block; }

audio:not([controls]) {
  display: none; }

a:focus {
  outline: thin dotted; }

ol, ul {
  list-style: none; }

q:before, q:after, blockquote:before, blockquote:after {
  content: ""; }

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

figure {
  margin: 0; }

img {
  border: 0;
  -ms-interpolation-mode: bicubic; }

svg:not(:root) {
  overflow: hidden; }

abbr {
  text-transform: uppercase; }

time {
  display: block; }

button, input, select, textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline; }

button, input {
  line-height: normal; }

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0; }

button, input[type="button"], input[type="reset"], input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button; }

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box; }

input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

textarea {
  overflow: auto;
  vertical-align: top; }

/*============================================================================
  VARIABLES
==============================================================================*/
@media (min-width: 480px) {
  .init-mediaqueries {
    margin: 0; } }

@media (min-width: 768px) {
  .init-mediaqueries {
    margin: 0; } }

@media (min-width: 1024px) {
  .init-mediaqueries {
    margin: 0; } }

@media (min-width: 1200px) {
  .init-mediaqueries {
    margin: 0; } }

@media (min-width: 1400px) {
  .init-mediaqueries {
    margin: 0; } }

@media (min-width: 1680px) {
  .init-mediaqueries {
    margin: 0; } }

/*============================================================================
  MIXINS
==============================================================================*/
.clearfix {
  *zoom: 1; }
  .clearfix:before, .clearfix:after {
    display: table;
    content: " "; }
  .clearfix:after {
    clear: both; }

.ir {
  display: block;
  border: 0;
  text-indent: -999em;
  overflow: hidden;
  background-color: transparent;
  background-repeat: no-repeat;
  text-align: left;
  direction: ltr; }

.hidden {
  display: none !important;
  visibility: hidden; }

.vh {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.vh.focusable:active, .vh.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto; }

.invisible {
  visibility: hidden; }

/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);
  or object-fit and object-position:
  @include object-fit(cover, top);
*/
/*
==============================================
CSS3 ANIMATION CHEAT SHEET
==============================================

Made by Justin Aguilar

www.justinaguilar.com/animations/

Questions, comments, concerns, love letters:
justin@justinaguilar.com
==============================================
*/
/*
==============================================
slideDown
==============================================
*/
.slideDown {
  animation-name: slideDown;
  -webkit-animation-name: slideDown;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important; }

@keyframes slideDown {
  0% {
    transform: translateY(-100%); }
  50% {
    transform: translateY(8%); }
  65% {
    transform: translateY(-4%); }
  80% {
    transform: translateY(4%); }
  95% {
    transform: translateY(-2%); }
  100% {
    transform: translateY(0%); } }

@-webkit-keyframes slideDown {
  0% {
    -webkit-transform: translateY(-100%); }
  50% {
    -webkit-transform: translateY(8%); }
  65% {
    -webkit-transform: translateY(-4%); }
  80% {
    -webkit-transform: translateY(4%); }
  95% {
    -webkit-transform: translateY(-2%); }
  100% {
    -webkit-transform: translateY(0%); } }

/*
==============================================
slideUp
==============================================
*/
.slideUp {
  animation-name: slideUp;
  -webkit-animation-name: slideUp;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important; }

@keyframes slideUp {
  0% {
    transform: translateY(100%); }
  50% {
    transform: translateY(-8%); }
  65% {
    transform: translateY(4%); }
  80% {
    transform: translateY(-4%); }
  95% {
    transform: translateY(2%); }
  100% {
    transform: translateY(0%); } }

@-webkit-keyframes slideUp {
  0% {
    -webkit-transform: translateY(100%); }
  50% {
    -webkit-transform: translateY(-8%); }
  65% {
    -webkit-transform: translateY(4%); }
  80% {
    -webkit-transform: translateY(-4%); }
  95% {
    -webkit-transform: translateY(2%); }
  100% {
    -webkit-transform: translateY(0%); } }

/*
==============================================
slideLeft
==============================================
*/
.slideLeft {
  animation-name: slideLeft;
  -webkit-animation-name: slideLeft;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes slideLeft {
  0% {
    transform: translateX(150%); }
  50% {
    transform: translateX(-8%); }
  65% {
    transform: translateX(4%); }
  80% {
    transform: translateX(-4%); }
  95% {
    transform: translateX(2%); }
  100% {
    transform: translateX(0%); } }

@-webkit-keyframes slideLeft {
  0% {
    -webkit-transform: translateX(150%); }
  50% {
    -webkit-transform: translateX(-8%); }
  65% {
    -webkit-transform: translateX(4%); }
  80% {
    -webkit-transform: translateX(-4%); }
  95% {
    -webkit-transform: translateX(2%); }
  100% {
    -webkit-transform: translateX(0%); } }

/*
==============================================
slideRight
==============================================
*/
.slideRight {
  animation-name: slideRight;
  -webkit-animation-name: slideRight;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes slideRight {
  0% {
    transform: translateX(-150%); }
  50% {
    transform: translateX(8%); }
  65% {
    transform: translateX(-4%); }
  80% {
    transform: translateX(4%); }
  95% {
    transform: translateX(-2%); }
  100% {
    transform: translateX(0%); } }

@-webkit-keyframes slideRight {
  0% {
    -webkit-transform: translateX(-150%); }
  50% {
    -webkit-transform: translateX(8%); }
  65% {
    -webkit-transform: translateX(-4%); }
  80% {
    -webkit-transform: translateX(4%); }
  95% {
    -webkit-transform: translateX(-2%); }
  100% {
    -webkit-transform: translateX(0%); } }

/*
==============================================
slideExpandUp
==============================================
*/
.slideExpandUp {
  animation-name: slideExpandUp;
  -webkit-animation-name: slideExpandUp;
  animation-duration: 1.6s;
  -webkit-animation-duration: 1.6s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease -out;
  visibility: visible !important; }

@keyframes slideExpandUp {
  0% {
    transform: translateY(100%) scaleX(0.5); }
  30% {
    transform: translateY(-8%) scaleX(0.5); }
  40% {
    transform: translateY(2%) scaleX(0.5); }
  50% {
    transform: translateY(0%) scaleX(1.1); }
  60% {
    transform: translateY(0%) scaleX(0.9); }
  70% {
    transform: translateY(0%) scaleX(1.05); }
  80% {
    transform: translateY(0%) scaleX(0.95); }
  90% {
    transform: translateY(0%) scaleX(1.02); }
  100% {
    transform: translateY(0%) scaleX(1); } }

@-webkit-keyframes slideExpandUp {
  0% {
    -webkit-transform: translateY(100%) scaleX(0.5); }
  30% {
    -webkit-transform: translateY(-8%) scaleX(0.5); }
  40% {
    -webkit-transform: translateY(2%) scaleX(0.5); }
  50% {
    -webkit-transform: translateY(0%) scaleX(1.1); }
  60% {
    -webkit-transform: translateY(0%) scaleX(0.9); }
  70% {
    -webkit-transform: translateY(0%) scaleX(1.05); }
  80% {
    -webkit-transform: translateY(0%) scaleX(0.95); }
  90% {
    -webkit-transform: translateY(0%) scaleX(1.02); }
  100% {
    -webkit-transform: translateY(0%) scaleX(1); } }

/*
==============================================
expandUp
==============================================
*/
.expandUp {
  animation-name: expandUp;
  -webkit-animation-name: expandUp;
  animation-duration: 0.7s;
  -webkit-animation-duration: 0.7s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  visibility: visible !important; }

@keyframes expandUp {
  0% {
    transform: translateY(100%) scale(0.6) scaleY(0.5); }
  60% {
    transform: translateY(-7%) scaleY(1.12); }
  75% {
    transform: translateY(3%); }
  100% {
    transform: translateY(0%) scale(1) scaleY(1); } }

@-webkit-keyframes expandUp {
  0% {
    -webkit-transform: translateY(100%) scale(0.6) scaleY(0.5); }
  60% {
    -webkit-transform: translateY(-7%) scaleY(1.12); }
  75% {
    -webkit-transform: translateY(3%); }
  100% {
    -webkit-transform: translateY(0%) scale(1) scaleY(1); } }

/*
==============================================
fadeIn
==============================================
*/
.fadeIn {
  animation-name: fadeIn;
  -webkit-animation-name: fadeIn;
  animation-duration: 1.5s;
  -webkit-animation-duration: 1.5s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  visibility: visible !important; }

@keyframes fadeIn {
  0% {
    transform: scale(0);
    opacity: 0.0; }
  60% {
    transform: scale(1.1); }
  80% {
    transform: scale(0.9);
    opacity: 1; }
  100% {
    transform: scale(1);
    opacity: 1; } }

@-webkit-keyframes fadeIn {
  0% {
    -webkit-transform: scale(0);
    opacity: 0.0; }
  60% {
    -webkit-transform: scale(1.1); }
  80% {
    -webkit-transform: scale(0.9);
    opacity: 1; }
  100% {
    -webkit-transform: scale(1);
    opacity: 1; } }

/*
==============================================
expandOpen
==============================================
*/
.expandOpen {
  animation-name: expandOpen;
  -webkit-animation-name: expandOpen;
  animation-duration: 1.2s;
  -webkit-animation-duration: 1.2s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;
  visibility: visible !important; }

@keyframes expandOpen {
  0% {
    transform: scale(1.8); }
  50% {
    transform: scale(0.95); }
  80% {
    transform: scale(1.05); }
  90% {
    transform: scale(0.98); }
  100% {
    transform: scale(1); } }

@-webkit-keyframes expandOpen {
  0% {
    -webkit-transform: scale(1.8); }
  50% {
    -webkit-transform: scale(0.95); }
  80% {
    -webkit-transform: scale(1.05); }
  90% {
    -webkit-transform: scale(0.98); }
  100% {
    -webkit-transform: scale(1); } }

/*
==============================================
bigEntrance
==============================================
*/
.bigEntrance {
  animation-name: bigEntrance;
  -webkit-animation-name: bigEntrance;
  animation-duration: 1.6s;
  -webkit-animation-duration: 1.6s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;
  visibility: visible !important; }

@keyframes bigEntrance {
  0% {
    transform: scale(0.3) rotate(6deg) translateX(-30%) translateY(30%);
    opacity: 0.2; }
  30% {
    transform: scale(1.03) rotate(-2deg) translateX(2%) translateY(-2%);
    opacity: 1; }
  45% {
    transform: scale(0.98) rotate(1deg) translateX(0%) translateY(0%);
    opacity: 1; }
  60% {
    transform: scale(1.01) rotate(-1deg) translateX(0%) translateY(0%);
    opacity: 1; }
  75% {
    transform: scale(0.99) rotate(1deg) translateX(0%) translateY(0%);
    opacity: 1; }
  90% {
    transform: scale(1.01) rotate(0deg) translateX(0%) translateY(0%);
    opacity: 1; }
  100% {
    transform: scale(1) rotate(0deg) translateX(0%) translateY(0%);
    opacity: 1; } }

@-webkit-keyframes bigEntrance {
  0% {
    -webkit-transform: scale(0.3) rotate(6deg) translateX(-30%) translateY(30%);
    opacity: 0.2; }
  30% {
    -webkit-transform: scale(1.03) rotate(-2deg) translateX(2%) translateY(-2%);
    opacity: 1; }
  45% {
    -webkit-transform: scale(0.98) rotate(1deg) translateX(0%) translateY(0%);
    opacity: 1; }
  60% {
    -webkit-transform: scale(1.01) rotate(-1deg) translateX(0%) translateY(0%);
    opacity: 1; }
  75% {
    -webkit-transform: scale(0.99) rotate(1deg) translateX(0%) translateY(0%);
    opacity: 1; }
  90% {
    -webkit-transform: scale(1.01) rotate(0deg) translateX(0%) translateY(0%);
    opacity: 1; }
  100% {
    -webkit-transform: scale(1) rotate(0deg) translateX(0%) translateY(0%);
    opacity: 1; } }

/*
==============================================
hatch
==============================================
*/
.hatch {
  animation-name: hatch;
  -webkit-animation-name: hatch;
  animation-duration: 2s;
  -webkit-animation-duration: 2s;
  animation-timing-function: ease-in-out;
  -webkit-animation-timing-function: ease-in-out;
  transform-origin: 50% 100%;
  -ms-transform-origin: 50% 100%;
  -webkit-transform-origin: 50% 100%;
  visibility: visible !important; }

@keyframes hatch {
  0% {
    transform: rotate(0deg) scaleY(0.6); }
  20% {
    transform: rotate(-2deg) scaleY(1.05); }
  35% {
    transform: rotate(2deg) scaleY(1); }
  50% {
    transform: rotate(-2deg); }
  65% {
    transform: rotate(1deg); }
  80% {
    transform: rotate(-1deg); }
  100% {
    transform: rotate(0deg); } }

@-webkit-keyframes hatch {
  0% {
    -webkit-transform: rotate(0deg) scaleY(0.6); }
  20% {
    -webkit-transform: rotate(-2deg) scaleY(1.05); }
  35% {
    -webkit-transform: rotate(2deg) scaleY(1); }
  50% {
    -webkit-transform: rotate(-2deg); }
  65% {
    -webkit-transform: rotate(1deg); }
  80% {
    -webkit-transform: rotate(-1deg); }
  100% {
    -webkit-transform: rotate(0deg); } }

/*
==============================================
bounce
==============================================
*/
.bounce {
  animation-name: bounce;
  -webkit-animation-name: bounce;
  animation-duration: 1.6s;
  -webkit-animation-duration: 1.6s;
  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
  transform-origin: 50% 100%;
  -ms-transform-origin: 50% 100%;
  -webkit-transform-origin: 50% 100%; }

@keyframes bounce {
  0% {
    transform: translateY(0%) scaleY(0.6); }
  60% {
    transform: translateY(-100%) scaleY(1.1); }
  70% {
    transform: translateY(0%) scaleY(0.95) scaleX(1.05); }
  80% {
    transform: translateY(0%) scaleY(1.05) scaleX(1); }
  90% {
    transform: translateY(0%) scaleY(0.95) scaleX(1); }
  100% {
    transform: translateY(0%) scaleY(1) scaleX(1); } }

@-webkit-keyframes bounce {
  0% {
    -webkit-transform: translateY(0%) scaleY(0.6); }
  60% {
    -webkit-transform: translateY(-100%) scaleY(1.1); }
  70% {
    -webkit-transform: translateY(0%) scaleY(0.95) scaleX(1.05); }
  80% {
    -webkit-transform: translateY(0%) scaleY(1.05) scaleX(1); }
  90% {
    -webkit-transform: translateY(0%) scaleY(0.95) scaleX(1); }
  100% {
    -webkit-transform: translateY(0%) scaleY(1) scaleX(1); } }

/*
==============================================
pulse
==============================================
*/
.pulse {
  animation-name: pulse;
  -webkit-animation-name: pulse;
  animation-duration: 1.5s;
  -webkit-animation-duration: 1.5s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite; }

@keyframes pulse {
  0% {
    transform: scale(0.9);
    opacity: 0.7; }
  50% {
    transform: scale(1);
    opacity: 1; }
  100% {
    transform: scale(0.9);
    opacity: 0.7; } }

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(0.95);
    opacity: 0.7; }
  50% {
    -webkit-transform: scale(1);
    opacity: 1; }
  100% {
    -webkit-transform: scale(0.95);
    opacity: 0.7; } }

/*
==============================================
floating
==============================================
*/
.floating {
  animation-name: floating;
  -webkit-animation-name: floating;
  animation-duration: 1.5s;
  -webkit-animation-duration: 1.5s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite; }

@keyframes floating {
  0% {
    transform: translateY(0%); }
  50% {
    transform: translateY(8%); }
  100% {
    transform: translateY(0%); } }

@-webkit-keyframes floating {
  0% {
    -webkit-transform: translateY(0%); }
  50% {
    -webkit-transform: translateY(8%); }
  100% {
    -webkit-transform: translateY(0%); } }

/*
==============================================
tossing
==============================================
*/
.tossing {
  animation-name: tossing;
  -webkit-animation-name: tossing;
  animation-duration: 2.5s;
  -webkit-animation-duration: 2.5s;
  animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite; }

@keyframes tossing {
  0% {
    transform: rotate(-4deg); }
  50% {
    transform: rotate(4deg); }
  100% {
    transform: rotate(-4deg); } }

@-webkit-keyframes tossing {
  0% {
    -webkit-transform: rotate(-4deg); }
  50% {
    -webkit-transform: rotate(4deg); }
  100% {
    -webkit-transform: rotate(-4deg); } }

/*
==============================================
pullUp
==============================================
*/
.pullUp {
  animation-name: pullUp;
  -webkit-animation-name: pullUp;
  animation-duration: 1.1s;
  -webkit-animation-duration: 1.1s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;
  transform-origin: 50% 100%;
  -ms-transform-origin: 50% 100%;
  -webkit-transform-origin: 50% 100%; }

@keyframes pullUp {
  0% {
    transform: scaleY(0.1); }
  40% {
    transform: scaleY(1.02); }
  60% {
    transform: scaleY(0.98); }
  80% {
    transform: scaleY(1.01); }
  100% {
    transform: scaleY(0.98); }
  80% {
    transform: scaleY(1.01); }
  100% {
    transform: scaleY(1); } }

@-webkit-keyframes pullUp {
  0% {
    -webkit-transform: scaleY(0.1); }
  40% {
    -webkit-transform: scaleY(1.02); }
  60% {
    -webkit-transform: scaleY(0.98); }
  80% {
    -webkit-transform: scaleY(1.01); }
  100% {
    -webkit-transform: scaleY(0.98); }
  80% {
    -webkit-transform: scaleY(1.01); }
  100% {
    -webkit-transform: scaleY(1); } }

/*
==============================================
pullDown
==============================================
*/
.pullDown {
  animation-name: pullDown;
  -webkit-animation-name: pullDown;
  animation-duration: 1.1s;
  -webkit-animation-duration: 1.1s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;
  transform-origin: 50% 0%;
  -ms-transform-origin: 50% 0%;
  -webkit-transform-origin: 50% 0%; }

@keyframes pullDown {
  0% {
    transform: scaleY(0.1); }
  40% {
    transform: scaleY(1.02); }
  60% {
    transform: scaleY(0.98); }
  80% {
    transform: scaleY(1.01); }
  100% {
    transform: scaleY(0.98); }
  80% {
    transform: scaleY(1.01); }
  100% {
    transform: scaleY(1); } }

@-webkit-keyframes pullDown {
  0% {
    -webkit-transform: scaleY(0.1); }
  40% {
    -webkit-transform: scaleY(1.02); }
  60% {
    -webkit-transform: scaleY(0.98); }
  80% {
    -webkit-transform: scaleY(1.01); }
  100% {
    -webkit-transform: scaleY(0.98); }
  80% {
    -webkit-transform: scaleY(1.01); }
  100% {
    -webkit-transform: scaleY(1); } }

/*
==============================================
stretchLeft
==============================================
*/
.stretchLeft {
  animation-name: stretchLeft;
  -webkit-animation-name: stretchLeft;
  animation-duration: 1.5s;
  -webkit-animation-duration: 1.5s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;
  transform-origin: 100% 0%;
  -ms-transform-origin: 100% 0%;
  -webkit-transform-origin: 100% 0%; }

@keyframes stretchLeft {
  0% {
    transform: scaleX(0.3); }
  40% {
    transform: scaleX(1.02); }
  60% {
    transform: scaleX(0.98); }
  80% {
    transform: scaleX(1.01); }
  100% {
    transform: scaleX(0.98); }
  80% {
    transform: scaleX(1.01); }
  100% {
    transform: scaleX(1); } }

@-webkit-keyframes stretchLeft {
  0% {
    -webkit-transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1); } }

/*
==============================================
stretchRight
==============================================
*/
.stretchRight {
  animation-name: stretchRight;
  -webkit-animation-name: stretchRight;
  animation-duration: 1.5s;
  -webkit-animation-duration: 1.5s;
  animation-timing-function: ease-out;
  -webkit-animation-timing-function: ease-out;
  transform-origin: 0% 0%;
  -ms-transform-origin: 0% 0%;
  -webkit-transform-origin: 0% 0%; }

@keyframes stretchRight {
  0% {
    transform: scaleX(0.3); }
  40% {
    transform: scaleX(1.02); }
  60% {
    transform: scaleX(0.98); }
  80% {
    transform: scaleX(1.01); }
  100% {
    transform: scaleX(0.98); }
  80% {
    transform: scaleX(1.01); }
  100% {
    transform: scaleX(1); } }

@-webkit-keyframes stretchRight {
  0% {
    -webkit-transform: scaleX(0.3); }
  40% {
    -webkit-transform: scaleX(1.02); }
  60% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(0.98); }
  80% {
    -webkit-transform: scaleX(1.01); }
  100% {
    -webkit-transform: scaleX(1); } }

.parallax-item,
.parallax-item2 {
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1); }

/*============================================================================
  GRID
==============================================================================*/
.grid, .grid--rev, .grid--full, .grid--half, .grid--x2 {
  *zoom: 1;
  margin: 0 0 0 -40px; }
  .grid:before, .grid--rev:before, .grid--full:before, .grid--half:before, .grid--x2:before, .grid:after, .grid--rev:after, .grid--full:after, .grid--half:after, .grid--x2:after {
    display: table;
    content: " "; }
  .grid:after, .grid--rev:after, .grid--full:after, .grid--half:after, .grid--x2:after {
    clear: both; }

ul.grid, ul.grid--rev, ul.grid--full, ul.grid--half, ul.grid--x2,
ul.grid-uniform {
  list-style: none;
  padding: 0; }

.grid__item {
  float: left;
  padding-left: 40px;
  width: 100%; }

.grid--rev {
  direction: rtl;
  text-align: left; }
  .grid--rev > .grid__item {
    direction: ltr;
    text-align: left;
    float: right; }

.grid--full {
  margin-left: 0; }
  .grid--full > .grid__item {
    padding-left: 0; }

.grid--half {
  margin-left: -20px; }
  .grid--half > .grid__item {
    padding-left: 20px; }

.grid--x2 {
  margin-left: -80px; }
  .grid--x2 > .grid__item {
    padding-left: 80px; }

.one-whole {
  width: 100%; }

.one-half {
  width: 50%; }

.one-third {
  width: 33.333%; }

.two-thirds {
  width: 66.666%; }

.one-quarter {
  width: 25%; }

.two-quarters {
  width: 50%; }

.three-quarters {
  width: 75%; }

.one-fifth {
  width: 20%; }

.two-fifths {
  width: 40%; }

.three-fifths {
  width: 60%; }

.four-fifths {
  width: 80%; }

.one-sixth {
  width: 16.666%; }

.two-sixths {
  width: 33.333%; }

.three-sixths {
  width: 50%; }

.four-sixths {
  width: 66.666%; }

.five-sixths {
  width: 83.333%; }

.one-eighth {
  width: 12.5%; }

.two-eighths {
  width: 25%; }

.three-eighths {
  width: 37.5%; }

.four-eighths {
  width: 50%; }

.five-eighths {
  width: 62.5%; }

.six-eighths {
  width: 75%; }

.seven-eighths {
  width: 87.5%; }

.one-tenth {
  width: 10%; }

.two-tenths {
  width: 20%; }

.three-tenths {
  width: 30%; }

.four-tenths {
  width: 40%; }

.five-tenths {
  width: 50%; }

.six-tenths {
  width: 60%; }

.seven-tenths {
  width: 70%; }

.eight-tenths {
  width: 80%; }

.nine-tenths {
  width: 90%; }

.one-twelfth {
  width: 8.333%; }

.two-twelfths {
  width: 16.666%; }

.three-twelfths {
  width: 25%; }

.four-twelfths {
  width: 33.333%; }

.five-twelfths {
  width: 41.666%; }

.six-twelfths {
  width: 50%; }

.seven-twelfths {
  width: 58.333%; }

.eight-twelfths {
  width: 66.666%; }

.nine-twelfths {
  width: 75%; }

.ten-twelfths {
  width: 83.333%; }

.eleven-twelfths {
  width: 91.666%; }

.show {
  display: block !important; }

.hide {
  display: none !important; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

.left {
  float: left !important; }

.right {
  float: right !important; }

@media only screen and (min-width: 480px) {
  .small--one-whole {
    width: 100%; }
  .small--one-half {
    width: 50%; }
  .small--one-third {
    width: 33.333%; }
  .small--two-thirds {
    width: 66.666%; }
  .small--one-quarter {
    width: 25%; }
  .small--two-quarters {
    width: 50%; }
  .small--three-quarters {
    width: 75%; }
  .small--one-fifth {
    width: 20%; }
  .small--two-fifths {
    width: 40%; }
  .small--three-fifths {
    width: 60%; }
  .small--four-fifths {
    width: 80%; }
  .small--one-sixth {
    width: 16.666%; }
  .small--two-sixths {
    width: 33.333%; }
  .small--three-sixths {
    width: 50%; }
  .small--four-sixths {
    width: 66.666%; }
  .small--five-sixths {
    width: 83.333%; }
  .small--one-eighth {
    width: 12.5%; }
  .small--two-eighths {
    width: 25%; }
  .small--three-eighths {
    width: 37.5%; }
  .small--four-eighths {
    width: 50%; }
  .small--five-eighths {
    width: 62.5%; }
  .small--six-eighths {
    width: 75%; }
  .small--seven-eighths {
    width: 87.5%; }
  .small--one-tenth {
    width: 10%; }
  .small--two-tenths {
    width: 20%; }
  .small--three-tenths {
    width: 30%; }
  .small--four-tenths {
    width: 40%; }
  .small--five-tenths {
    width: 50%; }
  .small--six-tenths {
    width: 60%; }
  .small--seven-tenths {
    width: 70%; }
  .small--eight-tenths {
    width: 80%; }
  .small--nine-tenths {
    width: 90%; }
  .small--one-twelfth {
    width: 8.333%; }
  .small--two-twelfths {
    width: 16.666%; }
  .small--three-twelfths {
    width: 25%; }
  .small--four-twelfths {
    width: 33.333%; }
  .small--five-twelfths {
    width: 41.666%; }
  .small--six-twelfths {
    width: 50%; }
  .small--seven-twelfths {
    width: 58.333%; }
  .small--eight-twelfths {
    width: 66.666%; }
  .small--nine-twelfths {
    width: 75%; }
  .small--ten-twelfths {
    width: 83.333%; }
  .small--eleven-twelfths {
    width: 91.666%; }
  .small--show {
    display: block !important; }
  .small--hide {
    display: none !important; }
  .small--text-left {
    text-align: left !important; }
  .small--text-right {
    text-align: right !important; }
  .small--text-center {
    text-align: center !important; }
  .small--left {
    float: left !important; }
  .small--right {
    float: right !important; } }

@media only screen and (min-width: 768px) {
  .medium--one-whole {
    width: 100%; }
  .medium--one-half {
    width: 50%; }
  .medium--one-third {
    width: 33.333%; }
  .medium--two-thirds {
    width: 66.666%; }
  .medium--one-quarter {
    width: 25%; }
  .medium--two-quarters {
    width: 50%; }
  .medium--three-quarters {
    width: 75%; }
  .medium--one-fifth {
    width: 20%; }
  .medium--two-fifths {
    width: 40%; }
  .medium--three-fifths {
    width: 60%; }
  .medium--four-fifths {
    width: 80%; }
  .medium--one-sixth {
    width: 16.666%; }
  .medium--two-sixths {
    width: 33.333%; }
  .medium--three-sixths {
    width: 50%; }
  .medium--four-sixths {
    width: 66.666%; }
  .medium--five-sixths {
    width: 83.333%; }
  .medium--one-eighth {
    width: 12.5%; }
  .medium--two-eighths {
    width: 25%; }
  .medium--three-eighths {
    width: 37.5%; }
  .medium--four-eighths {
    width: 50%; }
  .medium--five-eighths {
    width: 62.5%; }
  .medium--six-eighths {
    width: 75%; }
  .medium--seven-eighths {
    width: 87.5%; }
  .medium--one-tenth {
    width: 10%; }
  .medium--two-tenths {
    width: 20%; }
  .medium--three-tenths {
    width: 30%; }
  .medium--four-tenths {
    width: 40%; }
  .medium--five-tenths {
    width: 50%; }
  .medium--six-tenths {
    width: 60%; }
  .medium--seven-tenths {
    width: 70%; }
  .medium--eight-tenths {
    width: 80%; }
  .medium--nine-tenths {
    width: 90%; }
  .medium--one-twelfth {
    width: 8.333%; }
  .medium--two-twelfths {
    width: 16.666%; }
  .medium--three-twelfths {
    width: 25%; }
  .medium--four-twelfths {
    width: 33.333%; }
  .medium--five-twelfths {
    width: 41.666%; }
  .medium--six-twelfths {
    width: 50%; }
  .medium--seven-twelfths {
    width: 58.333%; }
  .medium--eight-twelfths {
    width: 66.666%; }
  .medium--nine-twelfths {
    width: 75%; }
  .medium--ten-twelfths {
    width: 83.333%; }
  .medium--eleven-twelfths {
    width: 91.666%; }
  .medium--show {
    display: block !important; }
  .medium--hide {
    display: none !important; }
  .medium--text-left {
    text-align: left !important; }
  .medium--text-right {
    text-align: right !important; }
  .medium--text-center {
    text-align: center !important; }
  .medium--left {
    float: left !important; }
  .medium--right {
    float: right !important; } }

@media only screen and (min-width: 1024px) {
  .large--one-whole {
    width: 100%; }
  .large--one-half {
    width: 50%; }
  .large--one-third {
    width: 33.333%; }
  .large--two-thirds {
    width: 66.666%; }
  .large--one-quarter {
    width: 25%; }
  .large--two-quarters {
    width: 50%; }
  .large--three-quarters {
    width: 75%; }
  .large--one-fifth {
    width: 20%; }
  .large--two-fifths {
    width: 40%; }
  .large--three-fifths {
    width: 60%; }
  .large--four-fifths {
    width: 80%; }
  .large--one-sixth {
    width: 16.666%; }
  .large--two-sixths {
    width: 33.333%; }
  .large--three-sixths {
    width: 50%; }
  .large--four-sixths {
    width: 66.666%; }
  .large--five-sixths {
    width: 83.333%; }
  .large--one-eighth {
    width: 12.5%; }
  .large--two-eighths {
    width: 25%; }
  .large--three-eighths {
    width: 37.5%; }
  .large--four-eighths {
    width: 50%; }
  .large--five-eighths {
    width: 62.5%; }
  .large--six-eighths {
    width: 75%; }
  .large--seven-eighths {
    width: 87.5%; }
  .large--one-tenth {
    width: 10%; }
  .large--two-tenths {
    width: 20%; }
  .large--three-tenths {
    width: 30%; }
  .large--four-tenths {
    width: 40%; }
  .large--five-tenths {
    width: 50%; }
  .large--six-tenths {
    width: 60%; }
  .large--seven-tenths {
    width: 70%; }
  .large--eight-tenths {
    width: 80%; }
  .large--nine-tenths {
    width: 90%; }
  .large--one-twelfth {
    width: 8.333%; }
  .large--two-twelfths {
    width: 16.666%; }
  .large--three-twelfths {
    width: 25%; }
  .large--four-twelfths {
    width: 33.333%; }
  .large--five-twelfths {
    width: 41.666%; }
  .large--six-twelfths {
    width: 50%; }
  .large--seven-twelfths {
    width: 58.333%; }
  .large--eight-twelfths {
    width: 66.666%; }
  .large--nine-twelfths {
    width: 75%; }
  .large--ten-twelfths {
    width: 83.333%; }
  .large--eleven-twelfths {
    width: 91.666%; }
  .large--show {
    display: block !important; }
  .large--hide {
    display: none !important; }
  .large--text-left {
    text-align: left !important; }
  .large--text-right {
    text-align: right !important; }
  .large--text-center {
    text-align: center !important; }
  .large--left {
    float: left !important; }
  .large--right {
    float: right !important; } }

[class*="push--"] {
  position: relative; }

.push--one-whole {
  left: 100%;
  position: relative; }

.push--one-half {
  left: 50%;
  position: relative; }

.push--one-third {
  left: 33.333%;
  position: relative; }

.push--two-thirds {
  left: 66.666%;
  position: relative; }

.push--one-quarter {
  left: 25%;
  position: relative; }

.push--two-quarters {
  left: 50%;
  position: relative; }

.push--three-quarters {
  left: 75%;
  position: relative; }

.push--one-fifth {
  left: 20%;
  position: relative; }

.push--two-fifths {
  left: 40%;
  position: relative; }

.push--three-fifths {
  left: 60%;
  position: relative; }

.push--four-fifths {
  left: 80%;
  position: relative; }

.push--one-sixth {
  left: 16.666%;
  position: relative; }

.push--two-sixths {
  left: 33.333%;
  position: relative; }

.push--three-sixths {
  left: 50%;
  position: relative; }

.push--four-sixths {
  left: 66.666%;
  position: relative; }

.push--five-sixths {
  left: 83.333%;
  position: relative; }

.push--one-eighth {
  left: 12.5%;
  position: relative; }

.push--two-eighths {
  left: 25%;
  position: relative; }

.push--three-eighths {
  left: 37.5%;
  position: relative; }

.push--four-eighths {
  left: 50%;
  position: relative; }

.push--five-eighths {
  left: 62.5%;
  position: relative; }

.push--six-eighths {
  left: 75%;
  position: relative; }

.push--seven-eighths {
  left: 87.5%;
  position: relative; }

.push--one-tenth {
  left: 10%;
  position: relative; }

.push--two-tenths {
  left: 20%;
  position: relative; }

.push--three-tenths {
  left: 30%;
  position: relative; }

.push--four-tenths {
  left: 40%;
  position: relative; }

.push--five-tenths {
  left: 50%;
  position: relative; }

.push--six-tenths {
  left: 60%;
  position: relative; }

.push--seven-tenths {
  left: 70%;
  position: relative; }

.push--eight-tenths {
  left: 80%;
  position: relative; }

.push--nine-tenths {
  left: 90%;
  position: relative; }

.push--one-twelfth {
  left: 8.333%;
  position: relative; }

.push--two-twelfths {
  left: 16.666%;
  position: relative; }

.push--three-twelfths {
  left: 25%;
  position: relative; }

.push--four-twelfths {
  left: 33.333%;
  position: relative; }

.push--five-twelfths {
  left: 41.666%;
  position: relative; }

.push--six-twelfths {
  left: 50%;
  position: relative; }

.push--seven-twelfths {
  left: 58.333%;
  position: relative; }

.push--eight-twelfths {
  left: 66.666%;
  position: relative; }

.push--nine-twelfths {
  left: 75%;
  position: relative; }

.push--ten-twelfths {
  left: 83.333%;
  position: relative; }

.push--eleven-twelfths {
  left: 91.666%;
  position: relative; }

@media only screen and (min-width: 768px) {
  .push--medium--one-whole {
    left: 100%;
    position: relative; }
  .push--medium--one-half {
    left: 50%;
    position: relative; }
  .push--medium--one-third {
    left: 33.333%;
    position: relative; }
  .push--medium--two-thirds {
    left: 66.666%;
    position: relative; }
  .push--medium--one-quarter {
    left: 25%;
    position: relative; }
  .push--medium--two-quarters {
    left: 50%;
    position: relative; }
  .push--medium--three-quarters {
    left: 75%;
    position: relative; }
  .push--medium--one-fifth {
    left: 20%;
    position: relative; }
  .push--medium--two-fifths {
    left: 40%;
    position: relative; }
  .push--medium--three-fifths {
    left: 60%;
    position: relative; }
  .push--medium--four-fifths {
    left: 80%;
    position: relative; }
  .push--medium--one-sixth {
    left: 16.666%;
    position: relative; }
  .push--medium--two-sixths {
    left: 33.333%;
    position: relative; }
  .push--medium--three-sixths {
    left: 50%;
    position: relative; }
  .push--medium--four-sixths {
    left: 66.666%;
    position: relative; }
  .push--medium--five-sixths {
    left: 83.333%;
    position: relative; }
  .push--medium--one-eighth {
    left: 12.5%;
    position: relative; }
  .push--medium--two-eighths {
    left: 25%;
    position: relative; }
  .push--medium--three-eighths {
    left: 37.5%;
    position: relative; }
  .push--medium--four-eighths {
    left: 50%;
    position: relative; }
  .push--medium--five-eighths {
    left: 62.5%;
    position: relative; }
  .push--medium--six-eighths {
    left: 75%;
    position: relative; }
  .push--medium--seven-eighths {
    left: 87.5%;
    position: relative; }
  .push--medium--one-tenth {
    left: 10%;
    position: relative; }
  .push--medium--two-tenths {
    left: 20%;
    position: relative; }
  .push--medium--three-tenths {
    left: 30%;
    position: relative; }
  .push--medium--four-tenths {
    left: 40%;
    position: relative; }
  .push--medium--five-tenths {
    left: 50%;
    position: relative; }
  .push--medium--six-tenths {
    left: 60%;
    position: relative; }
  .push--medium--seven-tenths {
    left: 70%;
    position: relative; }
  .push--medium--eight-tenths {
    left: 80%;
    position: relative; }
  .push--medium--nine-tenths {
    left: 90%;
    position: relative; }
  .push--medium--one-twelfth {
    left: 8.333%;
    position: relative; }
  .push--medium--two-twelfths {
    left: 16.666%;
    position: relative; }
  .push--medium--three-twelfths {
    left: 25%;
    position: relative; }
  .push--medium--four-twelfths {
    left: 33.333%;
    position: relative; }
  .push--medium--five-twelfths {
    left: 41.666%;
    position: relative; }
  .push--medium--six-twelfths {
    left: 50%;
    position: relative; }
  .push--medium--seven-twelfths {
    left: 58.333%;
    position: relative; }
  .push--medium--eight-twelfths {
    left: 66.666%;
    position: relative; }
  .push--medium--nine-twelfths {
    left: 75%;
    position: relative; }
  .push--medium--ten-twelfths {
    left: 83.333%;
    position: relative; }
  .push--medium--eleven-twelfths {
    left: 91.666%;
    position: relative; } }

@media only screen and (min-width: 1024px) {
  .push--large--one-whole {
    left: 100%;
    position: relative; }
  .push--large--one-half {
    left: 50%;
    position: relative; }
  .push--large--one-third {
    left: 33.333%;
    position: relative; }
  .push--large--two-thirds {
    left: 66.666%;
    position: relative; }
  .push--large--one-quarter {
    left: 25%;
    position: relative; }
  .push--large--two-quarters {
    left: 50%;
    position: relative; }
  .push--large--three-quarters {
    left: 75%;
    position: relative; }
  .push--large--one-fifth {
    left: 20%;
    position: relative; }
  .push--large--two-fifths {
    left: 40%;
    position: relative; }
  .push--large--three-fifths {
    left: 60%;
    position: relative; }
  .push--large--four-fifths {
    left: 80%;
    position: relative; }
  .push--large--one-sixth {
    left: 16.666%;
    position: relative; }
  .push--large--two-sixths {
    left: 33.333%;
    position: relative; }
  .push--large--three-sixths {
    left: 50%;
    position: relative; }
  .push--large--four-sixths {
    left: 66.666%;
    position: relative; }
  .push--large--five-sixths {
    left: 83.333%;
    position: relative; }
  .push--large--one-eighth {
    left: 12.5%;
    position: relative; }
  .push--large--two-eighths {
    left: 25%;
    position: relative; }
  .push--large--three-eighths {
    left: 37.5%;
    position: relative; }
  .push--large--four-eighths {
    left: 50%;
    position: relative; }
  .push--large--five-eighths {
    left: 62.5%;
    position: relative; }
  .push--large--six-eighths {
    left: 75%;
    position: relative; }
  .push--large--seven-eighths {
    left: 87.5%;
    position: relative; }
  .push--large--one-tenth {
    left: 10%;
    position: relative; }
  .push--large--two-tenths {
    left: 20%;
    position: relative; }
  .push--large--three-tenths {
    left: 30%;
    position: relative; }
  .push--large--four-tenths {
    left: 40%;
    position: relative; }
  .push--large--five-tenths {
    left: 50%;
    position: relative; }
  .push--large--six-tenths {
    left: 60%;
    position: relative; }
  .push--large--seven-tenths {
    left: 70%;
    position: relative; }
  .push--large--eight-tenths {
    left: 80%;
    position: relative; }
  .push--large--nine-tenths {
    left: 90%;
    position: relative; }
  .push--large--one-twelfth {
    left: 8.333%;
    position: relative; }
  .push--large--two-twelfths {
    left: 16.666%;
    position: relative; }
  .push--large--three-twelfths {
    left: 25%;
    position: relative; }
  .push--large--four-twelfths {
    left: 33.333%;
    position: relative; }
  .push--large--five-twelfths {
    left: 41.666%;
    position: relative; }
  .push--large--six-twelfths {
    left: 50%;
    position: relative; }
  .push--large--seven-twelfths {
    left: 58.333%;
    position: relative; }
  .push--large--eight-twelfths {
    left: 66.666%;
    position: relative; }
  .push--large--nine-twelfths {
    left: 75%;
    position: relative; }
  .push--large--ten-twelfths {
    left: 83.333%;
    position: relative; }
  .push--large--eleven-twelfths {
    left: 91.666%;
    position: relative; } }

[class*="pull--"] {
  position: relative; }

.pull--one-whole {
  right: 100%;
  position: relative; }

.pull--one-half {
  right: 50%;
  position: relative; }

.pull--one-third {
  right: 33.333%;
  position: relative; }

.pull--two-thirds {
  right: 66.666%;
  position: relative; }

.pull--one-quarter {
  right: 25%;
  position: relative; }

.pull--two-quarters {
  right: 50%;
  position: relative; }

.pull--three-quarters {
  right: 75%;
  position: relative; }

.pull--one-fifth {
  right: 20%;
  position: relative; }

.pull--two-fifths {
  right: 40%;
  position: relative; }

.pull--three-fifths {
  right: 60%;
  position: relative; }

.pull--four-fifths {
  right: 80%;
  position: relative; }

.pull--one-sixth {
  right: 16.666%;
  position: relative; }

.pull--two-sixths {
  right: 33.333%;
  position: relative; }

.pull--three-sixths {
  right: 50%;
  position: relative; }

.pull--four-sixths {
  right: 66.666%;
  position: relative; }

.pull--five-sixths {
  right: 83.333%;
  position: relative; }

.pull--one-eighth {
  right: 12.5%;
  position: relative; }

.pull--two-eighths {
  right: 25%;
  position: relative; }

.pull--three-eighths {
  right: 37.5%;
  position: relative; }

.pull--four-eighths {
  right: 50%;
  position: relative; }

.pull--five-eighths {
  right: 62.5%;
  position: relative; }

.pull--six-eighths {
  right: 75%;
  position: relative; }

.pull--seven-eighths {
  right: 87.5%;
  position: relative; }

.pull--one-tenth {
  right: 10%;
  position: relative; }

.pull--two-tenths {
  right: 20%;
  position: relative; }

.pull--three-tenths {
  right: 30%;
  position: relative; }

.pull--four-tenths {
  right: 40%;
  position: relative; }

.pull--five-tenths {
  right: 50%;
  position: relative; }

.pull--six-tenths {
  right: 60%;
  position: relative; }

.pull--seven-tenths {
  right: 70%;
  position: relative; }

.pull--eight-tenths {
  right: 80%;
  position: relative; }

.pull--nine-tenths {
  right: 90%;
  position: relative; }

.pull--one-twelfth {
  right: 8.333%;
  position: relative; }

.pull--two-twelfths {
  right: 16.666%;
  position: relative; }

.pull--three-twelfths {
  right: 25%;
  position: relative; }

.pull--four-twelfths {
  right: 33.333%;
  position: relative; }

.pull--five-twelfths {
  right: 41.666%;
  position: relative; }

.pull--six-twelfths {
  right: 50%;
  position: relative; }

.pull--seven-twelfths {
  right: 58.333%;
  position: relative; }

.pull--eight-twelfths {
  right: 66.666%;
  position: relative; }

.pull--nine-twelfths {
  right: 75%;
  position: relative; }

.pull--ten-twelfths {
  right: 83.333%;
  position: relative; }

.pull--eleven-twelfths {
  right: 91.666%;
  position: relative; }

@media only screen and (min-width: 768px) {
  .pull--medium--one-whole {
    right: 100%;
    position: relative; }
  .pull--medium--one-half {
    right: 50%;
    position: relative; }
  .pull--medium--one-third {
    right: 33.333%;
    position: relative; }
  .pull--medium--two-thirds {
    right: 66.666%;
    position: relative; }
  .pull--medium--one-quarter {
    right: 25%;
    position: relative; }
  .pull--medium--two-quarters {
    right: 50%;
    position: relative; }
  .pull--medium--three-quarters {
    right: 75%;
    position: relative; }
  .pull--medium--one-fifth {
    right: 20%;
    position: relative; }
  .pull--medium--two-fifths {
    right: 40%;
    position: relative; }
  .pull--medium--three-fifths {
    right: 60%;
    position: relative; }
  .pull--medium--four-fifths {
    right: 80%;
    position: relative; }
  .pull--medium--one-sixth {
    right: 16.666%;
    position: relative; }
  .pull--medium--two-sixths {
    right: 33.333%;
    position: relative; }
  .pull--medium--three-sixths {
    right: 50%;
    position: relative; }
  .pull--medium--four-sixths {
    right: 66.666%;
    position: relative; }
  .pull--medium--five-sixths {
    right: 83.333%;
    position: relative; }
  .pull--medium--one-eighth {
    right: 12.5%;
    position: relative; }
  .pull--medium--two-eighths {
    right: 25%;
    position: relative; }
  .pull--medium--three-eighths {
    right: 37.5%;
    position: relative; }
  .pull--medium--four-eighths {
    right: 50%;
    position: relative; }
  .pull--medium--five-eighths {
    right: 62.5%;
    position: relative; }
  .pull--medium--six-eighths {
    right: 75%;
    position: relative; }
  .pull--medium--seven-eighths {
    right: 87.5%;
    position: relative; }
  .pull--medium--one-tenth {
    right: 10%;
    position: relative; }
  .pull--medium--two-tenths {
    right: 20%;
    position: relative; }
  .pull--medium--three-tenths {
    right: 30%;
    position: relative; }
  .pull--medium--four-tenths {
    right: 40%;
    position: relative; }
  .pull--medium--five-tenths {
    right: 50%;
    position: relative; }
  .pull--medium--six-tenths {
    right: 60%;
    position: relative; }
  .pull--medium--seven-tenths {
    right: 70%;
    position: relative; }
  .pull--medium--eight-tenths {
    right: 80%;
    position: relative; }
  .pull--medium--nine-tenths {
    right: 90%;
    position: relative; }
  .pull--medium--one-twelfth {
    right: 8.333%;
    position: relative; }
  .pull--medium--two-twelfths {
    right: 16.666%;
    position: relative; }
  .pull--medium--three-twelfths {
    right: 25%;
    position: relative; }
  .pull--medium--four-twelfths {
    right: 33.333%;
    position: relative; }
  .pull--medium--five-twelfths {
    right: 41.666%;
    position: relative; }
  .pull--medium--six-twelfths {
    right: 50%;
    position: relative; }
  .pull--medium--seven-twelfths {
    right: 58.333%;
    position: relative; }
  .pull--medium--eight-twelfths {
    right: 66.666%;
    position: relative; }
  .pull--medium--nine-twelfths {
    right: 75%;
    position: relative; }
  .pull--medium--ten-twelfths {
    right: 83.333%;
    position: relative; }
  .pull--medium--eleven-twelfths {
    right: 91.666%;
    position: relative; } }

@media only screen and (min-width: 1024px) {
  .pull--large--one-whole {
    right: 100%;
    position: relative; }
  .pull--large--one-half {
    right: 50%;
    position: relative; }
  .pull--large--one-third {
    right: 33.333%;
    position: relative; }
  .pull--large--two-thirds {
    right: 66.666%;
    position: relative; }
  .pull--large--one-quarter {
    right: 25%;
    position: relative; }
  .pull--large--two-quarters {
    right: 50%;
    position: relative; }
  .pull--large--three-quarters {
    right: 75%;
    position: relative; }
  .pull--large--one-fifth {
    right: 20%;
    position: relative; }
  .pull--large--two-fifths {
    right: 40%;
    position: relative; }
  .pull--large--three-fifths {
    right: 60%;
    position: relative; }
  .pull--large--four-fifths {
    right: 80%;
    position: relative; }
  .pull--large--one-sixth {
    right: 16.666%;
    position: relative; }
  .pull--large--two-sixths {
    right: 33.333%;
    position: relative; }
  .pull--large--three-sixths {
    right: 50%;
    position: relative; }
  .pull--large--four-sixths {
    right: 66.666%;
    position: relative; }
  .pull--large--five-sixths {
    right: 83.333%;
    position: relative; }
  .pull--large--one-eighth {
    right: 12.5%;
    position: relative; }
  .pull--large--two-eighths {
    right: 25%;
    position: relative; }
  .pull--large--three-eighths {
    right: 37.5%;
    position: relative; }
  .pull--large--four-eighths {
    right: 50%;
    position: relative; }
  .pull--large--five-eighths {
    right: 62.5%;
    position: relative; }
  .pull--large--six-eighths {
    right: 75%;
    position: relative; }
  .pull--large--seven-eighths {
    right: 87.5%;
    position: relative; }
  .pull--large--one-tenth {
    right: 10%;
    position: relative; }
  .pull--large--two-tenths {
    right: 20%;
    position: relative; }
  .pull--large--three-tenths {
    right: 30%;
    position: relative; }
  .pull--large--four-tenths {
    right: 40%;
    position: relative; }
  .pull--large--five-tenths {
    right: 50%;
    position: relative; }
  .pull--large--six-tenths {
    right: 60%;
    position: relative; }
  .pull--large--seven-tenths {
    right: 70%;
    position: relative; }
  .pull--large--eight-tenths {
    right: 80%;
    position: relative; }
  .pull--large--nine-tenths {
    right: 90%;
    position: relative; }
  .pull--large--one-twelfth {
    right: 8.333%;
    position: relative; }
  .pull--large--two-twelfths {
    right: 16.666%;
    position: relative; }
  .pull--large--three-twelfths {
    right: 25%;
    position: relative; }
  .pull--large--four-twelfths {
    right: 33.333%;
    position: relative; }
  .pull--large--five-twelfths {
    right: 41.666%;
    position: relative; }
  .pull--large--six-twelfths {
    right: 50%;
    position: relative; }
  .pull--large--seven-twelfths {
    right: 58.333%;
    position: relative; }
  .pull--large--eight-twelfths {
    right: 66.666%;
    position: relative; }
  .pull--large--nine-twelfths {
    right: 75%;
    position: relative; }
  .pull--large--ten-twelfths {
    right: 83.333%;
    position: relative; }
  .pull--large--eleven-twelfths {
    right: 91.666%;
    position: relative; } }

/*============================================================================
  TYPOGRAPHY
==============================================================================*/
@font-face {
  font-family: 'CutOutJams2';
  src: url("../fonts/cutoutjams2-regular-webfont.woff2") format("woff2"), url("../fonts/cutoutjams2-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

p {
  line-height: 1.8em;
  margin-bottom: 1.8em; }

.text-huge {
  font-family: 'Roboto Slab', Helvetica, Arial, Verdana, serif;
  font-weight: 700;
  font-size: 22px;
  margin-bottom: 0.2em;
  white-space: pre-line;
  line-height: 0; }
  @media screen and (min-width: 768px) {
    .text-huge {
      font-size: calc(22px + 22 * (100vw - 768px) / 432); } }
  @media screen and (min-width: 1200px) {
    .text-huge {
      font-size: 44px; } }
  .text-huge a {
    white-space: nowrap;
    line-height: 1.2em; }

strong {
  font-weight: 600;
  font-size: inherit; }

em {
  font-style: italic;
  font-weight: inherit; }

a, .link {
  font-weight: inherit;
  font-style: inherit;
  color: #434468;
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1);
  text-decoration: underline;
  text-decoration-skip: ink; }
  a:hover, a:focus, .link:hover, .link:focus {
    color: #2f3049; }
  a:focus, a:active, .link:focus, .link:active {
    outline: 0; }
  a.tdn, .link.tdn {
    text-decoration: none; }
    a.tdn:hover, a.tdn:focus, .link.tdn:hover, .link.tdn:focus {
      text-decoration: underline; }

button.link {
  font-family: 'Roboto Slab', Helvetica, Arial, Verdana, serif;
  font-weight: 700;
  text-decoration: none;
  font-size: 18px;
  font-size: 1.8rem; }
  button.link span {
    text-decoration: underline; }

.link--back {
  text-decoration: none;
  font-family: 'Roboto Slab', Helvetica, Arial, Verdana, serif;
  font-weight: 700; }
  .link--back span {
    text-decoration: underline; }

a:not([href]) {
  color: #333333;
  text-decoration: none; }

img {
  max-width: 100%;
  height: auto;
  display: inline-block;
  vertical-align: middle; }

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-family: 'Roboto Slab', Helvetica, Arial, Verdana, serif;
  font-weight: 700;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  color: #434468;
  line-height: 1.2em;
  margin: 0 0 0.8em; }
  h1 a, .h1 a, h2 a, .h2 a, h3 a, .h3 a, h4 a, .h4 a, h5 a, .h5 a, h6 a, .h6 a {
    text-decoration: none;
    outline: 0;
    font-weight: inherit;
    color: #434468; }
    h1 a:hover, h1 a:focus, .h1 a:hover, .h1 a:focus, h2 a:hover, h2 a:focus, .h2 a:hover, .h2 a:focus, h3 a:hover, h3 a:focus, .h3 a:hover, .h3 a:focus, h4 a:hover, h4 a:focus, .h4 a:hover, .h4 a:focus, h5 a:hover, h5 a:focus, .h5 a:hover, .h5 a:focus, h6 a:hover, h6 a:focus, .h6 a:hover, .h6 a:focus {
      color: #2f3049; }
  h1 strong, .h1 strong, h2 strong, .h2 strong, h3 strong, .h3 strong, h4 strong, .h4 strong, h5 strong, .h5 strong, h6 strong, .h6 strong {
    color: #F38533; }

h1, .h1 {
  font-size: 25px; }
  @media screen and (min-width: 768px) {
    h1, .h1 {
      font-size: calc(25px + 25 * (100vw - 768px) / 432); } }
  @media screen and (min-width: 1200px) {
    h1, .h1 {
      font-size: 50px; } }

h2, .h2 {
  font-size: 25px;
  margin: 0 0 1.4em; }
  @media screen and (min-width: 768px) {
    h2, .h2 {
      font-size: calc(25px + 25 * (100vw - 768px) / 432); } }
  @media screen and (min-width: 1200px) {
    h2, .h2 {
      font-size: 50px; } }

h3, .h3 {
  font-size: 16px;
  margin: 0 0 1em; }
  @media screen and (min-width: 768px) {
    h3, .h3 {
      font-size: calc(16px + 2 * (100vw - 768px) / 256); } }
  @media screen and (min-width: 1024px) {
    h3, .h3 {
      font-size: 18px; } }

h4, .h4 {
  font-size: 16px;
  font-size: 1.6rem; }

.decorated {
  font-family: 'CutOutJams2', Helvetica, Arial, Verdana, serif;
  font-size: 55px;
  color: #434468;
  text-transform: lowercase;
  margin: 0;
  line-height: 0.8em;
  font-weight: 400;
  white-space: nowrap; }
  @media screen and (min-width: 480px) {
    .decorated {
      font-size: calc(55px + 181 * (100vw - 480px) / 544); } }
  @media screen and (min-width: 1024px) {
    .decorated {
      font-size: 236px; } }
  .decorated a {
    text-decoration: none; }

.decorated--big {
  margin-bottom: 45px; }
  @media (min-width: 768px) {
    .decorated--big {
      margin-bottom: 90px;
      margin-top: -40px; } }

.decorated--small {
  font-size: 55px; }
  @media screen and (min-width: 480px) {
    .decorated--small {
      font-size: calc(55px + 145 * (100vw - 480px) / 544); } }
  @media screen and (min-width: 1024px) {
    .decorated--small {
      font-size: 200px; } }

@media (min-width: 768px) {
  .decorated--left {
    transform: translateX(60px); }
    .decorated--left + h2, .decorated--left + .h2 {
      text-align: right; } }

@media (min-width: 1024px) {
  .decorated--left + h2, .decorated--left + .h2 {
    margin-right: 17%; } }

@media (min-width: 768px) {
  .decorated--right {
    transform: translateX(-200px); } }

ul, ol {
  margin: 0 0 1.5em 16px; }
  ul li, ol li {
    line-height: 1.5em;
    padding: 0; }

ul ul, ul ol, ol ol, ol ul {
  margin-bottom: 0; }

ul {
  list-style: none; }
  ul.lined li {
    border-bottom: 1px solid #E3E3E3;
    padding: 5px 0; }
    ul.lined li:first-child {
      padding-top: 0; }
    ul.lined li:last-child {
      padding-bottom: 0;
      border-bottom: none; }

.rte ul li, ul.styled li {
  line-height: 1.8em;
  position: relative;
  font-weight: 400; }
  .rte ul li:before, ul.styled li:before {
    position: absolute;
    content: '>';
    font-family: 'CutOutJams2', Helvetica, Arial, Verdana, serif;
    font-size: 15px;
    font-size: 1.5rem;
    left: -15px;
    font-weight: 300; }

ol {
  list-style: decimal; }

.unstyled, ul.lined, nav ul {
  margin: 0 0 1.5em;
  padding: 0;
  list-style: none; }
  .unstyled ul, ul.lined ul, nav ul ul {
    list-style: none; }
  .unstyled li, ul.lined li, nav ul li {
    list-style: none;
    padding: 0;
    background: none; }

nav ul {
  *zoom: 1; }
  nav ul:before, nav ul:after {
    display: table;
    content: " "; }
  nav ul:after {
    clear: both; }

dl {
  margin: 0 0 1.5em; }

dt, dd {
  line-height: 1.5em; }

dt {
  font-weight: 600; }

dd {
  margin-bottom: 0.5em; }

hr {
  display: block;
  height: 1px;
  border: 0;
  margin: 40px 0;
  position: relative; }
  @media (min-width: 768px) {
    hr {
      margin: 75px 0; } }
  hr:not(.ninja) {
    background-image: linear-gradient(to right, #434468 33%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 13px 3px;
    background-repeat: repeat-x;
    height: 1px; }
  hr.ninja {
    border-color: transparent;
    margin: 50px 0; }

blockquote {
  line-height: inherit;
  padding-left: 20px;
  border-left: 1px solid #B4B4B4;
  margin-bottom: 1.5em;
  font-size: 20px;
  font-size: 2rem;
  font-style: italic; }
  blockquote p {
    margin-bottom: 0.5em; }
  blockquote cite {
    font-size: 14px;
    font-size: 1.4rem; }
  @media (min-width: 768px) {
    blockquote {
      padding-left: 40px; } }

.rte p strong {
  font-family: 'Roboto Slab', Helvetica, Arial, Verdana, serif;
  font-weight: 700;
  color: #434468;
  font-size: 18px;
  font-size: 1.8rem; }

.rte a, .rte .link {
  font-family: 'Roboto Slab', Helvetica, Arial, Verdana, serif;
  font-weight: 700; }

.rte .link {
  text-decoration: none;
  font-size: 18px;
  font-size: 1.8rem; }
  .rte .link span {
    text-decoration: underline; }

.rte small {
  font-size: 14px;
  font-size: 1.4rem; }

/*============================================================================
  TABLES
==============================================================================*/
table {
  width: 100%;
  padding: 0;
  border-collapse: separate;
  border-spacing: 0;
  empty-cells: show;
  margin: 0 0 1.5em; }
  table th, table td {
    padding: 11px 6px;
    text-align: left;
    vertical-align: middle;
    border-bottom: 1px solid #ddd;
    line-height: 1.5em; }
  table th {
    font-weight: bold;
    border-bottom: 2px solid #ddd; }

/*============================================================================
  FORMS
==============================================================================*/
form {
  margin: 0; }

legend {
  border: 0;
  padding: 0;
  white-space: normal;
  *margin-left: -7px;
  font-size: 18px; }

button, input, select, textarea, .button {
  margin: 0;
  vertical-align: baseline;
  *vertical-align: middle;
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1); }

button, input {
  line-height: normal; }

button, select {
  text-transform: none; }

label, .label, input, select, textarea {
  font-family: 'Roboto', Helvetica, Arial, Verdana, sans-serif;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.5em;
  font-weight: 400;
  vertical-align: middle;
  color: #333333; }

label, .label {
  display: block;
  margin-bottom: 8px; }

button, input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
  *overflow: visible;
  border: none; }

input[type="checkbox"], input[type="radio"] {
  cursor: pointer; }

input[type="text"], input[type="search"], input[type="password"],
input[type="email"], input[type="tel"], input[type="date"], input[type="number"], textarea, select {
  padding: 6px 14px;
  margin-bottom: 0;
  display: block;
  background: #fff;
  height: 40px;
  line-height: inherit;
  width: 100%;
  border: 1px solid #434468;
  border-radius: 5px;
  font-size: 14px;
  font-size: 1.4rem;
  color: #434468; }
  input[type="text"]:focus, input[type="search"]:focus, input[type="password"]:focus,
  input[type="email"]:focus, input[type="tel"]:focus, input[type="date"]:focus, input[type="number"]:focus, textarea:focus, select:focus {
    outline: 0;
    border-color: #434468; }

input[type="email"] {
  display: block; }

textarea {
  overflow: auto;
  vertical-align: top;
  resize: none;
  width: 100%;
  height: 125px; }

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  width: auto;
  height: auto;
  padding: 0;
  margin: 3px 0;
  *margin-top: 0;
  *height: 13px;
  *width: 13px;
  line-height: normal;
  border: none; }

input[type="file"] {
  background-color: #fff;
  padding: initial;
  border: initial;
  line-height: initial; }

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box; }

input[type="reset"], input[type="submit"], .button {
  float: left;
  display: block;
  clear: both;
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1);
  outline: 0;
  line-height: 1.2em;
  padding: 10px 20px;
  margin: 0;
  background-color: transparent;
  text-decoration: none;
  text-transform: lowercase;
  color: #434468;
  font-family: 'Roboto Slab', Helvetica, Arial, Verdana, serif;
  font-weight: 700;
  letter-spacing: 2px;
  font-size: 14px;
  font-size: 1.4rem;
  border-radius: 5px;
  border: 2px solid #434468; }
  input[type="reset"] .icon-chevron-right, input[type="reset"] .icon-chevron-left, input[type="submit"] .icon-chevron-right, input[type="submit"] .icon-chevron-left, .button .icon-chevron-right, .button .icon-chevron-left {
    display: inline-block;
    transition: 0.25s cubic-bezier(0, 0, 0.58, 1); }
  input[type="reset"]:hover, input[type="reset"]:focus, input[type="reset"]:active, input[type="submit"]:hover, input[type="submit"]:focus, input[type="submit"]:active, .button:hover, .button:focus, .button:active {
    background-color: transparent; }
    input[type="reset"]:hover .icon-chevron-right, input[type="reset"]:hover .icon-chevron-left, input[type="reset"]:focus .icon-chevron-right, input[type="reset"]:focus .icon-chevron-left, input[type="reset"]:active .icon-chevron-right, input[type="reset"]:active .icon-chevron-left, input[type="submit"]:hover .icon-chevron-right, input[type="submit"]:hover .icon-chevron-left, input[type="submit"]:focus .icon-chevron-right, input[type="submit"]:focus .icon-chevron-left, input[type="submit"]:active .icon-chevron-right, input[type="submit"]:active .icon-chevron-left, .button:hover .icon-chevron-right, .button:hover .icon-chevron-left, .button:focus .icon-chevron-right, .button:focus .icon-chevron-left, .button:active .icon-chevron-right, .button:active .icon-chevron-left {
      transform: translateX(3px); }
  input[type="reset"]:active, input[type="submit"]:active, .button:active {
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2); }
  input[type="reset"]::-moz-focus-inner, input[type="submit"]::-moz-focus-inner, .button::-moz-focus-inner {
    border: 0; }
  .text-center input[type="reset"], .text-center input[type="submit"], .text-center .button {
    float: none;
    display: inline-block; }

.file-input {
  position: relative; }
  .file-input:focus {
    outline: 0;
    border-color: #434468; }
  .file-input label {
    position: relative;
    display: block;
    padding: 10px 15px 10px 65px;
    margin: 0;
    cursor: pointer;
    border: 1px solid #434468;
    border-radius: 5px;
    background: white; }
    .file-input label:before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 60px;
      background: url("../images/upload.svg") center no-repeat;
      background-size: 13px;
      border-radius: 5px; }
  .file-input input[type="file"] {
    position: absolute;
    top: 1px;
    left: 1px;
    width: 1px;
    height: 1px;
    z-index: -1;
    outline: 0; }

button, input[type="text"], input[type="submit"], input[type="button"], input[type="reset"],
input[type="email"], input[type="tel"], input[type="number"], textarea {
  -webkit-appearance: none; }

button:focus, input[type="reset"]:focus, input[type="submit"]:focus, .button:focus, a.button:focus, select:focus {
  outline: 0;
  *outline: inherit; }

.button--divided {
  padding: 0; }
  .button--divided a {
    text-decoration: none;
    padding: 10px 20px;
    line-height: 1.2em;
    display: inline-block;
    float: left; }

.button--download {
  background-color: #434468;
  height: 50px;
  display: inline-block;
  color: #fff;
  font-family: 'Roboto Slab', Helvetica, Arial, Verdana, serif;
  font-weight: 700;
  padding: 15px 25px;
  border-radius: 5px;
  text-transform: lowercase;
  text-decoration: none;
  margin-bottom: 30px;
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1); }
  .button--download span {
    color: #fff;
    vertical-align: middle; }
  .button--download .icon {
    fill: white;
    width: 12px;
    height: 16px;
    margin-left: 5px; }
  .button--download:hover, .product-sheet__button:hover .button--download, .product-sheet__button:focus .button--download, .button--download:focus {
    opacity: 0.8; }

::placeholder {
  color: #434468; }

::-ms-clear {
  display: none; }

input[type="search"] {
  -webkit-appearance: none; }

::-webkit-search-cancel-button {
  -webkit-appearance: none; }

::-webkit-search-results-button {
  -webkit-appearance: none; }

::-webkit-inner-spin-button {
  -webkit-appearance: none; }

::-webkit-outer-spin-button {
  -webkit-appearance: none; }

.checkbox, .radio {
  position: relative; }
  .checkbox label, .radio label {
    padding-left: 35px;
    cursor: pointer;
    font-size: 14px;
    font-size: 1.4rem; }
    .checkbox label:before, .checkbox label:after, .radio label:before, .radio label:after {
      content: "";
      display: block;
      position: absolute;
      transition: 0.25s cubic-bezier(0, 0, 0.58, 1); }
    .checkbox label:before, .radio label:before {
      left: 0;
      top: 0;
      width: 20px;
      height: 20px;
      border: 1px solid #434468; }
    .checkbox label:after, .radio label:after {
      top: 2px;
      left: 6px;
      border: solid #434468;
      border-width: 0 3px 3px 0;
      width: 8px;
      height: 13px;
      transform: rotate(45deg);
      opacity: 0; }
  .checkbox input[type="checkbox"], .checkbox input[type="radio"], .radio input[type="checkbox"], .radio input[type="radio"] {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    visibility: hidden; }
    .checkbox input[type="checkbox"]:checked + label:before, .checkbox input[type="radio"]:checked + label:before, .radio input[type="checkbox"]:checked + label:before, .radio input[type="radio"]:checked + label:before {
      border-color: #2f3049; }
    .checkbox input[type="checkbox"]:checked + label:after, .checkbox input[type="radio"]:checked + label:after, .radio input[type="checkbox"]:checked + label:after, .radio input[type="radio"]:checked + label:after {
      opacity: 1; }

.checkbox label:before {
  border-radius: 5px; }

.checkbox-other input {
  background-color: transparent;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #434468; }

.radio label:before, .radio label:after {
  border-radius: 100%; }

.radio label:after {
  border: 0;
  transform: none;
  top: 4px;
  left: 4px;
  width: 12px;
  height: 12px;
  background-color: #2f3049; }

.grid__item > .radio:last-child label {
  margin-bottom: 0; }

form .row {
  margin-bottom: 0; }

fieldset {
  width: auto; }
  fieldset p, fieldset ul, fieldset li {
    *zoom: 1; }
    fieldset p:before, fieldset p:after, fieldset ul:before, fieldset ul:after, fieldset li:before, fieldset li:after {
      display: table;
      content: " "; }
    fieldset p:after, fieldset ul:after, fieldset li:after {
      clear: both; }
  fieldset ul {
    margin-left: -20px; }
    fieldset ul li {
      list-style: none;
      background: none;
      margin: 0 0 20px;
      float: left;
      width: 100%;
      padding: 0 0 0 20px; }
      fieldset ul li.error input {
        border-color: #FA3323; }
      fieldset ul li.space {
        margin-top: 25px; }
      @media (min-width: 768px) {
        fieldset ul li.one-half {
          width: 50%; }
        fieldset ul li.one-fourth {
          width: 25%; } }

.buttons {
  *zoom: 1; }
  .buttons:before, .buttons:after {
    display: table;
    content: " "; }
  .buttons:after {
    clear: both; }
  .buttons button, .buttons input[type="reset"], .buttons input[type="submit"], .buttons .button, .buttons a.button {
    float: left;
    clear: none;
    margin-right: 15px; }
    .buttons button.right, .buttons input[type="reset"].right, .buttons input[type="submit"].right, .buttons .button.right, .buttons a.button.right {
      float: right;
      margin-right: 0;
      margin-left: 15px; }

.buttons--language {
  text-align: center; }
  .buttons--language a.button,
  .buttons--language .button {
    font-family: 'Roboto Mono', Helvetica, Arial, Verdana, monospace;
    font-weight: 700;
    font-size: 18px;
    font-size: 1.8rem;
    text-transform: none;
    letter-spacing: 0;
    padding: 10px 12px;
    color: #fff;
    border-color: #fff;
    float: none;
    display: block;
    vertical-align: top;
    width: 175px;
    margin: 0 auto 10px; }
  .buttons--language .button--divided {
    padding: 0;
    width: 175px; }
    .buttons--language .button--divided a {
      padding: 10px 10px;
      position: relative;
      color: #fff;
      width: 170px;
      float: none; }
      .buttons--language .button--divided a + a:before {
        content: '';
        position: absolute;
        width: 18px;
        height: 2px;
        background: #fff;
        top: 1px;
        left: 74px; }
      .buttons--language .button--divided a:first-child {
        padding-left: 12px; }
      .buttons--language .button--divided a:last-child {
        padding-right: 15px; }
  .buttons--language span {
    vertical-align: middle; }
  .buttons--language .icon-flag {
    width: 15px;
    height: 15px;
    vertical-align: middle; }
  @media (min-width: 768px) {
    .buttons--language a.button,
    .buttons--language .button {
      width: auto;
      display: inline-block;
      margin: 0 6px 10px 0; }
    .buttons--language .button--divided a {
      float: left;
      width: auto; }
      .buttons--language .button--divided a + a:before {
        width: 2px;
        height: 18px;
        top: 13px;
        left: -2px; } }

.a-c .button, .a-c a.button, .a-c button {
  float: none;
  clear: none;
  display: inline-block; }

.grecaptcha-badge {
  display: none; }

.newsletter {
  margin-bottom: 30px;
  *zoom: 1;
  max-width: 400px; }
  .newsletter:before, .newsletter:after {
    display: table;
    content: " "; }
  .newsletter:after {
    clear: both; }
  .newsletter label {
    color: #434468;
    margin-bottom: 22px; }
  .newsletter input {
    float: left;
    width: calc(100% - 40px);
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-width: 1px;
    border-right: none;
    border-color: #434468 !important;
    color: #434468 !important; }
  .newsletter button {
    background-color: #434468;
    width: 40px;
    height: 40px;
    line-height: 0;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    float: right; }
    .newsletter button .icon {
      fill: #fff;
      width: 14px;
      height: 14px; }
    .newsletter button:hover, .newsletter button:focus {
      background-color: #2f3049; }
  .newsletter ::placeholder {
    color: #434468 !important; }

.little-miracle-form {
  color: #434468; }
  @media (min-width: 768px) {
    .little-miracle-form input {
      padding: 32px; } }
  @media (min-width: 768px) {
    .little-miracle-form select {
      height: calc(1.4rem + 32px * 2);
      text-indent: 32px;
      padding: 0; } }
  .little-miracle-form .btn--green {
    background-color: #28EDCC;
    border: none;
    width: 100%;
    color: #fff; }
    .little-miracle-form .btn--green:hover {
      background-color: #13dfbc; }
  .little-miracle-form .form__terms-conditions {
    padding-left: 32px;
    font-size: .9em; }

.js-expand-form {
  text-decoration: underline;
  font-weight: 700;
  font-size: 1.2em;
  cursor: pointer;
  margin: 0; }

.form__item--optional {
  display: none; }

.formError {
  z-index: 990; }

.formError .formErrorContent {
  z-index: 991; }

.formError .formErrorArrow {
  z-index: 996; }

.formErrorInsideDialog.formError {
  z-index: 5000; }

.formErrorInsideDialog.formError .formErrorContent {
  z-index: 5001; }

.formErrorInsideDialog.formError .formErrorArrow {
  z-index: 5006; }

.inputContainer {
  position: relative;
  float: left; }

.formError, .formError * {
  box-sizing: content-box; }

.formError {
  position: absolute;
  top: 300px;
  left: 300px;
  display: block;
  cursor: pointer;
  margin: 0 0 0 -105px; }
  .checkbox .formError, .radio .formError {
    margin: 0 !important;
    left: -10px !important;
    top: auto !important;
    bottom: 100%; }

label[for=subject] + .formError {
  margin: 0 0 0 -133px; }

.ajaxSubmit {
  padding: 20px;
  background: #55ea55;
  border: 1px solid #999;
  display: none; }

.formError .formErrorContent {
  background: #FA3323;
  position: relative;
  color: #fff;
  width: 150px;
  border: 1px solid #FA3323;
  padding: 4px 10px 4px 10px;
  font-size: 14px;
  font-size: 1.4rem;
  border-radius: 5px; }

.formError .formErrorArrow {
  position: relative;
  margin: -2px 0 0 145px;
  height: 10px; }
  .formError .formErrorArrow:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    border: 8px solid transparent;
    border-bottom: 0;
    border-top-color: #FA3323; }
  .checkbox .formError .formErrorArrow, .radio .formError .formErrorArrow {
    margin: -2px 0 0 12px; }

.formError .formErrorArrowBottom {
  box-shadow: none;
  margin: 0px 0 0 12px;
  top: 2px; }

.formError .formErrorArrow div {
  display: none; }

/*!
	Modaal - accessible modals - v0.4.4
	by Humaan, for all humans.
	http://humaan.com
 */
.modaal-noscroll {
  overflow: hidden; }

.modaal-accessible-hide {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden; }

.modaal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  opacity: 0;
  background: #fff !important; }

.modaal-wrapper {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  overflow: auto;
  opacity: 1;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
  transition: all 0.3s ease-in-out; }
  .modaal-wrapper * {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-backface-visibility: hidden; }
  .modaal-wrapper .modaal-close {
    border: none;
    background: transparent;
    padding: 0;
    -webkit-appearance: none; }
  .modaal-wrapper.modaal-start_none {
    display: none;
    opacity: 1; }
  .modaal-wrapper.modaal-start_fade {
    opacity: 0; }
  .modaal-wrapper *[tabindex="0"] {
    outline: none !important; }
  .modaal-wrapper.modaal-fullscreen {
    overflow: hidden; }

.modaal-outer-wrapper {
  display: table;
  position: relative;
  width: 100%;
  height: 100%; }
  .modaal-fullscreen .modaal-outer-wrapper {
    display: block; }

.modaal-inner-wrapper {
  display: table-cell;
  width: 100%;
  height: 100%;
  position: relative;
  vertical-align: middle;
  text-align: center;
  padding: 80px 25px; }
  .modaal-fullscreen .modaal-inner-wrapper {
    padding: 0;
    display: block;
    vertical-align: top; }

.modaal-container {
  position: relative;
  display: inline-block;
  width: 100%;
  margin: auto;
  text-align: left;
  color: #000;
  max-width: 1000px;
  border-radius: 0px;
  background: #fff;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  cursor: auto; }
  .modaal-container.is_loading {
    height: 100px;
    width: 100px;
    overflow: hidden; }
  .modaal-fullscreen .modaal-container {
    max-width: none;
    height: 100%;
    overflow: auto; }

.modaal-close {
  position: fixed;
  right: 20px;
  top: 20px;
  color: #fff;
  cursor: pointer;
  opacity: 1;
  width: 50px;
  height: 50px;
  background: rgba(0, 0, 0, 0);
  border-radius: 100%;
  transition: all 0.2s ease-in-out; }
  .modaal-close:focus, .modaal-close:hover {
    outline: none;
    background: #fff; }
    .modaal-close:focus:before, .modaal-close:focus:after, .modaal-close:hover:before, .modaal-close:hover:after {
      background: #434468; }
  .modaal-close span {
    position: absolute !important;
    clip: rect(1px 1px 1px 1px);
    /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0 !important;
    border: 0 !important;
    height: 1px !important;
    width: 1px !important;
    overflow: hidden; }
  .modaal-close:before, .modaal-close:after {
    display: block;
    content: " ";
    position: absolute;
    top: 14px;
    left: 23px;
    width: 4px;
    height: 22px;
    border-radius: 4px;
    background: #434468;
    transition: background 0.2s ease-in-out; }
  .modaal-close:before {
    transform: rotate(-45deg); }
  .modaal-close:after {
    transform: rotate(45deg); }
  .modaal-fullscreen .modaal-close {
    background: #afb7bc;
    right: 10px;
    top: 10px; }

.modaal-content-container {
  padding: 30px; }

.modaal-wrapper:not(.mobile-menu) .modaal-container {
  border-radius: 20px;
  overflow: hidden;
  width: auto; }

.modaal-wrapper:not(.mobile-menu) .modaal-content-container {
  padding: 0; }

.modaal-confirm-wrap {
  padding: 30px 0 0;
  text-align: center;
  font-size: 0; }

.modaal-confirm-btn {
  font-size: 14px;
  display: inline-block;
  margin: 0 10px;
  vertical-align: middle;
  cursor: pointer;
  border: none;
  background: transparent; }
  .modaal-confirm-btn.modaal-ok {
    padding: 10px 15px;
    color: #fff;
    background: #555;
    border-radius: 3px;
    transition: background 0.2s ease-in-out; }
    .modaal-confirm-btn.modaal-ok:hover {
      background: #2f2f2f; }
  .modaal-confirm-btn.modaal-cancel {
    text-decoration: underline; }
    .modaal-confirm-btn.modaal-cancel:hover {
      text-decoration: none;
      color: #2f2f2f; }

@keyframes instaReveal {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-o-keyframes instaReveal {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-moz-keyframes instaReveal {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes instaReveal {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-ms-keyframes instaReveal {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.modaal-instagram .modaal-container {
  width: auto;
  background: transparent;
  box-shadow: none !important; }

.modaal-instagram .modaal-content-container {
  padding: 0;
  background: transparent; }

.modaal-instagram .modaal-content-container > blockquote {
  width: 1px !important;
  height: 1px !important;
  opacity: 0 !important; }

.modaal-instagram iframe {
  opacity: 0;
  margin: -6px !important;
  border-radius: 0 !important;
  width: 1000px !important;
  max-width: 800px !important;
  box-shadow: none !important;
  animation: instaReveal 1s linear forwards; }

.modaal-image .modaal-inner-wrapper {
  padding-left: 140px;
  padding-right: 140px; }

.modaal-image .modaal-container {
  width: auto;
  max-width: 100%; }

.modaal-gallery-wrap {
  position: relative;
  color: #fff; }

.modaal-gallery-item {
  display: none; }
  .modaal-gallery-item img {
    display: block; }
  .modaal-gallery-item.is_active {
    display: block; }

.modaal-gallery-label {
  position: absolute;
  left: 0;
  width: 100%;
  margin: 20px 0 0;
  font-size: 18px;
  text-align: center;
  color: #fff; }
  .modaal-gallery-label:focus {
    outline: none; }

.modaal-gallery-control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  opacity: 1;
  cursor: pointer;
  color: #fff;
  width: 50px;
  height: 50px;
  background: rgba(0, 0, 0, 0);
  border: none;
  border-radius: 100%;
  transition: all 0.2s ease-in-out; }
  .modaal-gallery-control.is_hidden {
    opacity: 0;
    cursor: default; }
  .modaal-gallery-control:focus, .modaal-gallery-control:hover {
    outline: none;
    background: #fff; }
    .modaal-gallery-control:focus:before, .modaal-gallery-control:focus:after, .modaal-gallery-control:hover:before, .modaal-gallery-control:hover:after {
      background: #afb7bc; }
  .modaal-gallery-control span {
    position: absolute !important;
    clip: rect(1px 1px 1px 1px);
    /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0 !important;
    border: 0 !important;
    height: 1px !important;
    width: 1px !important;
    overflow: hidden; }
  .modaal-gallery-control:before, .modaal-gallery-control:after {
    display: block;
    content: " ";
    position: absolute;
    top: 16px;
    left: 25px;
    width: 4px;
    height: 18px;
    border-radius: 4px;
    background: #fff;
    transition: background 0.2s ease-in-out; }
  .modaal-gallery-control:before {
    margin: -5px 0 0;
    transform: rotate(-45deg); }
  .modaal-gallery-control:after {
    margin: 5px 0 0;
    transform: rotate(45deg); }

.modaal-gallery-next-inner {
  left: 100%;
  margin-left: 40px; }

.modaal-gallery-next-outer {
  right: 45px; }

.modaal-gallery-prev:before, .modaal-gallery-prev:after {
  left: 22px; }

.modaal-gallery-prev:before {
  margin: 5px 0 0;
  transform: rotate(-45deg); }

.modaal-gallery-prev:after {
  margin: -5px 0 0;
  transform: rotate(45deg); }

.modaal-gallery-prev-inner {
  right: 100%;
  margin-right: 40px; }

.modaal-gallery-prev-outer {
  left: 45px; }

.modaal-video-wrap {
  margin: auto 50px;
  position: relative; }

.modaal-video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  background: #000;
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto; }
  .modaal-video-container iframe,
  .modaal-video-container object,
  .modaal-video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modaal-iframe .modaal-content {
  width: 100%;
  height: 100%; }

.modaal-iframe-elem {
  width: 100%;
  height: 100%;
  display: block; }

@media only screen and (min-width: 1400px) {
  .modaal-video-container {
    padding-bottom: 0;
    height: 731px; } }

@media only screen and (max-width: 1140px) {
  .modaal-image .modaal-inner-wrapper {
    padding-left: 25px;
    padding-right: 25px; }
  .modaal-gallery-control {
    top: auto;
    bottom: 20px;
    transform: none;
    background: rgba(0, 0, 0, 0.7); }
    .modaal-gallery-control:before, .modaal-gallery-control:after {
      background: #fff; }
  .modaal-gallery-next {
    left: auto;
    right: 20px; }
  .modaal-gallery-prev {
    left: 20px;
    right: auto; } }

@media screen and (max-width: 900px) {
  .modaal-instagram iframe {
    width: 500px !important; } }

@media screen and (max-height: 1100px) {
  .modaal-instagram iframe {
    width: 700px !important; } }

@media screen and (max-height: 1000px) {
  .modaal-inner-wrapper {
    padding-top: 60px;
    padding-bottom: 60px; }
  .modaal-instagram iframe {
    width: 600px !important; } }

@media screen and (max-height: 900px) {
  .modaal-instagram iframe {
    width: 500px !important; }
  .modaal-video-container {
    max-width: 900px;
    max-height: 510px; } }

@media only screen and (max-width: 600px) {
  .modaal-instagram iframe {
    width: 280px !important; } }

@media only screen and (max-height: 820px) {
  .modaal-gallery-label {
    display: none; } }

.modaal-loading-spinner {
  background: none;
  position: absolute;
  width: 200px;
  height: 200px;
  top: 50%;
  left: 50%;
  margin: -100px 0 0 -100px;
  transform: scale(0.25); }

@-ms-keyframes modaal-loading-spinner {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5); }
  100% {
    opacity: .1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); } }

@-moz-keyframes modaal-loading-spinner {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5); }
  100% {
    opacity: .1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); } }

@-webkit-keyframes modaal-loading-spinner {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5); }
  100% {
    opacity: .1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); } }

@-o-keyframes modaal-loading-spinner {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5); }
  100% {
    opacity: .1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); } }

@keyframes modaal-loading-spinner {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5); }
  100% {
    opacity: .1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); } }

.modaal-loading-spinner > div {
  width: 24px;
  height: 24px;
  margin-left: 4px;
  margin-top: 4px;
  position: absolute; }

.modaal-loading-spinner > div > div {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  background: #fff; }

.modaal-loading-spinner > div:nth-of-type(1) > div {
  -ms-animation: modaal-loading-spinner 1s linear infinite;
  -moz-animation: modaal-loading-spinner 1s linear infinite;
  -webkit-animation: modaal-loading-spinner 1s linear infinite;
  -o-animation: modaal-loading-spinner 1s linear infinite;
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: 0s;
  -moz-animation-delay: 0s;
  -webkit-animation-delay: 0s;
  -o-animation-delay: 0s;
  animation-delay: 0s; }

.modaal-loading-spinner > div:nth-of-type(2) > div, .modaal-loading-spinner > div:nth-of-type(3) > div {
  -ms-animation: modaal-loading-spinner 1s linear infinite;
  -moz-animation: modaal-loading-spinner 1s linear infinite;
  -webkit-animation: modaal-loading-spinner 1s linear infinite;
  -o-animation: modaal-loading-spinner 1s linear infinite; }

.modaal-loading-spinner > div:nth-of-type(1) {
  -ms-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(45deg) translate(70px, 0); }

.modaal-loading-spinner > div:nth-of-type(2) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: .12s;
  -moz-animation-delay: .12s;
  -webkit-animation-delay: .12s;
  -o-animation-delay: .12s;
  animation-delay: .12s; }

.modaal-loading-spinner > div:nth-of-type(2) {
  -ms-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(90deg) translate(70px, 0); }

.modaal-loading-spinner > div:nth-of-type(3) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: .25s;
  -moz-animation-delay: .25s;
  -webkit-animation-delay: .25s;
  -o-animation-delay: .25s;
  animation-delay: .25s; }

.modaal-loading-spinner > div:nth-of-type(4) > div, .modaal-loading-spinner > div:nth-of-type(5) > div {
  -ms-animation: modaal-loading-spinner 1s linear infinite;
  -moz-animation: modaal-loading-spinner 1s linear infinite;
  -webkit-animation: modaal-loading-spinner 1s linear infinite;
  -o-animation: modaal-loading-spinner 1s linear infinite; }

.modaal-loading-spinner > div:nth-of-type(3) {
  -ms-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(135deg) translate(70px, 0); }

.modaal-loading-spinner > div:nth-of-type(4) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: .37s;
  -moz-animation-delay: .37s;
  -webkit-animation-delay: .37s;
  -o-animation-delay: .37s;
  animation-delay: .37s; }

.modaal-loading-spinner > div:nth-of-type(4) {
  -ms-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(180deg) translate(70px, 0); }

.modaal-loading-spinner > div:nth-of-type(5) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: .5s;
  -moz-animation-delay: .5s;
  -webkit-animation-delay: .5s;
  -o-animation-delay: .5s;
  animation-delay: .5s; }

.modaal-loading-spinner > div:nth-of-type(6) > div, .modaal-loading-spinner > div:nth-of-type(7) > div {
  -ms-animation: modaal-loading-spinner 1s linear infinite;
  -moz-animation: modaal-loading-spinner 1s linear infinite;
  -webkit-animation: modaal-loading-spinner 1s linear infinite;
  -o-animation: modaal-loading-spinner 1s linear infinite; }

.modaal-loading-spinner > div:nth-of-type(5) {
  -ms-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(225deg) translate(70px, 0); }

.modaal-loading-spinner > div:nth-of-type(6) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: .62s;
  -moz-animation-delay: .62s;
  -webkit-animation-delay: .62s;
  -o-animation-delay: .62s;
  animation-delay: .62s; }

.modaal-loading-spinner > div:nth-of-type(6) {
  -ms-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(270deg) translate(70px, 0); }

.modaal-loading-spinner > div:nth-of-type(7) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: .75s;
  -moz-animation-delay: .75s;
  -webkit-animation-delay: .75s;
  -o-animation-delay: .75s;
  animation-delay: .75s; }

.modaal-loading-spinner > div:nth-of-type(7) {
  -ms-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(315deg) translate(70px, 0); }

.modaal-loading-spinner > div:nth-of-type(8) > div {
  -ms-animation: modaal-loading-spinner 1s linear infinite;
  -moz-animation: modaal-loading-spinner 1s linear infinite;
  -webkit-animation: modaal-loading-spinner 1s linear infinite;
  -o-animation: modaal-loading-spinner 1s linear infinite;
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: .87s;
  -moz-animation-delay: .87s;
  -webkit-animation-delay: .87s;
  -o-animation-delay: .87s;
  animation-delay: .87s; }

.modaal-loading-spinner > div:nth-of-type(8) {
  -ms-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(360deg) translate(70px, 0); }

.little-miracle-landing .main {
  display: flex;
  flex-direction: column;
  padding: 0;
  height: 100vh; }

.little-miracle-landing .container--language {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center; }
  .little-miracle-landing .container--language h3 {
    font-size: 1.5em; }

.little-miracle-landing .hero {
  height: 70vh;
  padding-bottom: 0; }

.little-miracle-landing .buttons--language a {
  border-color: #434468;
  color: #434468; }

.little-miracle-landing .logo {
  fill: #fff; }

.arizona-landing .main {
  display: flex;
  flex-direction: column;
  padding: 0;
  height: 100vh; }

.arizona-landing .container--language {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center; }
  .arizona-landing .container--language h3 {
    font-size: 1.5em; }

.arizona-landing .hero {
  height: 40vh;
  padding-bottom: 0; }
  .arizona-landing .hero .hero__background {
    background-position: 15% center; }
  @media (min-width: 1024px) {
    .arizona-landing .hero {
      height: 400px; } }
  @media (min-width: 1680px) {
    .arizona-landing .hero {
      height: 45vh; } }

.arizona-landing .buttons--language a {
  border-color: #434468;
  color: #434468; }

.arizona-landing .logo {
  fill: #fff; }

.hero--giveaway {
  height: auto !important;
  min-height: 100vh; }
  .hero--giveaway .hero__background video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.3; }
  .hero--giveaway .hero__background--image {
    opacity: 0.3; }
  @media (max-width: 766px) {
    .hero--giveaway {
      padding-bottom: unset !important; } }

.giveaway__title {
  margin-bottom: 30px; }
  .giveaway__title h1 {
    font-size: 18px;
    line-height: 1.5em;
    padding: 70px 0 0;
    text-transform: uppercase;
    margin-bottom: 0; }
    @media screen and (min-width: 768px) {
      .giveaway__title h1 {
        font-size: calc(18px + 5 * (100vw - 768px) / 256); } }
    @media screen and (min-width: 1024px) {
      .giveaway__title h1 {
        font-size: 23px; } }
  .giveaway__title .subtitle {
    font-size: 14px;
    margin-top: 0.5em; }
    @media screen and (min-width: 768px) {
      .giveaway__title .subtitle {
        font-size: calc(14px + 2 * (100vw - 768px) / 256); } }
    @media screen and (min-width: 1024px) {
      .giveaway__title .subtitle {
        font-size: 16px; } }

.giveaway__content-wrapper .content-section {
  overflow: visible; }

@media (min-width: 768px) {
  .giveaway__content-wrapper {
    height: 100%;
    display: flex;
    align-items: center; } }

.giveaway__info {
  font-size: 15px;
  max-width: 54ch; }

.giveaway__logos {
  display: flex;
  align-items: baseline;
  gap: 30px; }

.giveaway__content {
  position: relative; }
  .giveaway__content .button,
  .giveaway__content input[type="submit"] {
    background-color: #434468;
    color: #fff; }

.js-giveaway-step-2,
.js-giveaway-step-3 {
  display: none; }

.form__info {
  font-size: 12px;
  color: #2f3049; }
  @media screen and (min-width: 768px) {
    .form__info {
      font-size: calc(12px + 2 * (100vw - 768px) / 256); } }
  @media screen and (min-width: 1024px) {
    .form__info {
      font-size: 14px; } }

.giveaway__active-o2-visual {
  display: none; }
  @media (min-width: 768px) {
    .giveaway__active-o2-visual {
      display: block;
      position: absolute;
      top: -30px;
      right: -180px;
      width: 200px;
      height: auto;
      transform: rotate(15deg);
      z-index: -1; } }

/*============================================================================
  #General Variables
==============================================================================*/
/*============================================================================
  #General Variables
==============================================================================*/
.b2b-shop {
  padding: 0 20px;
  margin: 0 auto 40px;
  position: relative;
  max-width: 1600px; }
  .b2b-shop.b2b-shop--preview-mode .b2b-cart__line-items {
    display: none; }
  .b2b-shop.b2b-shop--preview-mode .b2b-shop__filters {
    display: none; }
  .b2b-shop a {
    text-decoration: underline; }
  @media (min-width: 768px) {
    .b2b-shop {
      padding: 0 40px; } }

/* osborne overrides */
.b2b-shop .row {
  padding: 0;
  margin-left: -1.25em;
  margin-right: -1.25em;
  width: auto;
  max-width: inherit; }

.b2b-shop .divider {
  margin-bottom: 40px; }

.b2b-shop .breadcrumbs a {
  text-decoration: none; }

.b2b-shop .tags-table ul li {
  min-width: 100px;
  margin: 0 5px 20px; }
  .b2b-shop .tags-table ul li a {
    padding: 55px 25px 15px 25px;
    text-decoration: none; }

.b2b-shop__header {
  text-align: center;
  margin-bottom: 20px;
  margin-top: 40px; }
  @media (min-width: 768px) {
    .b2b-shop__header {
      margin-bottom: 40px; } }

.b2b-shop__title {
  margin-bottom: 0.4em; }

.b2b-shop__header-body {
  margin-bottom: 20px; }
  @media (min-width: 768px) {
    .b2b-shop__header-body {
      margin-bottom: 50px; } }

.b2b__vh {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0; }

.b2b-shop__grid {
  position: relative;
  *zoom: 1;
  margin: 40px 0 40px -40px; }
  .b2b-shop__grid:before, .b2b-shop__grid:after {
    display: table;
    content: " "; }
  .b2b-shop__grid:after {
    clear: both; }
  @media (min-width: 768px) {
    .b2b-shop__grid {
      margin-top: 60px; } }

.b2b-shop__content,
.b2b-shop__sidebar {
  padding-left: 40px;
  float: left;
  width: 100%; }

@media (min-width: 768px) {
  .b2b-shop__content {
    width: 50%; } }

@media (min-width: 768px) {
  .b2b-shop__sidebar {
    width: 50%; } }

.b2b-tags-row {
  margin-bottom: 20px; }
  @media (min-width: 768px) {
    .b2b-tags-row {
      margin-bottom: 40px;
      text-align: center; } }

.b2b-tags-row__list {
  list-style: none;
  margin: 0 0 15px;
  padding: 0; }
  .b2b-tags-row__list li {
    display: inline-block;
    margin: 0 10px; }
  .b2b-tags-row__list a {
    text-decoration: none; }
    .b2b-tags-row__list a:hover, .b2b-tags-row__list a:focus {
      text-decoration: underline; }
  @media (min-width: 768px) {
    .b2b-tags-row__list {
      display: inline-block; }
      .b2b-tags-row__list + .b2b-tags-row__list {
        margin-left: 30px;
        padding: 0 0 0 30px;
        border-left: 1px solid #555; } }

.b2b-tags-row__list .active a, .b2b-shop .tags-table .active a {
  text-decoration: underline;
  position: relative;
  padding-right: 30px; }
  .b2b-tags-row__list .active a:before, .b2b-tags-row__list .active a:after, .b2b-shop .tags-table .active a:before, .b2b-shop .tags-table .active a:after {
    content: "";
    display: block;
    position: absolute;
    right: 0;
    top: 2px;
    width: 20px;
    height: 20px;
    transition: 0.3; }
  .b2b-tags-row__list .active a:before, .b2b-shop .tags-table .active a:before {
    border: 1px solid #000;
    border-radius: 100%; }
  .b2b-tags-row__list .active a:after, .b2b-shop .tags-table .active a:after {
    content: "\2715";
    font-size: 12px;
    text-align: center;
    line-height: 22px; }
  .b2b-tags-row__list .active a:hover:before, .b2b-tags-row__list .active a:hover:after, .b2b-tags-row__list .active a:focus:before, .b2b-tags-row__list .active a:focus:after, .b2b-shop .tags-table .active a:hover:before, .b2b-shop .tags-table .active a:hover:after, .b2b-shop .tags-table .active a:focus:before, .b2b-shop .tags-table .active a:focus:after {
    opacity: 0.7; }

.b2b-shop .tags-table .active a {
  text-decoration: none; }
  .b2b-shop .tags-table .active a:before, .b2b-shop .tags-table .active a:after {
    right: -10px;
    top: -10px; }

.b2b-form {
  margin: 0 0 40px; }

.b2b-form--preview-mode .b2b-form__list-item:not(.b2b-form__list-item--active) {
  display: none; }

.b2b-form--preview-mode .b2b-form__list-item--active .b2b-form__clear {
  visibility: hidden; }

.b2b-form--preview-mode .b2b-form__select {
  pointer-events: none;
  cursor: auto;
  background: #fff; }

.b2b-form__list {
  list-style: none;
  padding: 0;
  margin: 0; }

.b2b-form__list-item {
  *zoom: 1;
  border-bottom: 1px solid #e5e5e5;
  padding: 10px 10px 0; }
  .b2b-form__list-item:before, .b2b-form__list-item:after {
    display: table;
    content: " "; }
  .b2b-form__list-item:after {
    clear: both; }
  @media (min-width: 768px) {
    .b2b-form__list-item {
      display: flex; } }

@media (min-width: 768px) {
  .b2b-form__product {
    float: left;
    width: 100px;
    display: flex; } }

@media (min-width: 1400px) {
  .b2b-form__product {
    width: 100px; } }

.b2b-form__product-image {
  display: inline-block;
  margin-right: 15px; }
  .b2b-form__product-image img {
    width: 50px; }

.b2b-form__variants {
  *zoom: 1;
  list-style: none;
  padding: 0;
  margin: 0;
  float: left;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 10px; }
  .b2b-form__variants:before, .b2b-form__variants:after {
    display: table;
    content: " "; }
  .b2b-form__variants:after {
    clear: both; }
  @media (min-width: 768px) {
    .b2b-form__variants {
      flex: 1; } }

.b2b-form__variants-item {
  float: left;
  margin-left: 15px;
  margin-bottom: 10px; }

.b2b-form__label {
  margin-bottom: 5px;
  font-size: 14px; }

.b2b-form__select {
  width: 90px;
  font-size: 14px;
  margin: 0; }
  .b2b-form__select::-webkit-inner-spin-button, .b2b-form__select::-webkit-outer-spin-button {
    -webkit-appearance: inner-spin-button !important;
    opacity: 1;
    margin-left: 10px; }
  .b2b-form__select:disabled {
    background-color: #efefef; }

.b2b-form__actions {
  margin: 0;
  display: flex;
  margin-top: 15px; }
  @media (min-width: 768px) {
    .b2b-form__actions {
      margin-top: 28px;
      margin-left: 20px; } }

.b2b-form__product-text {
  text-align: left; }

.b2b-form__product-title {
  min-width: 200px;
  max-width: 300px; }

.b2b-form__product-quantity {
  width: 33px;
  height: 33px;
  background-color: #efefef;
  border-radius: 100%;
  text-align: center;
  line-height: 33px;
  display: block;
  margin-right: 15px;
  font-size: 14px;
  border: 1px solid #efefef;
  visibility: hidden;
  display: none; }

.b2b-form__list-item--active .b2b-form__product-quantity {
  background-color: #fff;
  border-color: #e5e5e5;
  visibility: visible; }

.b2b-form__list-item--active .b2b-form__clear {
  visibility: visible; }

.b2b-form__clear {
  background-color: #fff;
  width: auto;
  padding: 0;
  width: 20px;
  height: 20px;
  position: relative;
  display: block;
  margin: 6px 0 0 6px;
  visibility: hidden; }
  .b2b-form__clear:before, .b2b-form__clear:after {
    content: "";
    display: block;
    position: absolute;
    top: 2px;
    left: 2px;
    border: 1px solid #000;
    height: 16px;
    width: 1px;
    transform: rotate(45deg); }
  .b2b-form__clear:after {
    transform: rotate(-45deg); }
  .b2b-form__clear:hover, .b2b-form__clear:focus {
    background-color: #fff;
    opacity: 0.6; }

.b2b-pagination {
  margin-bottom: 40px; }
  .b2b-pagination > span {
    display: inline-block;
    line-height: 1; }
  .b2b-pagination a {
    display: block; }
  .b2b-pagination a,
  .b2b-pagination .page.current {
    padding: 8px; }

.b2b-cart {
  border: 1px solid #e5e5e5;
  padding: 15px;
  font-size: 14px; }
  .b2b-cart label {
    font-size: 14px; }
  .b2b-cart textarea, .b2b-cart select {
    margin-bottom: 0; }
  .b2b-cart textarea {
    height: 70px; }
  .b2b-cart p {
    margin-bottom: 1.5em; }

.b2b-cart__form {
  position: relative; }
  .b2b-cart__form:before, .b2b-cart__form:after {
    content: "";
    display: block;
    position: absolute;
    transition: 0.3s;
    visibility: hidden;
    opacity: 0;
    z-index: 100; }
  .b2b-cart__form:before {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.5); }
  .b2b-cart__form:after {
    width: 50px;
    height: 50px;
    bottom: 50px;
    left: 50%;
    margin: -25px 0 0 -25px;
    background: url("../images/b2b-loading-spinner.svg") top left no-repeat;
    background-size: cover; }

.b2b-cart__form--is-loading:before, .b2b-cart__form--is-loading:after {
  opacity: 1;
  visibility: visible; }

.b2b-shop__error {
  padding: 10px;
  background-color: #fdf3f0;
  color: #bf0711;
  margin-bottom: 15px; }

.b2b-cart__line-items {
  list-style: none;
  margin: 0 0 20px;
  padding: 0; }
  .b2b-cart__line-items li {
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 5px;
    margin-bottom: 5px;
    display: flex; }

.b2b-cart__line-items-quantity {
  min-width: 20px; }

.b2b-cart__submit,
.b2b-cart__preview {
  width: 100%;
  text-align: center;
  margin: 0;
  text-decoration: none !important; }

.b2b-cart__back-to-edit {
  text-align: center;
  margin: 20px 0 0; }

.js-b2b-preview-mode {
  display: none; }

.b2b-cart__info {
  font-style: italic;
  font-size: 12px;
  margin: 20px 0 0; }
  .b2b-cart__info p {
    margin: 0; }

.fancybox-button[disabled] div {
  background: #1e1e1e; }

.b2b-cart__label {
  float: left;
  font-weight: bolder; }

.b2b-form__brand-title {
  font-size: 18px;
  margin-top: 20px; }

.b2b-form__packaging-title {
  margin-top: 10px;
  font-size: 14px;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 20px; }

/* =============================================================================
   LAYOUT
   ========================================================================== */
html {
  font-size: 62.5%; }

body {
  font-family: 'Roboto', Helvetica, Arial, Verdana, sans-serif;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.5em;
  font-weight: 400;
  color: #333333;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: rgba(0, 0, 0, 0); }

.content-editable {
  background-color: rgba(113, 255, 189, 0.3); }
  .content-editable:focus {
    background-color: inherit; }

.page-wrap {
  position: relative; }
  @media (min-width: 768px) {
    .page-wrap {
      min-height: 100vh;
      z-index: 2; } }

.container {
  position: relative;
  max-width: 1160px;
  margin: 0 auto;
  padding: 0 20px;
  width: 100%; }
  @media (min-width: 1024px) {
    .container {
      padding-left: 40px;
      padding-right: 40px; } }

.container--small {
  max-width: 620px; }

.container--medium {
  max-width: 940px; }

.container--large {
  max-width: 1385px; }

.container--oh {
  overflow: hidden; }

.wf-loading {
  overflow: hidden; }
  .wf-loading body {
    visibility: hidden; }

::selection {
  background-color: #adaeca;
  color: inherit; }

/* =============================================================================
   HELPERS
   ========================================================================== */
.icon {
  display: inline-block;
  vertical-align: middle; }

.icon-hamburger,
.icon-hamburger:before,
.icon-hamburger:after {
  width: 20px;
  position: absolute;
  left: 0;
  height: 1px;
  border-top: 2px solid #b3001b;
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1); }

.icon-hamburger {
  position: relative; }
  .icon-hamburger:before, .icon-hamburger:after {
    content: "";
    margin-left: 0; }
  .icon-hamburger:before {
    margin-top: 3px; }
  .icon-hamburger:after {
    margin-top: 8px; }

.icon-flag {
  width: 9px;
  height: 9px; }

.icon-arrow-left {
  width: 40px;
  height: 33px; }

.icon-arrow-right {
  width: 31px;
  height: 38px; }

.icon-chevron-right:before, .icon-chevron-left:before {
  content: '>';
  font-family: 'CutOutJams2', Helvetica, Arial, Verdana, serif;
  font-size: 15px;
  font-size: 1.5rem;
  font-weight: 300;
  font-style: normal; }

.icon-chevron-left:before {
  content: '<'; }

.mb10.mb10 {
  margin-bottom: 10px; }

.mb50.mb50 {
  margin-bottom: 25px; }
  @media (min-width: 768px) {
    .mb50.mb50 {
      margin-bottom: 50px; } }

.mb100.mb100 {
  margin-bottom: 50px; }
  @media (min-width: 768px) {
    .mb100.mb100 {
      margin-bottom: 100px; } }

.ttu {
  text-transform: uppercase; }

.ttl {
  text-transform: lowercase; }

.no-wrap {
  white-space: normal; }

.rounded {
  border-radius: 20px; }

.gm-style img {
  max-width: inherit; }

.video-container {
  position: relative;
  padding: 0 0 56.25%;
  height: 0;
  overflow: hidden;
  margin: 0 0 15px; }
  .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.equal-h {
  display: flex;
  flex-wrap: wrap; }
  .equal-h > * {
    display: flex; }
  .equal-h:before, .equal-h:after {
    display: none; }

html.no-flexbox .equal-h {
  height: 100%; }
  html.no-flexbox .equal-h > div {
    float: left; }

.block-link, .product-hero__logo, .job {
  position: relative;
  cursor: pointer; }

.block-link__target:before, .product-hero__link:before, .job .button:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.global-color-white a,
.global-color-white .link,
.global-color-white .decorated,
.global-color-white .rte p strong,
.global-color-white .tag,
.global-color-white .product-category__title,
.global-color-white .product-item__title,
.global-color-white .main-nav a,
.global-color-white .language__trigger,
.global-color-white .language__wrap li a,
.global-color-white .hero__scroll-button,
.global-color-white .hero__link-button,
.global-color-white a:hover,
.global-color-white a:focus,
.global-color-white li.active a,
.global-color-white .main-nav a:hover,
.global-color-white .main-nav a:focus,
.global-color-white .main-nav li.active a,
.global-color-white h1,
.global-color-white .h1,
.global-color-white h2,
.global-color-white .h2,
.global-color-white h3,
.global-color-white .h3,
.global-color-white h4,
.global-color-white .h4,
.global-color-white h5,
.global-color-white .h5,
.global-color-white h6,
.global-color-white .h6,
.global-color-white .arrow-label,
.global-color-white .giveaway__title .subtitle,
.giveaway__title .global-color-white .subtitle,
.global-color-white input[type="text"],
.global-color-white input[type="search"],
.global-color-white input[type="password"],
.global-color-white input[type="email"],
.global-color-white input[type="tel"],
.global-color-white input[type="date"],
.global-color-white input[type="number"],
.global-color-white textarea,
.global-color-white select,
.global-color-white input[type="reset"],
.global-color-white input[type="submit"],
.global-color-white .button,
.global-color-white ::placeholder,
.global-color-white .product-order.product-order .contact-block.contact-block a,
.global-color-white .main-nav__trigger,
.global-color-white .brand-navigation span {
  color: #fff; }

.global-color-white .main-nav a:hover:not(.no-line):after,
.global-color-white .main-nav a:focus:not(.no-line):after,
.global-color-white .main-nav li.active a:not(.no-line):after {
  box-shadow: inset 0px 2px 0px 0px #fff; }

.global-color-white .product-sheet__button .button--download {
  background-color: #fff; }

.global-color-white .hero__scroll-button .icon,
.global-color-white .hero__link-button .icon,
.global-color-white .logo,
.global-color-white .social__link,
.global-color-white .arrow-label .icon,
.global-color-white .giveaway__title .subtitle .icon,
.giveaway__title .global-color-white .subtitle .icon,
.global-color-white .brand-navigation .icon {
  fill: #fff; }

.global-color-white .tag,
.global-color-white .language__wrap,
.global-color-white .language__wrap:before,
.global-color-white .language__wrap:after,
.global-color-white .language__arrow,
.global-color-white input[type="text"],
.global-color-white input[type="search"],
.global-color-white input[type="password"],
.global-color-white input[type="email"],
.global-color-white input[type="tel"],
.global-color-white input[type="date"],
.global-color-white input[type="number"],
.global-color-white textarea,
.global-color-white select,
.global-color-white input[type="reset"],
.global-color-white input[type="submit"],
.global-color-white .button,
.global-color-white .checkbox label:before,
.global-color-white .checkbox label:after,
.global-color-white .radio label:before,
.global-color-white .radio label:after,
.global-color-white .checkbox input[type="checkbox"]:checked + label:before,
.global-color-white .checkbox input[type="radio"]:checked + label:before,
.global-color-white .radio input[type="checkbox"]:checked + label:before,
.global-color-white .radio input[type="radio"]:checked + label:before,
.global-color-white .icon-hamburger,
.global-color-white .icon-hamburger:before,
.global-color-white .icon-hamburger:after {
  border-color: #fff; }

.global-color-white .product-category {
  border-color: rgba(255, 255, 255, 0.15); }

.global-color-white .product-header .decorated {
  color: rgba(255, 255, 255, 0.15); }

.global-color-white .language__wrap,
.global-color-white .language__arrow {
  background-color: transparent; }
  .menu-hidden .global-color-white .language__wrap, .menu-hidden
  .global-color-white .language__arrow {
    background-color: #fff !important; }

.global-color-white.menu-hidden .language__wrap,
.global-color-white.menu-hidden .language__arrow {
  background-color: #fff;
  border-color: #b3001b; }
  .global-color-white.menu-hidden .language__wrap:before, .global-color-white.menu-hidden .language__wrap:after,
  .global-color-white.menu-hidden .language__arrow:before,
  .global-color-white.menu-hidden .language__arrow:after {
    border-color: #b3001b; }
  .global-color-white.menu-hidden .language__wrap a,
  .global-color-white.menu-hidden .language__arrow a {
    color: #b3001b; }

.global-color-red a,
.global-color-red .link,
.global-color-red .decorated,
.global-color-red .rte p strong,
.global-color-red .tag,
.global-color-red .product-category__title,
.global-color-red .product-item__title,
.global-color-red .main-nav a,
.global-color-red .language__trigger,
.global-color-red .language__wrap li a,
.global-color-red .hero__scroll-button,
.global-color-red .hero__link-button,
.global-color-red a:hover,
.global-color-red a:focus,
.global-color-red li.active a,
.global-color-red .main-nav a:hover,
.global-color-red .main-nav a:focus,
.global-color-red .main-nav li.active a,
.global-color-red h1,
.global-color-red .h1,
.global-color-red h2,
.global-color-red .h2,
.global-color-red h3,
.global-color-red .h3,
.global-color-red h4,
.global-color-red .h4,
.global-color-red h5,
.global-color-red .h5,
.global-color-red h6,
.global-color-red .h6,
.global-color-red .arrow-label,
.global-color-red .giveaway__title .subtitle,
.giveaway__title .global-color-red .subtitle,
.global-color-red input[type="text"],
.global-color-red input[type="search"],
.global-color-red input[type="password"],
.global-color-red input[type="email"],
.global-color-red input[type="tel"],
.global-color-red input[type="date"],
.global-color-red input[type="number"],
.global-color-red textarea,
.global-color-red select,
.global-color-red input[type="reset"],
.global-color-red input[type="submit"],
.global-color-red .button,
.global-color-red ::placeholder,
.global-color-red .product-order.product-order .contact-block.contact-block a,
.global-color-red .main-nav__trigger,
.global-color-red .brand-navigation span {
  color: #FA3323; }

.global-color-red .main-nav a:hover:not(.no-line):after,
.global-color-red .main-nav a:focus:not(.no-line):after,
.global-color-red .main-nav li.active a:not(.no-line):after {
  box-shadow: inset 0px 2px 0px 0px #FA3323; }

.global-color-red .product-sheet__button .button--download {
  background-color: #FA3323; }

.global-color-red .hero__scroll-button .icon,
.global-color-red .hero__link-button .icon,
.global-color-red .logo,
.global-color-red .social__link,
.global-color-red .arrow-label .icon,
.global-color-red .giveaway__title .subtitle .icon,
.giveaway__title .global-color-red .subtitle .icon,
.global-color-red .brand-navigation .icon {
  fill: #FA3323; }

.global-color-red .tag,
.global-color-red .language__wrap,
.global-color-red .language__wrap:before,
.global-color-red .language__wrap:after,
.global-color-red .language__arrow,
.global-color-red input[type="text"],
.global-color-red input[type="search"],
.global-color-red input[type="password"],
.global-color-red input[type="email"],
.global-color-red input[type="tel"],
.global-color-red input[type="date"],
.global-color-red input[type="number"],
.global-color-red textarea,
.global-color-red select,
.global-color-red input[type="reset"],
.global-color-red input[type="submit"],
.global-color-red .button,
.global-color-red .checkbox label:before,
.global-color-red .checkbox label:after,
.global-color-red .radio label:before,
.global-color-red .radio label:after,
.global-color-red .checkbox input[type="checkbox"]:checked + label:before,
.global-color-red .checkbox input[type="radio"]:checked + label:before,
.global-color-red .radio input[type="checkbox"]:checked + label:before,
.global-color-red .radio input[type="radio"]:checked + label:before,
.global-color-red .icon-hamburger,
.global-color-red .icon-hamburger:before,
.global-color-red .icon-hamburger:after {
  border-color: #FA3323; }

.global-color-red .product-category {
  border-color: rgba(250, 51, 35, 0.15); }

.global-color-red .product-header .decorated {
  color: rgba(250, 51, 35, 0.15); }

.global-color-raspberry a,
.global-color-raspberry .link,
.global-color-raspberry .decorated,
.global-color-raspberry .rte p strong,
.global-color-raspberry .tag,
.global-color-raspberry .product-category__title,
.global-color-raspberry .product-item__title,
.global-color-raspberry .main-nav a,
.global-color-raspberry .language__trigger,
.global-color-raspberry .language__wrap li a,
.global-color-raspberry .hero__scroll-button,
.global-color-raspberry .hero__link-button,
.global-color-raspberry a:hover,
.global-color-raspberry a:focus,
.global-color-raspberry li.active a,
.global-color-raspberry .main-nav a:hover,
.global-color-raspberry .main-nav a:focus,
.global-color-raspberry .main-nav li.active a,
.global-color-raspberry h1,
.global-color-raspberry .h1,
.global-color-raspberry h2,
.global-color-raspberry .h2,
.global-color-raspberry h3,
.global-color-raspberry .h3,
.global-color-raspberry h4,
.global-color-raspberry .h4,
.global-color-raspberry h5,
.global-color-raspberry .h5,
.global-color-raspberry h6,
.global-color-raspberry .h6,
.global-color-raspberry .arrow-label,
.global-color-raspberry .giveaway__title .subtitle,
.giveaway__title .global-color-raspberry .subtitle,
.global-color-raspberry input[type="text"],
.global-color-raspberry input[type="search"],
.global-color-raspberry input[type="password"],
.global-color-raspberry input[type="email"],
.global-color-raspberry input[type="tel"],
.global-color-raspberry input[type="date"],
.global-color-raspberry input[type="number"],
.global-color-raspberry textarea,
.global-color-raspberry select,
.global-color-raspberry input[type="reset"],
.global-color-raspberry input[type="submit"],
.global-color-raspberry .button,
.global-color-raspberry ::placeholder,
.global-color-raspberry .product-order.product-order .contact-block.contact-block a,
.global-color-raspberry .main-nav__trigger,
.global-color-raspberry .brand-navigation span {
  color: #E00E52; }

.global-color-raspberry .main-nav a:hover:not(.no-line):after,
.global-color-raspberry .main-nav a:focus:not(.no-line):after,
.global-color-raspberry .main-nav li.active a:not(.no-line):after {
  box-shadow: inset 0px 2px 0px 0px #E00E52; }

.global-color-raspberry .product-sheet__button .button--download {
  background-color: #E00E52; }

.global-color-raspberry .hero__scroll-button .icon,
.global-color-raspberry .hero__link-button .icon,
.global-color-raspberry .logo,
.global-color-raspberry .social__link,
.global-color-raspberry .arrow-label .icon,
.global-color-raspberry .giveaway__title .subtitle .icon,
.giveaway__title .global-color-raspberry .subtitle .icon,
.global-color-raspberry .brand-navigation .icon {
  fill: #E00E52; }

.global-color-raspberry .tag,
.global-color-raspberry .language__wrap,
.global-color-raspberry .language__wrap:before,
.global-color-raspberry .language__wrap:after,
.global-color-raspberry .language__arrow,
.global-color-raspberry input[type="text"],
.global-color-raspberry input[type="search"],
.global-color-raspberry input[type="password"],
.global-color-raspberry input[type="email"],
.global-color-raspberry input[type="tel"],
.global-color-raspberry input[type="date"],
.global-color-raspberry input[type="number"],
.global-color-raspberry textarea,
.global-color-raspberry select,
.global-color-raspberry input[type="reset"],
.global-color-raspberry input[type="submit"],
.global-color-raspberry .button,
.global-color-raspberry .checkbox label:before,
.global-color-raspberry .checkbox label:after,
.global-color-raspberry .radio label:before,
.global-color-raspberry .radio label:after,
.global-color-raspberry .checkbox input[type="checkbox"]:checked + label:before,
.global-color-raspberry .checkbox input[type="radio"]:checked + label:before,
.global-color-raspberry .radio input[type="checkbox"]:checked + label:before,
.global-color-raspberry .radio input[type="radio"]:checked + label:before,
.global-color-raspberry .icon-hamburger,
.global-color-raspberry .icon-hamburger:before,
.global-color-raspberry .icon-hamburger:after {
  border-color: #E00E52; }

.global-color-raspberry .product-category {
  border-color: rgba(224, 14, 82, 0.15); }

.global-color-raspberry .product-header .decorated {
  color: rgba(224, 14, 82, 0.15); }

.global-color-orange a,
.global-color-orange .link,
.global-color-orange .decorated,
.global-color-orange .rte p strong,
.global-color-orange .tag,
.global-color-orange .product-category__title,
.global-color-orange .product-item__title,
.global-color-orange .main-nav a,
.global-color-orange .language__trigger,
.global-color-orange .language__wrap li a,
.global-color-orange .hero__scroll-button,
.global-color-orange .hero__link-button,
.global-color-orange a:hover,
.global-color-orange a:focus,
.global-color-orange li.active a,
.global-color-orange .main-nav a:hover,
.global-color-orange .main-nav a:focus,
.global-color-orange .main-nav li.active a,
.global-color-orange h1,
.global-color-orange .h1,
.global-color-orange h2,
.global-color-orange .h2,
.global-color-orange h3,
.global-color-orange .h3,
.global-color-orange h4,
.global-color-orange .h4,
.global-color-orange h5,
.global-color-orange .h5,
.global-color-orange h6,
.global-color-orange .h6,
.global-color-orange .arrow-label,
.global-color-orange .giveaway__title .subtitle,
.giveaway__title .global-color-orange .subtitle,
.global-color-orange input[type="text"],
.global-color-orange input[type="search"],
.global-color-orange input[type="password"],
.global-color-orange input[type="email"],
.global-color-orange input[type="tel"],
.global-color-orange input[type="date"],
.global-color-orange input[type="number"],
.global-color-orange textarea,
.global-color-orange select,
.global-color-orange input[type="reset"],
.global-color-orange input[type="submit"],
.global-color-orange .button,
.global-color-orange ::placeholder,
.global-color-orange .product-order.product-order .contact-block.contact-block a,
.global-color-orange .main-nav__trigger,
.global-color-orange .brand-navigation span {
  color: #F38533; }

.global-color-orange .main-nav a:hover:not(.no-line):after,
.global-color-orange .main-nav a:focus:not(.no-line):after,
.global-color-orange .main-nav li.active a:not(.no-line):after {
  box-shadow: inset 0px 2px 0px 0px #F38533; }

.global-color-orange .product-sheet__button .button--download {
  background-color: #F38533; }

.global-color-orange .hero__scroll-button .icon,
.global-color-orange .hero__link-button .icon,
.global-color-orange .logo,
.global-color-orange .social__link,
.global-color-orange .arrow-label .icon,
.global-color-orange .giveaway__title .subtitle .icon,
.giveaway__title .global-color-orange .subtitle .icon,
.global-color-orange .brand-navigation .icon {
  fill: #F38533; }

.global-color-orange .tag,
.global-color-orange .language__wrap,
.global-color-orange .language__wrap:before,
.global-color-orange .language__wrap:after,
.global-color-orange .language__arrow,
.global-color-orange input[type="text"],
.global-color-orange input[type="search"],
.global-color-orange input[type="password"],
.global-color-orange input[type="email"],
.global-color-orange input[type="tel"],
.global-color-orange input[type="date"],
.global-color-orange input[type="number"],
.global-color-orange textarea,
.global-color-orange select,
.global-color-orange input[type="reset"],
.global-color-orange input[type="submit"],
.global-color-orange .button,
.global-color-orange .checkbox label:before,
.global-color-orange .checkbox label:after,
.global-color-orange .radio label:before,
.global-color-orange .radio label:after,
.global-color-orange .checkbox input[type="checkbox"]:checked + label:before,
.global-color-orange .checkbox input[type="radio"]:checked + label:before,
.global-color-orange .radio input[type="checkbox"]:checked + label:before,
.global-color-orange .radio input[type="radio"]:checked + label:before,
.global-color-orange .icon-hamburger,
.global-color-orange .icon-hamburger:before,
.global-color-orange .icon-hamburger:after {
  border-color: #F38533; }

.global-color-orange .product-category {
  border-color: rgba(243, 133, 51, 0.15); }

.global-color-orange .product-header .decorated {
  color: rgba(243, 133, 51, 0.15); }

.global-color-lemon a,
.global-color-lemon .link,
.global-color-lemon .decorated,
.global-color-lemon .rte p strong,
.global-color-lemon .tag,
.global-color-lemon .product-category__title,
.global-color-lemon .product-item__title,
.global-color-lemon .main-nav a,
.global-color-lemon .language__trigger,
.global-color-lemon .language__wrap li a,
.global-color-lemon .hero__scroll-button,
.global-color-lemon .hero__link-button,
.global-color-lemon a:hover,
.global-color-lemon a:focus,
.global-color-lemon li.active a,
.global-color-lemon .main-nav a:hover,
.global-color-lemon .main-nav a:focus,
.global-color-lemon .main-nav li.active a,
.global-color-lemon h1,
.global-color-lemon .h1,
.global-color-lemon h2,
.global-color-lemon .h2,
.global-color-lemon h3,
.global-color-lemon .h3,
.global-color-lemon h4,
.global-color-lemon .h4,
.global-color-lemon h5,
.global-color-lemon .h5,
.global-color-lemon h6,
.global-color-lemon .h6,
.global-color-lemon .arrow-label,
.global-color-lemon .giveaway__title .subtitle,
.giveaway__title .global-color-lemon .subtitle,
.global-color-lemon input[type="text"],
.global-color-lemon input[type="search"],
.global-color-lemon input[type="password"],
.global-color-lemon input[type="email"],
.global-color-lemon input[type="tel"],
.global-color-lemon input[type="date"],
.global-color-lemon input[type="number"],
.global-color-lemon textarea,
.global-color-lemon select,
.global-color-lemon input[type="reset"],
.global-color-lemon input[type="submit"],
.global-color-lemon .button,
.global-color-lemon ::placeholder,
.global-color-lemon .product-order.product-order .contact-block.contact-block a,
.global-color-lemon .main-nav__trigger,
.global-color-lemon .brand-navigation span {
  color: #DEE137; }

.global-color-lemon .main-nav a:hover:not(.no-line):after,
.global-color-lemon .main-nav a:focus:not(.no-line):after,
.global-color-lemon .main-nav li.active a:not(.no-line):after {
  box-shadow: inset 0px 2px 0px 0px #DEE137; }

.global-color-lemon .product-sheet__button .button--download {
  background-color: #DEE137; }

.global-color-lemon .hero__scroll-button .icon,
.global-color-lemon .hero__link-button .icon,
.global-color-lemon .logo,
.global-color-lemon .social__link,
.global-color-lemon .arrow-label .icon,
.global-color-lemon .giveaway__title .subtitle .icon,
.giveaway__title .global-color-lemon .subtitle .icon,
.global-color-lemon .brand-navigation .icon {
  fill: #DEE137; }

.global-color-lemon .tag,
.global-color-lemon .language__wrap,
.global-color-lemon .language__wrap:before,
.global-color-lemon .language__wrap:after,
.global-color-lemon .language__arrow,
.global-color-lemon input[type="text"],
.global-color-lemon input[type="search"],
.global-color-lemon input[type="password"],
.global-color-lemon input[type="email"],
.global-color-lemon input[type="tel"],
.global-color-lemon input[type="date"],
.global-color-lemon input[type="number"],
.global-color-lemon textarea,
.global-color-lemon select,
.global-color-lemon input[type="reset"],
.global-color-lemon input[type="submit"],
.global-color-lemon .button,
.global-color-lemon .checkbox label:before,
.global-color-lemon .checkbox label:after,
.global-color-lemon .radio label:before,
.global-color-lemon .radio label:after,
.global-color-lemon .checkbox input[type="checkbox"]:checked + label:before,
.global-color-lemon .checkbox input[type="radio"]:checked + label:before,
.global-color-lemon .radio input[type="checkbox"]:checked + label:before,
.global-color-lemon .radio input[type="radio"]:checked + label:before,
.global-color-lemon .icon-hamburger,
.global-color-lemon .icon-hamburger:before,
.global-color-lemon .icon-hamburger:after {
  border-color: #DEE137; }

.global-color-lemon .product-category {
  border-color: rgba(222, 225, 55, 0.15); }

.global-color-lemon .product-header .decorated {
  color: rgba(222, 225, 55, 0.15); }

.global-color-yellow a,
.global-color-yellow .link,
.global-color-yellow .decorated,
.global-color-yellow .rte p strong,
.global-color-yellow .tag,
.global-color-yellow .product-category__title,
.global-color-yellow .product-item__title,
.global-color-yellow .main-nav a,
.global-color-yellow .language__trigger,
.global-color-yellow .language__wrap li a,
.global-color-yellow .hero__scroll-button,
.global-color-yellow .hero__link-button,
.global-color-yellow a:hover,
.global-color-yellow a:focus,
.global-color-yellow li.active a,
.global-color-yellow .main-nav a:hover,
.global-color-yellow .main-nav a:focus,
.global-color-yellow .main-nav li.active a,
.global-color-yellow h1,
.global-color-yellow .h1,
.global-color-yellow h2,
.global-color-yellow .h2,
.global-color-yellow h3,
.global-color-yellow .h3,
.global-color-yellow h4,
.global-color-yellow .h4,
.global-color-yellow h5,
.global-color-yellow .h5,
.global-color-yellow h6,
.global-color-yellow .h6,
.global-color-yellow .arrow-label,
.global-color-yellow .giveaway__title .subtitle,
.giveaway__title .global-color-yellow .subtitle,
.global-color-yellow input[type="text"],
.global-color-yellow input[type="search"],
.global-color-yellow input[type="password"],
.global-color-yellow input[type="email"],
.global-color-yellow input[type="tel"],
.global-color-yellow input[type="date"],
.global-color-yellow input[type="number"],
.global-color-yellow textarea,
.global-color-yellow select,
.global-color-yellow input[type="reset"],
.global-color-yellow input[type="submit"],
.global-color-yellow .button,
.global-color-yellow ::placeholder,
.global-color-yellow .product-order.product-order .contact-block.contact-block a,
.global-color-yellow .main-nav__trigger,
.global-color-yellow .brand-navigation span {
  color: #FCDE32; }

.global-color-yellow .main-nav a:hover:not(.no-line):after,
.global-color-yellow .main-nav a:focus:not(.no-line):after,
.global-color-yellow .main-nav li.active a:not(.no-line):after {
  box-shadow: inset 0px 2px 0px 0px #FCDE32; }

.global-color-yellow .product-sheet__button .button--download {
  background-color: #FCDE32; }

.global-color-yellow .hero__scroll-button .icon,
.global-color-yellow .hero__link-button .icon,
.global-color-yellow .logo,
.global-color-yellow .social__link,
.global-color-yellow .arrow-label .icon,
.global-color-yellow .giveaway__title .subtitle .icon,
.giveaway__title .global-color-yellow .subtitle .icon,
.global-color-yellow .brand-navigation .icon {
  fill: #FCDE32; }

.global-color-yellow .tag,
.global-color-yellow .language__wrap,
.global-color-yellow .language__wrap:before,
.global-color-yellow .language__wrap:after,
.global-color-yellow .language__arrow,
.global-color-yellow input[type="text"],
.global-color-yellow input[type="search"],
.global-color-yellow input[type="password"],
.global-color-yellow input[type="email"],
.global-color-yellow input[type="tel"],
.global-color-yellow input[type="date"],
.global-color-yellow input[type="number"],
.global-color-yellow textarea,
.global-color-yellow select,
.global-color-yellow input[type="reset"],
.global-color-yellow input[type="submit"],
.global-color-yellow .button,
.global-color-yellow .checkbox label:before,
.global-color-yellow .checkbox label:after,
.global-color-yellow .radio label:before,
.global-color-yellow .radio label:after,
.global-color-yellow .checkbox input[type="checkbox"]:checked + label:before,
.global-color-yellow .checkbox input[type="radio"]:checked + label:before,
.global-color-yellow .radio input[type="checkbox"]:checked + label:before,
.global-color-yellow .radio input[type="radio"]:checked + label:before,
.global-color-yellow .icon-hamburger,
.global-color-yellow .icon-hamburger:before,
.global-color-yellow .icon-hamburger:after {
  border-color: #FCDE32; }

.global-color-yellow .product-category {
  border-color: rgba(252, 222, 50, 0.15); }

.global-color-yellow .product-header .decorated {
  color: rgba(252, 222, 50, 0.15); }

.global-color-pink a,
.global-color-pink .link,
.global-color-pink .decorated,
.global-color-pink .rte p strong,
.global-color-pink .tag,
.global-color-pink .product-category__title,
.global-color-pink .product-item__title,
.global-color-pink .main-nav a,
.global-color-pink .language__trigger,
.global-color-pink .language__wrap li a,
.global-color-pink .hero__scroll-button,
.global-color-pink .hero__link-button,
.global-color-pink a:hover,
.global-color-pink a:focus,
.global-color-pink li.active a,
.global-color-pink .main-nav a:hover,
.global-color-pink .main-nav a:focus,
.global-color-pink .main-nav li.active a,
.global-color-pink h1,
.global-color-pink .h1,
.global-color-pink h2,
.global-color-pink .h2,
.global-color-pink h3,
.global-color-pink .h3,
.global-color-pink h4,
.global-color-pink .h4,
.global-color-pink h5,
.global-color-pink .h5,
.global-color-pink h6,
.global-color-pink .h6,
.global-color-pink .arrow-label,
.global-color-pink .giveaway__title .subtitle,
.giveaway__title .global-color-pink .subtitle,
.global-color-pink input[type="text"],
.global-color-pink input[type="search"],
.global-color-pink input[type="password"],
.global-color-pink input[type="email"],
.global-color-pink input[type="tel"],
.global-color-pink input[type="date"],
.global-color-pink input[type="number"],
.global-color-pink textarea,
.global-color-pink select,
.global-color-pink input[type="reset"],
.global-color-pink input[type="submit"],
.global-color-pink .button,
.global-color-pink ::placeholder,
.global-color-pink .product-order.product-order .contact-block.contact-block a,
.global-color-pink .main-nav__trigger,
.global-color-pink .brand-navigation span {
  color: #FF9F8E; }

.global-color-pink .main-nav a:hover:not(.no-line):after,
.global-color-pink .main-nav a:focus:not(.no-line):after,
.global-color-pink .main-nav li.active a:not(.no-line):after {
  box-shadow: inset 0px 2px 0px 0px #FF9F8E; }

.global-color-pink .product-sheet__button .button--download {
  background-color: #FF9F8E; }

.global-color-pink .hero__scroll-button .icon,
.global-color-pink .hero__link-button .icon,
.global-color-pink .logo,
.global-color-pink .social__link,
.global-color-pink .arrow-label .icon,
.global-color-pink .giveaway__title .subtitle .icon,
.giveaway__title .global-color-pink .subtitle .icon,
.global-color-pink .brand-navigation .icon {
  fill: #FF9F8E; }

.global-color-pink .tag,
.global-color-pink .language__wrap,
.global-color-pink .language__wrap:before,
.global-color-pink .language__wrap:after,
.global-color-pink .language__arrow,
.global-color-pink input[type="text"],
.global-color-pink input[type="search"],
.global-color-pink input[type="password"],
.global-color-pink input[type="email"],
.global-color-pink input[type="tel"],
.global-color-pink input[type="date"],
.global-color-pink input[type="number"],
.global-color-pink textarea,
.global-color-pink select,
.global-color-pink input[type="reset"],
.global-color-pink input[type="submit"],
.global-color-pink .button,
.global-color-pink .checkbox label:before,
.global-color-pink .checkbox label:after,
.global-color-pink .radio label:before,
.global-color-pink .radio label:after,
.global-color-pink .checkbox input[type="checkbox"]:checked + label:before,
.global-color-pink .checkbox input[type="radio"]:checked + label:before,
.global-color-pink .radio input[type="checkbox"]:checked + label:before,
.global-color-pink .radio input[type="radio"]:checked + label:before,
.global-color-pink .icon-hamburger,
.global-color-pink .icon-hamburger:before,
.global-color-pink .icon-hamburger:after {
  border-color: #FF9F8E; }

.global-color-pink .product-category {
  border-color: rgba(255, 159, 142, 0.15); }

.global-color-pink .product-header .decorated {
  color: rgba(255, 159, 142, 0.15); }

.global-color-eggplant a,
.global-color-eggplant .link,
.global-color-eggplant .decorated,
.global-color-eggplant .rte p strong,
.global-color-eggplant .tag,
.global-color-eggplant .product-category__title,
.global-color-eggplant .product-item__title,
.global-color-eggplant .main-nav a,
.global-color-eggplant .language__trigger,
.global-color-eggplant .language__wrap li a,
.global-color-eggplant .hero__scroll-button,
.global-color-eggplant .hero__link-button,
.global-color-eggplant a:hover,
.global-color-eggplant a:focus,
.global-color-eggplant li.active a,
.global-color-eggplant .main-nav a:hover,
.global-color-eggplant .main-nav a:focus,
.global-color-eggplant .main-nav li.active a,
.global-color-eggplant h1,
.global-color-eggplant .h1,
.global-color-eggplant h2,
.global-color-eggplant .h2,
.global-color-eggplant h3,
.global-color-eggplant .h3,
.global-color-eggplant h4,
.global-color-eggplant .h4,
.global-color-eggplant h5,
.global-color-eggplant .h5,
.global-color-eggplant h6,
.global-color-eggplant .h6,
.global-color-eggplant .arrow-label,
.global-color-eggplant .giveaway__title .subtitle,
.giveaway__title .global-color-eggplant .subtitle,
.global-color-eggplant input[type="text"],
.global-color-eggplant input[type="search"],
.global-color-eggplant input[type="password"],
.global-color-eggplant input[type="email"],
.global-color-eggplant input[type="tel"],
.global-color-eggplant input[type="date"],
.global-color-eggplant input[type="number"],
.global-color-eggplant textarea,
.global-color-eggplant select,
.global-color-eggplant input[type="reset"],
.global-color-eggplant input[type="submit"],
.global-color-eggplant .button,
.global-color-eggplant ::placeholder,
.global-color-eggplant .product-order.product-order .contact-block.contact-block a,
.global-color-eggplant .main-nav__trigger,
.global-color-eggplant .brand-navigation span {
  color: #361B4E; }

.global-color-eggplant .main-nav a:hover:not(.no-line):after,
.global-color-eggplant .main-nav a:focus:not(.no-line):after,
.global-color-eggplant .main-nav li.active a:not(.no-line):after {
  box-shadow: inset 0px 2px 0px 0px #361B4E; }

.global-color-eggplant .product-sheet__button .button--download {
  background-color: #361B4E; }

.global-color-eggplant .hero__scroll-button .icon,
.global-color-eggplant .hero__link-button .icon,
.global-color-eggplant .logo,
.global-color-eggplant .social__link,
.global-color-eggplant .arrow-label .icon,
.global-color-eggplant .giveaway__title .subtitle .icon,
.giveaway__title .global-color-eggplant .subtitle .icon,
.global-color-eggplant .brand-navigation .icon {
  fill: #361B4E; }

.global-color-eggplant .tag,
.global-color-eggplant .language__wrap,
.global-color-eggplant .language__wrap:before,
.global-color-eggplant .language__wrap:after,
.global-color-eggplant .language__arrow,
.global-color-eggplant input[type="text"],
.global-color-eggplant input[type="search"],
.global-color-eggplant input[type="password"],
.global-color-eggplant input[type="email"],
.global-color-eggplant input[type="tel"],
.global-color-eggplant input[type="date"],
.global-color-eggplant input[type="number"],
.global-color-eggplant textarea,
.global-color-eggplant select,
.global-color-eggplant input[type="reset"],
.global-color-eggplant input[type="submit"],
.global-color-eggplant .button,
.global-color-eggplant .checkbox label:before,
.global-color-eggplant .checkbox label:after,
.global-color-eggplant .radio label:before,
.global-color-eggplant .radio label:after,
.global-color-eggplant .checkbox input[type="checkbox"]:checked + label:before,
.global-color-eggplant .checkbox input[type="radio"]:checked + label:before,
.global-color-eggplant .radio input[type="checkbox"]:checked + label:before,
.global-color-eggplant .radio input[type="radio"]:checked + label:before,
.global-color-eggplant .icon-hamburger,
.global-color-eggplant .icon-hamburger:before,
.global-color-eggplant .icon-hamburger:after {
  border-color: #361B4E; }

.global-color-eggplant .product-category {
  border-color: rgba(54, 27, 78, 0.15); }

.global-color-eggplant .product-header .decorated {
  color: rgba(54, 27, 78, 0.15); }

.global-color-purple a,
.global-color-purple .link,
.global-color-purple .decorated,
.global-color-purple .rte p strong,
.global-color-purple .tag,
.global-color-purple .product-category__title,
.global-color-purple .product-item__title,
.global-color-purple .main-nav a,
.global-color-purple .language__trigger,
.global-color-purple .language__wrap li a,
.global-color-purple .hero__scroll-button,
.global-color-purple .hero__link-button,
.global-color-purple a:hover,
.global-color-purple a:focus,
.global-color-purple li.active a,
.global-color-purple .main-nav a:hover,
.global-color-purple .main-nav a:focus,
.global-color-purple .main-nav li.active a,
.global-color-purple h1,
.global-color-purple .h1,
.global-color-purple h2,
.global-color-purple .h2,
.global-color-purple h3,
.global-color-purple .h3,
.global-color-purple h4,
.global-color-purple .h4,
.global-color-purple h5,
.global-color-purple .h5,
.global-color-purple h6,
.global-color-purple .h6,
.global-color-purple .arrow-label,
.global-color-purple .giveaway__title .subtitle,
.giveaway__title .global-color-purple .subtitle,
.global-color-purple input[type="text"],
.global-color-purple input[type="search"],
.global-color-purple input[type="password"],
.global-color-purple input[type="email"],
.global-color-purple input[type="tel"],
.global-color-purple input[type="date"],
.global-color-purple input[type="number"],
.global-color-purple textarea,
.global-color-purple select,
.global-color-purple input[type="reset"],
.global-color-purple input[type="submit"],
.global-color-purple .button,
.global-color-purple ::placeholder,
.global-color-purple .product-order.product-order .contact-block.contact-block a,
.global-color-purple .main-nav__trigger,
.global-color-purple .brand-navigation span {
  color: #5E2AC2; }

.global-color-purple .main-nav a:hover:not(.no-line):after,
.global-color-purple .main-nav a:focus:not(.no-line):after,
.global-color-purple .main-nav li.active a:not(.no-line):after {
  box-shadow: inset 0px 2px 0px 0px #5E2AC2; }

.global-color-purple .product-sheet__button .button--download {
  background-color: #5E2AC2; }

.global-color-purple .hero__scroll-button .icon,
.global-color-purple .hero__link-button .icon,
.global-color-purple .logo,
.global-color-purple .social__link,
.global-color-purple .arrow-label .icon,
.global-color-purple .giveaway__title .subtitle .icon,
.giveaway__title .global-color-purple .subtitle .icon,
.global-color-purple .brand-navigation .icon {
  fill: #5E2AC2; }

.global-color-purple .tag,
.global-color-purple .language__wrap,
.global-color-purple .language__wrap:before,
.global-color-purple .language__wrap:after,
.global-color-purple .language__arrow,
.global-color-purple input[type="text"],
.global-color-purple input[type="search"],
.global-color-purple input[type="password"],
.global-color-purple input[type="email"],
.global-color-purple input[type="tel"],
.global-color-purple input[type="date"],
.global-color-purple input[type="number"],
.global-color-purple textarea,
.global-color-purple select,
.global-color-purple input[type="reset"],
.global-color-purple input[type="submit"],
.global-color-purple .button,
.global-color-purple .checkbox label:before,
.global-color-purple .checkbox label:after,
.global-color-purple .radio label:before,
.global-color-purple .radio label:after,
.global-color-purple .checkbox input[type="checkbox"]:checked + label:before,
.global-color-purple .checkbox input[type="radio"]:checked + label:before,
.global-color-purple .radio input[type="checkbox"]:checked + label:before,
.global-color-purple .radio input[type="radio"]:checked + label:before,
.global-color-purple .icon-hamburger,
.global-color-purple .icon-hamburger:before,
.global-color-purple .icon-hamburger:after {
  border-color: #5E2AC2; }

.global-color-purple .product-category {
  border-color: rgba(94, 42, 194, 0.15); }

.global-color-purple .product-header .decorated {
  color: rgba(94, 42, 194, 0.15); }

.global-color-blue a,
.global-color-blue .link,
.global-color-blue .decorated,
.global-color-blue .rte p strong,
.global-color-blue .tag,
.global-color-blue .product-category__title,
.global-color-blue .product-item__title,
.global-color-blue .main-nav a,
.global-color-blue .language__trigger,
.global-color-blue .language__wrap li a,
.global-color-blue .hero__scroll-button,
.global-color-blue .hero__link-button,
.global-color-blue a:hover,
.global-color-blue a:focus,
.global-color-blue li.active a,
.global-color-blue .main-nav a:hover,
.global-color-blue .main-nav a:focus,
.global-color-blue .main-nav li.active a,
.global-color-blue h1,
.global-color-blue .h1,
.global-color-blue h2,
.global-color-blue .h2,
.global-color-blue h3,
.global-color-blue .h3,
.global-color-blue h4,
.global-color-blue .h4,
.global-color-blue h5,
.global-color-blue .h5,
.global-color-blue h6,
.global-color-blue .h6,
.global-color-blue .arrow-label,
.global-color-blue .giveaway__title .subtitle,
.giveaway__title .global-color-blue .subtitle,
.global-color-blue input[type="text"],
.global-color-blue input[type="search"],
.global-color-blue input[type="password"],
.global-color-blue input[type="email"],
.global-color-blue input[type="tel"],
.global-color-blue input[type="date"],
.global-color-blue input[type="number"],
.global-color-blue textarea,
.global-color-blue select,
.global-color-blue input[type="reset"],
.global-color-blue input[type="submit"],
.global-color-blue .button,
.global-color-blue ::placeholder,
.global-color-blue .product-order.product-order .contact-block.contact-block a,
.global-color-blue .main-nav__trigger,
.global-color-blue .brand-navigation span {
  color: #0086FF; }

.global-color-blue .main-nav a:hover:not(.no-line):after,
.global-color-blue .main-nav a:focus:not(.no-line):after,
.global-color-blue .main-nav li.active a:not(.no-line):after {
  box-shadow: inset 0px 2px 0px 0px #0086FF; }

.global-color-blue .product-sheet__button .button--download {
  background-color: #0086FF; }

.global-color-blue .hero__scroll-button .icon,
.global-color-blue .hero__link-button .icon,
.global-color-blue .logo,
.global-color-blue .social__link,
.global-color-blue .arrow-label .icon,
.global-color-blue .giveaway__title .subtitle .icon,
.giveaway__title .global-color-blue .subtitle .icon,
.global-color-blue .brand-navigation .icon {
  fill: #0086FF; }

.global-color-blue .tag,
.global-color-blue .language__wrap,
.global-color-blue .language__wrap:before,
.global-color-blue .language__wrap:after,
.global-color-blue .language__arrow,
.global-color-blue input[type="text"],
.global-color-blue input[type="search"],
.global-color-blue input[type="password"],
.global-color-blue input[type="email"],
.global-color-blue input[type="tel"],
.global-color-blue input[type="date"],
.global-color-blue input[type="number"],
.global-color-blue textarea,
.global-color-blue select,
.global-color-blue input[type="reset"],
.global-color-blue input[type="submit"],
.global-color-blue .button,
.global-color-blue .checkbox label:before,
.global-color-blue .checkbox label:after,
.global-color-blue .radio label:before,
.global-color-blue .radio label:after,
.global-color-blue .checkbox input[type="checkbox"]:checked + label:before,
.global-color-blue .checkbox input[type="radio"]:checked + label:before,
.global-color-blue .radio input[type="checkbox"]:checked + label:before,
.global-color-blue .radio input[type="radio"]:checked + label:before,
.global-color-blue .icon-hamburger,
.global-color-blue .icon-hamburger:before,
.global-color-blue .icon-hamburger:after {
  border-color: #0086FF; }

.global-color-blue .product-category {
  border-color: rgba(0, 134, 255, 0.15); }

.global-color-blue .product-header .decorated {
  color: rgba(0, 134, 255, 0.15); }

.global-color-sky a,
.global-color-sky .link,
.global-color-sky .decorated,
.global-color-sky .rte p strong,
.global-color-sky .tag,
.global-color-sky .product-category__title,
.global-color-sky .product-item__title,
.global-color-sky .main-nav a,
.global-color-sky .language__trigger,
.global-color-sky .language__wrap li a,
.global-color-sky .hero__scroll-button,
.global-color-sky .hero__link-button,
.global-color-sky a:hover,
.global-color-sky a:focus,
.global-color-sky li.active a,
.global-color-sky .main-nav a:hover,
.global-color-sky .main-nav a:focus,
.global-color-sky .main-nav li.active a,
.global-color-sky h1,
.global-color-sky .h1,
.global-color-sky h2,
.global-color-sky .h2,
.global-color-sky h3,
.global-color-sky .h3,
.global-color-sky h4,
.global-color-sky .h4,
.global-color-sky h5,
.global-color-sky .h5,
.global-color-sky h6,
.global-color-sky .h6,
.global-color-sky .arrow-label,
.global-color-sky .giveaway__title .subtitle,
.giveaway__title .global-color-sky .subtitle,
.global-color-sky input[type="text"],
.global-color-sky input[type="search"],
.global-color-sky input[type="password"],
.global-color-sky input[type="email"],
.global-color-sky input[type="tel"],
.global-color-sky input[type="date"],
.global-color-sky input[type="number"],
.global-color-sky textarea,
.global-color-sky select,
.global-color-sky input[type="reset"],
.global-color-sky input[type="submit"],
.global-color-sky .button,
.global-color-sky ::placeholder,
.global-color-sky .product-order.product-order .contact-block.contact-block a,
.global-color-sky .main-nav__trigger,
.global-color-sky .brand-navigation span {
  color: #9FD5E1; }

.global-color-sky .main-nav a:hover:not(.no-line):after,
.global-color-sky .main-nav a:focus:not(.no-line):after,
.global-color-sky .main-nav li.active a:not(.no-line):after {
  box-shadow: inset 0px 2px 0px 0px #9FD5E1; }

.global-color-sky .product-sheet__button .button--download {
  background-color: #9FD5E1; }

.global-color-sky .hero__scroll-button .icon,
.global-color-sky .hero__link-button .icon,
.global-color-sky .logo,
.global-color-sky .social__link,
.global-color-sky .arrow-label .icon,
.global-color-sky .giveaway__title .subtitle .icon,
.giveaway__title .global-color-sky .subtitle .icon,
.global-color-sky .brand-navigation .icon {
  fill: #9FD5E1; }

.global-color-sky .tag,
.global-color-sky .language__wrap,
.global-color-sky .language__wrap:before,
.global-color-sky .language__wrap:after,
.global-color-sky .language__arrow,
.global-color-sky input[type="text"],
.global-color-sky input[type="search"],
.global-color-sky input[type="password"],
.global-color-sky input[type="email"],
.global-color-sky input[type="tel"],
.global-color-sky input[type="date"],
.global-color-sky input[type="number"],
.global-color-sky textarea,
.global-color-sky select,
.global-color-sky input[type="reset"],
.global-color-sky input[type="submit"],
.global-color-sky .button,
.global-color-sky .checkbox label:before,
.global-color-sky .checkbox label:after,
.global-color-sky .radio label:before,
.global-color-sky .radio label:after,
.global-color-sky .checkbox input[type="checkbox"]:checked + label:before,
.global-color-sky .checkbox input[type="radio"]:checked + label:before,
.global-color-sky .radio input[type="checkbox"]:checked + label:before,
.global-color-sky .radio input[type="radio"]:checked + label:before,
.global-color-sky .icon-hamburger,
.global-color-sky .icon-hamburger:before,
.global-color-sky .icon-hamburger:after {
  border-color: #9FD5E1; }

.global-color-sky .product-category {
  border-color: rgba(159, 213, 225, 0.15); }

.global-color-sky .product-header .decorated {
  color: rgba(159, 213, 225, 0.15); }

.global-color-mint a,
.global-color-mint .link,
.global-color-mint .decorated,
.global-color-mint .rte p strong,
.global-color-mint .tag,
.global-color-mint .product-category__title,
.global-color-mint .product-item__title,
.global-color-mint .main-nav a,
.global-color-mint .language__trigger,
.global-color-mint .language__wrap li a,
.global-color-mint .hero__scroll-button,
.global-color-mint .hero__link-button,
.global-color-mint a:hover,
.global-color-mint a:focus,
.global-color-mint li.active a,
.global-color-mint .main-nav a:hover,
.global-color-mint .main-nav a:focus,
.global-color-mint .main-nav li.active a,
.global-color-mint h1,
.global-color-mint .h1,
.global-color-mint h2,
.global-color-mint .h2,
.global-color-mint h3,
.global-color-mint .h3,
.global-color-mint h4,
.global-color-mint .h4,
.global-color-mint h5,
.global-color-mint .h5,
.global-color-mint h6,
.global-color-mint .h6,
.global-color-mint .arrow-label,
.global-color-mint .giveaway__title .subtitle,
.giveaway__title .global-color-mint .subtitle,
.global-color-mint input[type="text"],
.global-color-mint input[type="search"],
.global-color-mint input[type="password"],
.global-color-mint input[type="email"],
.global-color-mint input[type="tel"],
.global-color-mint input[type="date"],
.global-color-mint input[type="number"],
.global-color-mint textarea,
.global-color-mint select,
.global-color-mint input[type="reset"],
.global-color-mint input[type="submit"],
.global-color-mint .button,
.global-color-mint ::placeholder,
.global-color-mint .product-order.product-order .contact-block.contact-block a,
.global-color-mint .main-nav__trigger,
.global-color-mint .brand-navigation span {
  color: #80F5C7; }

.global-color-mint .main-nav a:hover:not(.no-line):after,
.global-color-mint .main-nav a:focus:not(.no-line):after,
.global-color-mint .main-nav li.active a:not(.no-line):after {
  box-shadow: inset 0px 2px 0px 0px #80F5C7; }

.global-color-mint .product-sheet__button .button--download {
  background-color: #80F5C7; }

.global-color-mint .hero__scroll-button .icon,
.global-color-mint .hero__link-button .icon,
.global-color-mint .logo,
.global-color-mint .social__link,
.global-color-mint .arrow-label .icon,
.global-color-mint .giveaway__title .subtitle .icon,
.giveaway__title .global-color-mint .subtitle .icon,
.global-color-mint .brand-navigation .icon {
  fill: #80F5C7; }

.global-color-mint .tag,
.global-color-mint .language__wrap,
.global-color-mint .language__wrap:before,
.global-color-mint .language__wrap:after,
.global-color-mint .language__arrow,
.global-color-mint input[type="text"],
.global-color-mint input[type="search"],
.global-color-mint input[type="password"],
.global-color-mint input[type="email"],
.global-color-mint input[type="tel"],
.global-color-mint input[type="date"],
.global-color-mint input[type="number"],
.global-color-mint textarea,
.global-color-mint select,
.global-color-mint input[type="reset"],
.global-color-mint input[type="submit"],
.global-color-mint .button,
.global-color-mint .checkbox label:before,
.global-color-mint .checkbox label:after,
.global-color-mint .radio label:before,
.global-color-mint .radio label:after,
.global-color-mint .checkbox input[type="checkbox"]:checked + label:before,
.global-color-mint .checkbox input[type="radio"]:checked + label:before,
.global-color-mint .radio input[type="checkbox"]:checked + label:before,
.global-color-mint .radio input[type="radio"]:checked + label:before,
.global-color-mint .icon-hamburger,
.global-color-mint .icon-hamburger:before,
.global-color-mint .icon-hamburger:after {
  border-color: #80F5C7; }

.global-color-mint .product-category {
  border-color: rgba(128, 245, 199, 0.15); }

.global-color-mint .product-header .decorated {
  color: rgba(128, 245, 199, 0.15); }

.global-color-jade a,
.global-color-jade .link,
.global-color-jade .decorated,
.global-color-jade .rte p strong,
.global-color-jade .tag,
.global-color-jade .product-category__title,
.global-color-jade .product-item__title,
.global-color-jade .main-nav a,
.global-color-jade .language__trigger,
.global-color-jade .language__wrap li a,
.global-color-jade .hero__scroll-button,
.global-color-jade .hero__link-button,
.global-color-jade a:hover,
.global-color-jade a:focus,
.global-color-jade li.active a,
.global-color-jade .main-nav a:hover,
.global-color-jade .main-nav a:focus,
.global-color-jade .main-nav li.active a,
.global-color-jade h1,
.global-color-jade .h1,
.global-color-jade h2,
.global-color-jade .h2,
.global-color-jade h3,
.global-color-jade .h3,
.global-color-jade h4,
.global-color-jade .h4,
.global-color-jade h5,
.global-color-jade .h5,
.global-color-jade h6,
.global-color-jade .h6,
.global-color-jade .arrow-label,
.global-color-jade .giveaway__title .subtitle,
.giveaway__title .global-color-jade .subtitle,
.global-color-jade input[type="text"],
.global-color-jade input[type="search"],
.global-color-jade input[type="password"],
.global-color-jade input[type="email"],
.global-color-jade input[type="tel"],
.global-color-jade input[type="date"],
.global-color-jade input[type="number"],
.global-color-jade textarea,
.global-color-jade select,
.global-color-jade input[type="reset"],
.global-color-jade input[type="submit"],
.global-color-jade .button,
.global-color-jade ::placeholder,
.global-color-jade .product-order.product-order .contact-block.contact-block a,
.global-color-jade .main-nav__trigger,
.global-color-jade .brand-navigation span {
  color: #28EDCC; }

.global-color-jade .main-nav a:hover:not(.no-line):after,
.global-color-jade .main-nav a:focus:not(.no-line):after,
.global-color-jade .main-nav li.active a:not(.no-line):after {
  box-shadow: inset 0px 2px 0px 0px #28EDCC; }

.global-color-jade .product-sheet__button .button--download {
  background-color: #28EDCC; }

.global-color-jade .hero__scroll-button .icon,
.global-color-jade .hero__link-button .icon,
.global-color-jade .logo,
.global-color-jade .social__link,
.global-color-jade .arrow-label .icon,
.global-color-jade .giveaway__title .subtitle .icon,
.giveaway__title .global-color-jade .subtitle .icon,
.global-color-jade .brand-navigation .icon {
  fill: #28EDCC; }

.global-color-jade .tag,
.global-color-jade .language__wrap,
.global-color-jade .language__wrap:before,
.global-color-jade .language__wrap:after,
.global-color-jade .language__arrow,
.global-color-jade input[type="text"],
.global-color-jade input[type="search"],
.global-color-jade input[type="password"],
.global-color-jade input[type="email"],
.global-color-jade input[type="tel"],
.global-color-jade input[type="date"],
.global-color-jade input[type="number"],
.global-color-jade textarea,
.global-color-jade select,
.global-color-jade input[type="reset"],
.global-color-jade input[type="submit"],
.global-color-jade .button,
.global-color-jade .checkbox label:before,
.global-color-jade .checkbox label:after,
.global-color-jade .radio label:before,
.global-color-jade .radio label:after,
.global-color-jade .checkbox input[type="checkbox"]:checked + label:before,
.global-color-jade .checkbox input[type="radio"]:checked + label:before,
.global-color-jade .radio input[type="checkbox"]:checked + label:before,
.global-color-jade .radio input[type="radio"]:checked + label:before,
.global-color-jade .icon-hamburger,
.global-color-jade .icon-hamburger:before,
.global-color-jade .icon-hamburger:after {
  border-color: #28EDCC; }

.global-color-jade .product-category {
  border-color: rgba(40, 237, 204, 0.15); }

.global-color-jade .product-header .decorated {
  color: rgba(40, 237, 204, 0.15); }

.global-color-lime a,
.global-color-lime .link,
.global-color-lime .decorated,
.global-color-lime .rte p strong,
.global-color-lime .tag,
.global-color-lime .product-category__title,
.global-color-lime .product-item__title,
.global-color-lime .main-nav a,
.global-color-lime .language__trigger,
.global-color-lime .language__wrap li a,
.global-color-lime .hero__scroll-button,
.global-color-lime .hero__link-button,
.global-color-lime a:hover,
.global-color-lime a:focus,
.global-color-lime li.active a,
.global-color-lime .main-nav a:hover,
.global-color-lime .main-nav a:focus,
.global-color-lime .main-nav li.active a,
.global-color-lime h1,
.global-color-lime .h1,
.global-color-lime h2,
.global-color-lime .h2,
.global-color-lime h3,
.global-color-lime .h3,
.global-color-lime h4,
.global-color-lime .h4,
.global-color-lime h5,
.global-color-lime .h5,
.global-color-lime h6,
.global-color-lime .h6,
.global-color-lime .arrow-label,
.global-color-lime .giveaway__title .subtitle,
.giveaway__title .global-color-lime .subtitle,
.global-color-lime input[type="text"],
.global-color-lime input[type="search"],
.global-color-lime input[type="password"],
.global-color-lime input[type="email"],
.global-color-lime input[type="tel"],
.global-color-lime input[type="date"],
.global-color-lime input[type="number"],
.global-color-lime textarea,
.global-color-lime select,
.global-color-lime input[type="reset"],
.global-color-lime input[type="submit"],
.global-color-lime .button,
.global-color-lime ::placeholder,
.global-color-lime .product-order.product-order .contact-block.contact-block a,
.global-color-lime .main-nav__trigger,
.global-color-lime .brand-navigation span {
  color: #6AD527; }

.global-color-lime .main-nav a:hover:not(.no-line):after,
.global-color-lime .main-nav a:focus:not(.no-line):after,
.global-color-lime .main-nav li.active a:not(.no-line):after {
  box-shadow: inset 0px 2px 0px 0px #6AD527; }

.global-color-lime .product-sheet__button .button--download {
  background-color: #6AD527; }

.global-color-lime .hero__scroll-button .icon,
.global-color-lime .hero__link-button .icon,
.global-color-lime .logo,
.global-color-lime .social__link,
.global-color-lime .arrow-label .icon,
.global-color-lime .giveaway__title .subtitle .icon,
.giveaway__title .global-color-lime .subtitle .icon,
.global-color-lime .brand-navigation .icon {
  fill: #6AD527; }

.global-color-lime .tag,
.global-color-lime .language__wrap,
.global-color-lime .language__wrap:before,
.global-color-lime .language__wrap:after,
.global-color-lime .language__arrow,
.global-color-lime input[type="text"],
.global-color-lime input[type="search"],
.global-color-lime input[type="password"],
.global-color-lime input[type="email"],
.global-color-lime input[type="tel"],
.global-color-lime input[type="date"],
.global-color-lime input[type="number"],
.global-color-lime textarea,
.global-color-lime select,
.global-color-lime input[type="reset"],
.global-color-lime input[type="submit"],
.global-color-lime .button,
.global-color-lime .checkbox label:before,
.global-color-lime .checkbox label:after,
.global-color-lime .radio label:before,
.global-color-lime .radio label:after,
.global-color-lime .checkbox input[type="checkbox"]:checked + label:before,
.global-color-lime .checkbox input[type="radio"]:checked + label:before,
.global-color-lime .radio input[type="checkbox"]:checked + label:before,
.global-color-lime .radio input[type="radio"]:checked + label:before,
.global-color-lime .icon-hamburger,
.global-color-lime .icon-hamburger:before,
.global-color-lime .icon-hamburger:after {
  border-color: #6AD527; }

.global-color-lime .product-category {
  border-color: rgba(106, 213, 39, 0.15); }

.global-color-lime .product-header .decorated {
  color: rgba(106, 213, 39, 0.15); }

.global-color-green a,
.global-color-green .link,
.global-color-green .decorated,
.global-color-green .rte p strong,
.global-color-green .tag,
.global-color-green .product-category__title,
.global-color-green .product-item__title,
.global-color-green .main-nav a,
.global-color-green .language__trigger,
.global-color-green .language__wrap li a,
.global-color-green .hero__scroll-button,
.global-color-green .hero__link-button,
.global-color-green a:hover,
.global-color-green a:focus,
.global-color-green li.active a,
.global-color-green .main-nav a:hover,
.global-color-green .main-nav a:focus,
.global-color-green .main-nav li.active a,
.global-color-green h1,
.global-color-green .h1,
.global-color-green h2,
.global-color-green .h2,
.global-color-green h3,
.global-color-green .h3,
.global-color-green h4,
.global-color-green .h4,
.global-color-green h5,
.global-color-green .h5,
.global-color-green h6,
.global-color-green .h6,
.global-color-green .arrow-label,
.global-color-green .giveaway__title .subtitle,
.giveaway__title .global-color-green .subtitle,
.global-color-green input[type="text"],
.global-color-green input[type="search"],
.global-color-green input[type="password"],
.global-color-green input[type="email"],
.global-color-green input[type="tel"],
.global-color-green input[type="date"],
.global-color-green input[type="number"],
.global-color-green textarea,
.global-color-green select,
.global-color-green input[type="reset"],
.global-color-green input[type="submit"],
.global-color-green .button,
.global-color-green ::placeholder,
.global-color-green .product-order.product-order .contact-block.contact-block a,
.global-color-green .main-nav__trigger,
.global-color-green .brand-navigation span {
  color: #1EB430; }

.global-color-green .main-nav a:hover:not(.no-line):after,
.global-color-green .main-nav a:focus:not(.no-line):after,
.global-color-green .main-nav li.active a:not(.no-line):after {
  box-shadow: inset 0px 2px 0px 0px #1EB430; }

.global-color-green .product-sheet__button .button--download {
  background-color: #1EB430; }

.global-color-green .hero__scroll-button .icon,
.global-color-green .hero__link-button .icon,
.global-color-green .logo,
.global-color-green .social__link,
.global-color-green .arrow-label .icon,
.global-color-green .giveaway__title .subtitle .icon,
.giveaway__title .global-color-green .subtitle .icon,
.global-color-green .brand-navigation .icon {
  fill: #1EB430; }

.global-color-green .tag,
.global-color-green .language__wrap,
.global-color-green .language__wrap:before,
.global-color-green .language__wrap:after,
.global-color-green .language__arrow,
.global-color-green input[type="text"],
.global-color-green input[type="search"],
.global-color-green input[type="password"],
.global-color-green input[type="email"],
.global-color-green input[type="tel"],
.global-color-green input[type="date"],
.global-color-green input[type="number"],
.global-color-green textarea,
.global-color-green select,
.global-color-green input[type="reset"],
.global-color-green input[type="submit"],
.global-color-green .button,
.global-color-green .checkbox label:before,
.global-color-green .checkbox label:after,
.global-color-green .radio label:before,
.global-color-green .radio label:after,
.global-color-green .checkbox input[type="checkbox"]:checked + label:before,
.global-color-green .checkbox input[type="radio"]:checked + label:before,
.global-color-green .radio input[type="checkbox"]:checked + label:before,
.global-color-green .radio input[type="radio"]:checked + label:before,
.global-color-green .icon-hamburger,
.global-color-green .icon-hamburger:before,
.global-color-green .icon-hamburger:after {
  border-color: #1EB430; }

.global-color-green .product-category {
  border-color: rgba(30, 180, 48, 0.15); }

.global-color-green .product-header .decorated {
  color: rgba(30, 180, 48, 0.15); }

.global-color-forest a,
.global-color-forest .link,
.global-color-forest .decorated,
.global-color-forest .rte p strong,
.global-color-forest .tag,
.global-color-forest .product-category__title,
.global-color-forest .product-item__title,
.global-color-forest .main-nav a,
.global-color-forest .language__trigger,
.global-color-forest .language__wrap li a,
.global-color-forest .hero__scroll-button,
.global-color-forest .hero__link-button,
.global-color-forest a:hover,
.global-color-forest a:focus,
.global-color-forest li.active a,
.global-color-forest .main-nav a:hover,
.global-color-forest .main-nav a:focus,
.global-color-forest .main-nav li.active a,
.global-color-forest h1,
.global-color-forest .h1,
.global-color-forest h2,
.global-color-forest .h2,
.global-color-forest h3,
.global-color-forest .h3,
.global-color-forest h4,
.global-color-forest .h4,
.global-color-forest h5,
.global-color-forest .h5,
.global-color-forest h6,
.global-color-forest .h6,
.global-color-forest .arrow-label,
.global-color-forest .giveaway__title .subtitle,
.giveaway__title .global-color-forest .subtitle,
.global-color-forest input[type="text"],
.global-color-forest input[type="search"],
.global-color-forest input[type="password"],
.global-color-forest input[type="email"],
.global-color-forest input[type="tel"],
.global-color-forest input[type="date"],
.global-color-forest input[type="number"],
.global-color-forest textarea,
.global-color-forest select,
.global-color-forest input[type="reset"],
.global-color-forest input[type="submit"],
.global-color-forest .button,
.global-color-forest ::placeholder,
.global-color-forest .product-order.product-order .contact-block.contact-block a,
.global-color-forest .main-nav__trigger,
.global-color-forest .brand-navigation span {
  color: #0C5842; }

.global-color-forest .main-nav a:hover:not(.no-line):after,
.global-color-forest .main-nav a:focus:not(.no-line):after,
.global-color-forest .main-nav li.active a:not(.no-line):after {
  box-shadow: inset 0px 2px 0px 0px #0C5842; }

.global-color-forest .product-sheet__button .button--download {
  background-color: #0C5842; }

.global-color-forest .hero__scroll-button .icon,
.global-color-forest .hero__link-button .icon,
.global-color-forest .logo,
.global-color-forest .social__link,
.global-color-forest .arrow-label .icon,
.global-color-forest .giveaway__title .subtitle .icon,
.giveaway__title .global-color-forest .subtitle .icon,
.global-color-forest .brand-navigation .icon {
  fill: #0C5842; }

.global-color-forest .tag,
.global-color-forest .language__wrap,
.global-color-forest .language__wrap:before,
.global-color-forest .language__wrap:after,
.global-color-forest .language__arrow,
.global-color-forest input[type="text"],
.global-color-forest input[type="search"],
.global-color-forest input[type="password"],
.global-color-forest input[type="email"],
.global-color-forest input[type="tel"],
.global-color-forest input[type="date"],
.global-color-forest input[type="number"],
.global-color-forest textarea,
.global-color-forest select,
.global-color-forest input[type="reset"],
.global-color-forest input[type="submit"],
.global-color-forest .button,
.global-color-forest .checkbox label:before,
.global-color-forest .checkbox label:after,
.global-color-forest .radio label:before,
.global-color-forest .radio label:after,
.global-color-forest .checkbox input[type="checkbox"]:checked + label:before,
.global-color-forest .checkbox input[type="radio"]:checked + label:before,
.global-color-forest .radio input[type="checkbox"]:checked + label:before,
.global-color-forest .radio input[type="radio"]:checked + label:before,
.global-color-forest .icon-hamburger,
.global-color-forest .icon-hamburger:before,
.global-color-forest .icon-hamburger:after {
  border-color: #0C5842; }

.global-color-forest .product-category {
  border-color: rgba(12, 88, 66, 0.15); }

.global-color-forest .product-header .decorated {
  color: rgba(12, 88, 66, 0.15); }

.global-color-brown a,
.global-color-brown .link,
.global-color-brown .decorated,
.global-color-brown .rte p strong,
.global-color-brown .tag,
.global-color-brown .product-category__title,
.global-color-brown .product-item__title,
.global-color-brown .main-nav a,
.global-color-brown .language__trigger,
.global-color-brown .language__wrap li a,
.global-color-brown .hero__scroll-button,
.global-color-brown .hero__link-button,
.global-color-brown a:hover,
.global-color-brown a:focus,
.global-color-brown li.active a,
.global-color-brown .main-nav a:hover,
.global-color-brown .main-nav a:focus,
.global-color-brown .main-nav li.active a,
.global-color-brown h1,
.global-color-brown .h1,
.global-color-brown h2,
.global-color-brown .h2,
.global-color-brown h3,
.global-color-brown .h3,
.global-color-brown h4,
.global-color-brown .h4,
.global-color-brown h5,
.global-color-brown .h5,
.global-color-brown h6,
.global-color-brown .h6,
.global-color-brown .arrow-label,
.global-color-brown .giveaway__title .subtitle,
.giveaway__title .global-color-brown .subtitle,
.global-color-brown input[type="text"],
.global-color-brown input[type="search"],
.global-color-brown input[type="password"],
.global-color-brown input[type="email"],
.global-color-brown input[type="tel"],
.global-color-brown input[type="date"],
.global-color-brown input[type="number"],
.global-color-brown textarea,
.global-color-brown select,
.global-color-brown input[type="reset"],
.global-color-brown input[type="submit"],
.global-color-brown .button,
.global-color-brown ::placeholder,
.global-color-brown .product-order.product-order .contact-block.contact-block a,
.global-color-brown .main-nav__trigger,
.global-color-brown .brand-navigation span {
  color: #5B3A17; }

.global-color-brown .main-nav a:hover:not(.no-line):after,
.global-color-brown .main-nav a:focus:not(.no-line):after,
.global-color-brown .main-nav li.active a:not(.no-line):after {
  box-shadow: inset 0px 2px 0px 0px #5B3A17; }

.global-color-brown .product-sheet__button .button--download {
  background-color: #5B3A17; }

.global-color-brown .hero__scroll-button .icon,
.global-color-brown .hero__link-button .icon,
.global-color-brown .logo,
.global-color-brown .social__link,
.global-color-brown .arrow-label .icon,
.global-color-brown .giveaway__title .subtitle .icon,
.giveaway__title .global-color-brown .subtitle .icon,
.global-color-brown .brand-navigation .icon {
  fill: #5B3A17; }

.global-color-brown .tag,
.global-color-brown .language__wrap,
.global-color-brown .language__wrap:before,
.global-color-brown .language__wrap:after,
.global-color-brown .language__arrow,
.global-color-brown input[type="text"],
.global-color-brown input[type="search"],
.global-color-brown input[type="password"],
.global-color-brown input[type="email"],
.global-color-brown input[type="tel"],
.global-color-brown input[type="date"],
.global-color-brown input[type="number"],
.global-color-brown textarea,
.global-color-brown select,
.global-color-brown input[type="reset"],
.global-color-brown input[type="submit"],
.global-color-brown .button,
.global-color-brown .checkbox label:before,
.global-color-brown .checkbox label:after,
.global-color-brown .radio label:before,
.global-color-brown .radio label:after,
.global-color-brown .checkbox input[type="checkbox"]:checked + label:before,
.global-color-brown .checkbox input[type="radio"]:checked + label:before,
.global-color-brown .radio input[type="checkbox"]:checked + label:before,
.global-color-brown .radio input[type="radio"]:checked + label:before,
.global-color-brown .icon-hamburger,
.global-color-brown .icon-hamburger:before,
.global-color-brown .icon-hamburger:after {
  border-color: #5B3A17; }

.global-color-brown .product-category {
  border-color: rgba(91, 58, 23, 0.15); }

.global-color-brown .product-header .decorated {
  color: rgba(91, 58, 23, 0.15); }

.color-red,
.color-red a {
  color: #FA3323; }

.color-raspberry,
.color-raspberry a {
  color: #E00E52; }

.color-orange,
.color-orange a {
  color: #F38533; }

.color-lemon,
.color-lemon a {
  color: #DEE137; }

.color-yellow,
.color-yellow a {
  color: #FCDE32; }

.color-pink,
.color-pink a {
  color: #FF9F8E; }

.color-eggplant,
.color-eggplant a {
  color: #361B4E; }

.color-purple,
.color-purple a {
  color: #5E2AC2; }

.color-blue,
.color-blue a {
  color: #0086FF; }

.color-sky,
.color-sky a {
  color: #9FD5E1; }

.color-mint,
.color-mint a {
  color: #80F5C7; }

.color-jade,
.color-jade a {
  color: #28EDCC; }

.color-lime,
.color-lime a {
  color: #6AD527; }

.color-green,
.color-green a {
  color: #1EB430; }

.color-forest,
.color-forest a {
  color: #0C5842; }

.color-brown,
.color-brown a {
  color: #5B3A17; }

.color-white,
.color-white a {
  color: #fff; }

/* =============================================================================
   HEADER
   ========================================================================== */
.page-header {
  *zoom: 1;
  height: 80px;
  position: fixed;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  z-index: 999;
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1);
  display: block; }
  .page-header:before, .page-header:after {
    display: table;
    content: " "; }
  .page-header:after {
    clear: both; }
  .page-header .container {
    height: 100%; }
  @media (max-width: 766px) {
    .page-header.menu-hidden {
      background-color: #fff;
      box-shadow: 0px 0px 25px 0px rgba(67, 68, 104, 0.05); }
      .page-header.menu-hidden.global-color-white .logo {
        fill: #b3001b; } }
  @media (min-width: 768px) {
    .page-header {
      height: 145px; }
      .page-header.small {
        height: 100px; }
      .page-header.menu-hidden:hover, .page-header.menu-hidden:focus {
        background-color: #fff;
        box-shadow: 0px 0px 25px 0px rgba(67, 68, 104, 0.05); }
        .page-header.menu-hidden:hover.global-color-white .logo, .page-header.menu-hidden:focus.global-color-white .logo {
          fill: #b3001b; }
        .page-header.menu-hidden:hover .main-nav,
        .page-header.menu-hidden:hover .language, .page-header.menu-hidden:focus .main-nav,
        .page-header.menu-hidden:focus .language {
          opacity: 1;
          visibility: visible; } }

.page-header__logo {
  text-decoration: none;
  opacity: 1;
  backface-visibility: hidden;
  display: inline-block;
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  pointer-events: auto; }
  .page-header__logo img {
    display: block; }
  .page-header__logo:hover, .page-header__logo:focus {
    opacity: 0.8;
    backface-visibility: hidden; }
  .mobile-menu .page-header__logo {
    position: absolute;
    padding: 0; }
  @media (min-width: 768px) {
    .page-header__logo {
      top: 30px; }
      .small .page-header__logo {
        top: 10px; } }

.logo {
  width: 48px;
  height: 60px;
  fill: #b3001b;
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1); }
  @media (min-width: 768px) {
    .logo {
      width: 60px;
      height: 75px; }
      .small .logo {
        width: 60px;
        height: 75px; } }
  @media (min-width: 1024px) {
    .logo {
      width: 80px;
      height: 100px; }
      .small .logo {
        width: 60px;
        height: 75px; } }

.main-nav {
  text-transform: uppercase;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  color: #333333;
  margin: 0;
  font-family: 'Roboto Mono', Helvetica, Arial, Verdana, monospace;
  font-weight: 700;
  font-size: 14px;
  font-size: 1.4rem;
  letter-spacing: 1px; }
  .main-nav a {
    text-decoration: none;
    color: #b3001b;
    padding: 5px 15px;
    display: block;
    pointer-events: auto;
    position: relative; }
    .main-nav a:after {
      height: 2px;
      content: '';
      position: absolute;
      bottom: 0;
      left: 15px;
      right: 15px;
      transition: 0.25s cubic-bezier(0, 0, 0.58, 1); }
  .main-nav a:hover,
  .main-nav a:focus,
  .main-nav li.active a {
    color: #b3001b; }
    .main-nav a:hover:not(.no-line):after,
    .main-nav a:focus:not(.no-line):after,
    .main-nav li.active a:not(.no-line):after {
      box-shadow: inset 0px 2px 0px 0px #b3001b; }
  .mobile-menu .main-nav {
    font-size: 18px;
    font-size: 1.8rem; }
    .mobile-menu .main-nav li {
      margin-bottom: 15px; }
  @media (min-width: 768px) {
    .main-nav {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      display: block;
      width: 100%;
      text-align: center;
      opacity: 1;
      visibility: visible;
      transition: 0.25s cubic-bezier(0, 0, 0.58, 1); }
      .menu-hidden .main-nav {
        opacity: 0;
        visibility: hidden; }
      .main-nav li {
        float: left; }
        .main-nav li:nth-child(3):after {
          content: '';
          height: 14px;
          width: 105px;
          display: inline-block;
          transition: 0.25s cubic-bezier(0, 0, 0.58, 1); }
      .main-nav a {
        display: inline-block;
        padding: 5px 5px; }
        .menu-hidden.global-color-white .main-nav a {
          color: #b3001b; }
        .main-nav a:after {
          left: 5px;
          right: 5px; }
      .menu-hidden.global-color-white .main-nav a:hover, .menu-hidden.global-color-white
      .main-nav a:focus, .menu-hidden.global-color-white
      .main-nav li.active a {
        color: #b3001b; }
        .menu-hidden.global-color-white .main-nav a:hover:after, .menu-hidden.global-color-white
        .main-nav a:focus:after, .menu-hidden.global-color-white
        .main-nav li.active a:after {
          box-shadow: inset 0px 2px 0px 0px #b3001b; } }
  @media (min-width: 1024px) {
    .main-nav li:nth-child(3):after {
      width: 235px; }
    .main-nav a {
      padding: 5px 15px; }
      .main-nav a:after {
        left: 15px;
        right: 15px; } }

.main-nav__notification {
  position: relative; }
  .main-nav__notification:before {
    content: '';
    position: absolute;
    width: 6px;
    height: 6px;
    top: 1px;
    right: 10px;
    background-color: #FB5A99;
    border-radius: 50%; }
  @media (min-width: 768px) {
    .main-nav__notification:before {
      right: 0; } }
  @media (min-width: 1024px) {
    .main-nav__notification:before {
      right: 10px; } }

.main-nav__trigger {
  display: block;
  padding: 12px 52px 12px 20px;
  background-color: transparent;
  color: #b3001b;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  top: 18px;
  right: 20px;
  pointer-events: auto;
  font-family: 'Roboto Mono', Helvetica, Arial, Verdana, monospace;
  font-weight: 700;
  font-size: 14px;
  font-size: 1.4rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-right: -20px; }
  .mobile-menu .main-nav__trigger {
    display: none; }
  .main-nav__trigger .icon-hamburger {
    position: absolute;
    left: auto;
    right: 20px;
    top: 16px; }
  .main-nav__trigger:hover, .main-nav__trigger:focus {
    background-color: transparent;
    box-shadow: none; }
  .main-nav__trigger:active {
    transform: none; }
  @media (min-width: 768px) {
    .main-nav__trigger {
      display: none; } }

.main-nav__wrap {
  display: none;
  margin: 0;
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1); }
  .mobile-menu .main-nav__wrap {
    display: block !important;
    padding: 80px 0 0; }
  @media (min-width: 768px) {
    .main-nav__wrap {
      display: inline-block; } }

.language {
  position: relative;
  float: left;
  margin-top: 23px;
  display: block;
  pointer-events: auto;
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1);
  opacity: 1;
  visibility: visible; }
  .mobile-menu .language {
    display: block;
    float: none;
    margin-top: 0; }
  @media (min-width: 768px) {
    .language {
      margin-top: 52px;
      float: right; }
      .menu-hidden .language {
        opacity: 0;
        visibility: hidden; }
      .small .language {
        margin-top: 29px; } }

.language__trigger {
  font-family: 'Roboto Slab', Helvetica, Arial, Verdana, serif;
  font-size: 14px;
  font-size: 1.4rem;
  white-space: nowrap;
  color: #b3001b;
  background-color: transparent;
  padding: 5px 0; }
  .language__trigger .icon-flag {
    position: relative;
    top: 1px; }
  .language__trigger span {
    vertical-align: middle;
    text-decoration: underline;
    margin-left: 5px; }
  @media (min-width: 768px) {
    .menu-hidden.global-color-white .language__trigger {
      color: #b3001b; } }
  @media (min-width: 1024px) {
    .language__trigger {
      min-width: 80px; } }

.language__dropdown {
  padding-top: 10px;
  position: absolute;
  left: -11px;
  z-index: 999;
  width: 122px; }
  .mobile-menu .language__dropdown {
    left: auto;
    right: 50%;
    transform: translateX(50%);
    margin-bottom: 30px; }
  @media (min-width: 768px) {
    .language__dropdown {
      left: -45px; } }
  @media (min-width: 1024px) {
    .language__dropdown {
      left: -11px; } }

.language__arrow {
  position: absolute;
  top: -7px;
  left: 42px;
  border-style: solid;
  border-color: #b3001b;
  border-width: 2px 0 0 2px;
  width: 11px;
  height: 11px;
  transform: rotate(45deg);
  z-index: 0;
  background-color: #fff; }

.language__wrap {
  list-style: none;
  margin: 0;
  border-style: solid;
  border-width: 0 2px 2px;
  border-color: #b3001b;
  border-top-color: transparent;
  border-radius: 5px;
  font-family: 'Roboto Mono', Helvetica, Arial, Verdana, monospace;
  font-size: 13px;
  font-size: 1.3rem;
  position: relative;
  display: none;
  background-color: #fff; }
  .language__wrap:before, .language__wrap:after {
    content: '';
    position: absolute;
    top: -2px;
    height: 15px;
    border-style: solid;
    border-color: #b3001b;
    z-index: 0; }
  .language__wrap:before {
    right: -2px;
    border-width: 2px 2px 0 0;
    border-top-right-radius: 5px;
    width: 66px; }
  .language__wrap:after {
    left: -2px;
    border-width: 2px 0 0 2px;
    border-top-left-radius: 5px;
    width: 43px; }
  .language__wrap li {
    white-space: nowrap; }
    .language__wrap li a {
      text-decoration: none;
      padding: 5px 9px;
      display: block;
      color: #b3001b;
      z-index: 2;
      position: relative; }
      .language__wrap li a span {
        vertical-align: middle; }
    .language__wrap li:nth-child(2) a {
      padding-top: 10px; }
    .language__wrap li:last-child a {
      padding-bottom: 10px; }
  .mobile-menu .language__wrap {
    text-align: left; }
    .mobile-menu .language__wrap li {
      margin: 0; }
      .mobile-menu .language__wrap li a {
        text-transform: none;
        letter-spacing: 0; }

.mobile-menu .modaal-close {
  border-radius: 0;
  background-color: transparent; }
  .mobile-menu .modaal-close:before, .mobile-menu .modaal-close:after {
    background-color: #b3001b;
    border-radius: 3px; }

.modaal-content-container {
  text-transform: uppercase;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  color: #333333;
  margin: 0;
  font-family: 'Roboto Mono', Helvetica, Arial, Verdana, monospace;
  font-weight: 700;
  font-size: 18px;
  font-size: 1.8rem;
  letter-spacing: 1px;
  text-align: center; }
  .modaal-content-container li {
    list-style: none;
    margin-bottom: 10px; }
  .modaal-content-container a {
    text-decoration: none;
    color: #b3001b;
    padding: 5px 15px;
    display: inline-block;
    pointer-events: auto;
    position: relative; }
    .modaal-content-container a:after {
      height: 2px;
      content: '';
      position: absolute;
      bottom: 0;
      left: 15px;
      right: 15px;
      transition: 0.25s cubic-bezier(0, 0, 0.58, 1); }
  .modaal-content-container a:hover,
  .modaal-content-container a:focus,
  .modaal-content-container li.active a {
    color: #b3001b; }
    .modaal-content-container a:hover:not(.no-line):after,
    .modaal-content-container a:focus:not(.no-line):after,
    .modaal-content-container li.active a:not(.no-line):after {
      box-shadow: inset 0px 2px 0px 0px #b3001b; }

.skip-link {
  left: -999px;
  position: absolute;
  top: auto;
  overflow: hidden;
  z-index: -999;
  white-space: nowrap; }
  .skip-link:focus {
    top: 0;
    left: 0;
    z-index: 999;
    background-color: #fff;
    padding: 0.5em 1em;
    font-size: 20px;
    font-size: 2rem; }

/* =============================================================================
   MAIN
   ========================================================================== */
@media (min-width: 768px) {
  .main {
    padding: 0 0 450px; } }

/* =============================================================================
   FOOTER
   ========================================================================== */
.page-footer {
  position: relative;
  color: #b3001b !important; }
  @media (min-width: 768px) {
    .page-footer {
      height: 450px;
      margin: -450px 0 0;
      z-index: 5; } }

.page-footer__top {
  background-color: #F5F5F7;
  display: flex;
  align-items: center;
  padding: 20px 0; }
  .page-footer__top .social {
    margin: 0 0 0 -10px; }
  .page-footer__top .social__item {
    padding: 0 0 10px 10px; }
  .page-footer__top .social__link {
    width: 21px;
    height: 21px;
    fill: #b3001b; }
    .page-footer__top .social__link .icon {
      width: 21px;
      height: 21px; }
  .page-footer__top .grid__item p:last-child {
    margin-bottom: 0; }
  @media (min-width: 768px) {
    .page-footer__top {
      height: 400px; }
      .page-footer__top .container > .grid > .grid__item:first-child, .page-footer__top .container > .grid--rev > .grid__item:first-child, .page-footer__top .container > .grid--full > .grid__item:first-child, .page-footer__top .container > .grid--half > .grid__item:first-child, .page-footer__top .container > .grid--x2 > .grid__item:first-child {
        position: relative; }
        .page-footer__top .container > .grid > .grid__item:first-child:after, .page-footer__top .container > .grid--rev > .grid__item:first-child:after, .page-footer__top .container > .grid--full > .grid__item:first-child:after, .page-footer__top .container > .grid--half > .grid__item:first-child:after, .page-footer__top .container > .grid--x2 > .grid__item:first-child:after {
          content: '';
          position: absolute;
          height: 100%;
          top: 0;
          right: -20px;
          background-image: linear-gradient(#b3001b 33%, rgba(255, 255, 255, 0) 0%);
          background-position: right;
          background-size: 3px 13px;
          background-repeat: repeat-y;
          width: 1px; } }

.page-footer__block, .contact-block {
  padding: 18px 0;
  width: 100%; }
  .page-footer__block .h3, .contact-block .h3 {
    color: #b3001b !important; }
  .page-footer__block a, .contact-block a {
    color: #b3001b;
    text-decoration: none; }
    .page-footer__block a:hover .page-footer__block a:focus, .contact-block a:hover .page-footer__block a:focus, .page-footer__block a:hover .contact-block a:focus, .contact-block a:hover .contact-block a:focus {
      text-decoration: none; }
  .page-footer .page-footer__block a, .page-footer .contact-block a {
    color: #b3001b !important; }
  @media (min-width: 768px) {
    .page-footer__block, .contact-block {
      padding: 9px 0; } }

.page-footer__bottom {
  background-color: #fff;
  font-weight: 300;
  font-size: 14px;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  padding: 20px 0;
  text-align: center; }
  .page-footer__bottom a {
    text-decoration: underline;
    color: #b3001b !important; }
  .page-footer__bottom ul {
    margin: 0 0 20px;
    *zoom: 1;
    display: inline-block;
    padding-top: 1px; }
    .page-footer__bottom ul:before, .page-footer__bottom ul:after {
      display: table;
      content: " "; }
    .page-footer__bottom ul:after {
      clear: both; }
    .page-footer__bottom ul li {
      float: left;
      position: relative; }
      .page-footer__bottom ul li + li {
        margin-left: 15px; }
        .page-footer__bottom ul li + li:before {
          position: absolute;
          content: '';
          height: 1px;
          width: 5px;
          background-color: #b3001b !important;
          left: -10px;
          top: calc(50% + 1px); }
  @media (min-width: 768px) {
    .page-footer__bottom {
      height: 50px;
      text-align: left; }
      .page-footer__bottom ul {
        margin: 0; } }

a.esign {
  width: 58px;
  height: 15px;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1);
  display: block;
  outline: 0;
  z-index: 9999;
  overflow: hidden;
  margin: 0 auto; }
  a.esign:hover, a.esign:focus {
    opacity: 1; }
  @media (min-width: 768px) {
    a.esign {
      width: 11px;
      position: absolute;
      right: 40px;
      top: 6px;
      margin: 0; }
      a.esign:hover, a.esign:focus {
        width: 58px; }
        a.esign:hover .esign__logo, a.esign:focus .esign__logo {
          fill: #b3001b; } }

.esign__logo {
  width: 58px;
  height: 15px;
  fill: #b3001b; }

/* =============================================================================
   COMPONENTS
   ========================================================================== */
.large {
  font-size: 20px;
  font-size: 2rem; }

.center-block {
  *zoom: 1;
  text-align: center;
  margin: 20px 0; }
  .center-block:before, .center-block:after {
    display: table;
    content: " "; }
  .center-block:after {
    clear: both; }
  .center-block .button {
    float: none;
    display: inline-block; }

section {
  position: relative; }

article {
  *zoom: 1;
  margin: 0 0 20px; }
  article:before, article:after {
    display: table;
    content: " "; }
  article:after {
    clear: both; }
  article a img {
    display: block;
    opacity: 1;
    backface-visibility: hidden; }
  article a:hover img, article a:focus img {
    opacity: 0.8;
    backface-visibility: hidden; }

.article-detail__image {
  margin-bottom: 1.5em; }

.note, .response, div.mce_inline_error {
  *zoom: 1;
  margin: 0 0 20px;
  padding: 15px 20px;
  background: #efefef;
  border-radius: 5px; }
  .note:before, .response:before, div.mce_inline_error:before, .note:after, .response:after, div.mce_inline_error:after {
    display: table;
    content: " "; }
  .note:after, .response:after, div.mce_inline_error:after {
    clear: both; }
  .note ul, .response ul, div.mce_inline_error ul,
  .note p,
  .response p,
  div.mce_inline_error p {
    margin-bottom: 1em; }
    .note ul:last-child, .response ul:last-child, div.mce_inline_error ul:last-child,
    .note p:last-child,
    .response p:last-child,
    div.mce_inline_error p:last-child {
      margin-bottom: 0; }

.note--success, #mce-success-response {
  color: #39963a;
  background-color: #eff9ef; }

.note--error, #mce-error-response, div.mce_inline_error {
  color: #FA3323;
  background-color: white; }

#mce-error-response {
  margin: 10px 0 0 !important; }

#mce-success-response {
  margin: 10px 0 0 !important; }

div.mce_inline_error {
  margin: 10px 0 0 !important; }

.arrow-label, .giveaway__title .subtitle {
  font-family: 'Roboto Slab', Helvetica, Arial, Verdana, serif;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  color: #b3001b;
  position: relative;
  margin-bottom: 45px;
  padding-right: 15px; }
  .arrow-label strong, .giveaway__title .subtitle strong {
    color: #F38533; }
  .arrow-label .icon-arrow-left, .giveaway__title .subtitle .icon-arrow-left,
  .arrow-label .icon-arrow-right,
  .giveaway__title .subtitle .icon-arrow-right {
    fill: #b3001b;
    position: absolute;
    bottom: -45px; }
  .arrow-label .icon-arrow-left, .giveaway__title .subtitle .icon-arrow-left {
    transform: rotate(337deg); }
  
  .arrow-label .icon-arrow-right,
  .giveaway__title .subtitle .icon-arrow-right {
    transform: rotate(20deg);
    bottom: -47px; }

.arrow-label--left {
  text-align: left; }
  .arrow-label--left .icon {
    left: 35px; }

.arrow-label--right {
  text-align: right; }
  .arrow-label--right .icon {
    right: 80px; }

.cycle-slideshow {
  width: 100%;
  position: relative; }

.cycle-pager {
  width: 100%;
  text-align: center; }
  .cycle-pager span {
    display: inline-block;
    margin: 0 3px;
    font-size: 36px;
    height: 20px;
    width: 20px;
    line-height: 14px;
    cursor: pointer;
    border: 2px solid #fff;
    border-radius: 20px;
    color: #fff;
    overflow: hidden;
    text-indent: -500px;
    transition: 0.25s cubic-bezier(0, 0, 0.58, 1); }
    .cycle-pager span:hover, .cycle-pager span:focus {
      transition: none; }
  .cycle-pager span:hover,
  .cycle-pager span:focus,
  .cycle-pager .cycle-pager-active {
    text-indent: 0; }

.hero {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 0;
  padding-bottom: 100%;
  position: relative;
  overflow: hidden; }
  .hero:before, .hero:after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    height: 0; }
  .hero:before {
    border-top: 1px solid #fff; }
  .hero:after {
    padding-bottom: 1.388888888%;
    background-image: url("../images/shape-hero-trim.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center; }
  @media (min-width: 768px) {
    .hero {
      height: 100vh;
      padding-bottom: 0; } }

.hero--dark .hero__link-button,
.hero--dark .hero__link-button span,
.hero--dark .hero__scroll-button {
  color: #fff; }
  .hero--dark .hero__link-button .icon,
  .hero--dark .hero__link-button span .icon,
  .hero--dark .hero__scroll-button .icon {
    fill: #fff; }

.hero--small:after {
  padding-bottom: 1.04166667%;
  background-image: url("../images/shape-hero-trim-product.png"); }

@media (min-width: 768px) {
  .hero--small {
    height: 550px;
    padding-bottom: 0; } }

.hero--medium:after {
  padding-bottom: 1.04166667%;
  background-image: url("../images/shape-hero-trim-product.png"); }

@media (min-width: 768px) {
  .hero--medium {
    height: 60vh;
    padding-bottom: 0; } }

.hero__background {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; }

.hero__link-button {
  display: inline-block;
  font-family: 'Roboto Slab', Helvetica, Arial, Verdana, serif;
  font-weight: 700;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  position: absolute;
  bottom: calc(12.5% - 40px);
  left: calc(8% - 20px);
  padding: 30px;
  transform: scale(0.5) translate3d(0, 0, 0);
  transform-origin: left; }
  .hero__link-button span {
    max-width: 140px;
    display: block; }
  .hero__link-button .icon {
    transform: translate3d(0, 0, 0) rotate(160deg) scale(0.95);
    fill: #b3001b;
    top: -22px;
    left: 85px;
    position: absolute;
    pointer-events: none;
    transition: 0.25s cubic-bezier(0, 0, 0.58, 1); }
  .hero__link-button:hover, .hero__link-button:focus {
    transform: translate3d(0, 0, 0) scale(1.3); }
    .hero__link-button:hover .icon, .hero__link-button:focus .icon {
      transform: translate3d(10px, -10px, 0) rotate(175deg) scale(0.95); }
  @media (min-width: 768px) {
    .hero__link-button {
      transform: scale(1) translate3d(0, 0, 0);
      left: calc(8% - 40px); } }

.hero__title {
  margin: 50px 0; }

.hero__text {
  max-width: 100ch; }

#form-the-gutsy-captain-kombucha {
  margin-bottom: 50px; }

.hero__scroll-button {
  background-color: transparent;
  color: #b3001b;
  width: 50px;
  height: 50px;
  position: absolute;
  bottom: 15px;
  left: 50%;
  margin-left: -25px; }
  .hero__scroll-button .icon {
    fill: #b3001b;
    width: 15px;
    height: 20px; }
  @media (min-width: 768px) {
    .hero__scroll-button {
      bottom: 30px; } }

.hero__scroll-button-2 {
  font-family: 'Roboto Slab', Helvetica, Arial, Verdana, serif;
  font-weight: 700;
  background-color: transparent;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  width: 170px;
  bottom: 100px;
  left: 50%;
  position: absolute;
  margin-left: -85px;
  transform: scale(0) translate3d(0, 0, 0);
  letter-spacing: 1.5px;
  display: block; }
  .hero--small .hero__scroll-button-2 {
    bottom: 70px; }
  .hero__scroll-button-2 .icon {
    fill: #fff;
    position: absolute;
    display: block;
    transform: rotate(310deg) scale(0.9);
    bottom: -45px;
    left: 63px; }
  @media (max-width: 766px) {
    .hero__scroll-button-2 {
      bottom: 50px; }
      .hero--small .hero__scroll-button-2 {
        bottom: 50px; }
      .hero__scroll-button-2 span {
        transform: scale(0.5);
        display: block; }
      .hero__scroll-button-2 .icon {
        bottom: -35px;
        transform: rotate(310deg) scale(0.7); } }

.jumbotron {
  overflow: hidden; }

.jumbotron__wrap {
  max-width: 1600px;
  margin: 0 auto; }

.jumbotron__item {
  position: relative; }
  .jumbotron__item .button {
    float: none;
    display: inline-block;
    color: #fff;
    border-color: #fff;
    text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.15);
    box-shadow: 3px 3px 3px 0px rgba(0, 0, 0, 0.15);
    position: relative; }
    .jumbotron__item .button .icon {
      position: absolute;
      fill: #fff;
      transition: 0.25s cubic-bezier(0, 0, 0.58, 1); }
  .jumbotron__item .decorated {
    font-size: 70px;
    transition: 0.25s cubic-bezier(0, 0, 0.58, 1); }
    @media screen and (min-width: 480px) {
      .jumbotron__item .decorated {
        font-size: calc(70px + 80 * (100vw - 480px) / 544); } }
    @media screen and (min-width: 1024px) {
      .jumbotron__item .decorated {
        font-size: 150px; } }
  @media (min-width: 768px) {
    .jumbotron__item .button {
      transform: scale(1.4); } }
  @media (min-width: 1024px) {
    .jumbotron__item .button {
      transform: scale(1.3); } }
  @media (min-width: 1200px) {
    .jumbotron__item .button {
      transform: scale(1.2); } }
  @media (min-width: 1400px) {
    .jumbotron__item .button {
      transform: scale(1); } }

.jumbotron__item--drinks .button {
  background-color: rgba(243, 133, 51, 0.35); }
  .jumbotron__item--drinks .button .icon {
    transform: rotate(120deg) scale(0.9);
    bottom: 60px;
    left: calc(50% - 12px);
    filter: drop-shadow(-1px -3px 1px rgba(0, 0, 0, 0.15)); }

.jumbotron__item--drinks .jumbotron__bg {
  opacity: 0.8;
  right: calc(-50% - 100px);
  left: -50%; }

.jumbotron__item--drinks .jumbotron__bg--rev {
  transform: translateX(-100%) scaleX(-1); }

.jumbotron__item--drinks .jumbotron__image {
  max-width: 390px;
  margin-bottom: 65px;
  padding-left: 30px; }

.jumbotron__item--drinks .decorated {
  opacity: 0.5;
  margin-bottom: 40px; }

@media (min-width: 768px) {
  .jumbotron__item--drinks {
    margin-right: -18%; }
    .jumbotron__item--drinks .jumbotron__inner {
      padding-bottom: 93.867924528%; }
    .jumbotron__item--drinks .jumbotron__content {
      margin-left: -100px; }
    .jumbotron__item--drinks .jumbotron__bg {
      right: 0;
      left: 0; } }

.jumbotron__item--snacks .button {
  background-color: rgba(179, 0, 27, 0.35);
  margin-bottom: 85px; }
  .jumbotron__item--snacks .button .icon {
    transform: rotate(315deg) scale(0.9);
    top: 60px;
    left: calc(50% - 12px);
    filter: drop-shadow(1px 3px 1px rgba(0, 0, 0, 0.15)); }

.jumbotron__item--snacks .jumbotron__link {
  padding-top: 55px; }

.jumbotron__item--snacks .jumbotron__bg {
  opacity: 0.8;
  right: -50%;
  left: calc(-50% - 100px); }

.jumbotron__item--snacks .jumbotron__bg--rev {
  transform: translateX(calc(100% - 2px)) scaleX(-1); }

.jumbotron__item--snacks .jumbotron__image {
  max-width: 432px; }

.jumbotron__item--snacks .decorated {
  opacity: 0.65; }

@media (min-width: 768px) {
  .jumbotron__item--snacks {
    margin-left: -23%; }
    .jumbotron__item--snacks .jumbotron__inner {
      padding-bottom: 90.06772009%; }
    .jumbotron__item--snacks .jumbotron__content {
      margin-right: -100px; }
    .jumbotron__item--snacks .jumbotron__bg {
      left: 0;
      right: 0; } }

.jumbotron__bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  z-index: -1; }

.jumbotron__image {
  width: 100%;
  margin: 0 auto;
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1); }

.jumbotron__content {
  height: 100%;
  text-align: center;
  padding: 75px 40px; }
  @media (min-width: 768px) {
    .jumbotron__content {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      padding: 0 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      transform: scale(0.6); } }
  @media (min-width: 1024px) {
    .jumbotron__content {
      transform: scale(0.7); } }
  @media (min-width: 1200px) {
    .jumbotron__content {
      transform: scale(0.8); } }
  @media (min-width: 1400px) {
    .jumbotron__content {
      transform: scale(1); } }

@media (min-width: 768px) {
  .jumbotron__inner {
    height: 0; } }

.jumbotron__link {
  text-decoration: none; }
  .jumbotron__link:hover .decorated, .jumbotron__link:focus .decorated {
    transform: rotate(-2deg) scale(1.05); }
  .jumbotron__link:hover .button, .jumbotron__link:focus .button {
    transform: translateY(2px);
    text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.15);
    box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.15); }
    .jumbotron__item--drinks .jumbotron__link:hover .button .icon, .jumbotron__item--drinks .jumbotron__link:focus .button .icon {
      transform: translateY(-15px) rotate(120deg) scale(0.9); }
    .jumbotron__item--snacks .jumbotron__link:hover .button .icon, .jumbotron__item--snacks .jumbotron__link:focus .button .icon {
      transform: translateY(15px) rotate(315deg) scale(0.9); }
  .jumbotron__link:hover .jumbotron__image, .jumbotron__link:focus .jumbotron__image {
    transform: translateY(-5px); }

.social {
  *zoom: 1;
  list-style: none;
  margin: 0 0 0 -15px;
  display: inline-block; }
  .social:before, .social:after {
    display: table;
    content: " "; }
  .social:after {
    clear: both; }

.social__item {
  padding: 0 0 15px 15px;
  float: left; }

.social__link {
  height: 28px;
  width: 28px;
  line-height: 0;
  display: block;
  fill: #b3001b;
  text-decoration: none;
  overflow: hidden; }
  .social__link .icon {
    height: 28px;
    width: 28px; }

.social-grid__item img {
  height: 250px;
  width: 250px;
  object-fit: cover; }

.social-grid__item.link {
  position: relative;
  display: block;
  overflow: hidden;
  height: 0;
  padding-bottom: 100%;
  margin-bottom: 20px; }

.social-widget:hover .decorated, .social-widget:focus .decorated {
  transform: translateY(-10px); }

.social-widget .grid, .social-widget .grid--rev, .social-widget .grid--full, .social-widget .grid--half, .social-widget .grid--x2 {
  margin-bottom: 60px;
  min-height: 100px; }

.social-widget .decorated {
  margin-bottom: 0px;
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1);
  display: flex;
  justify-content: center; }

@media (min-width: 768px) {
  .social-widget .decorated {
    margin-bottom: -50px; } }

@media (min-width: 1024px) {
  .social-widget .decorated {
    margin-bottom: -75px; } }

.social-item-link {
  position: relative;
  display: block;
  overflow: hidden;
  height: 0;
  padding-bottom: 100%;
  margin-bottom: 20px;
  box-shadow: 10px 10px 15px 0px rgba(0, 0, 0, 0.15); }
  .social-item-link:hover .social-item-link__content, .social-item-link:focus .social-item-link__content {
    opacity: 1; }

.social-item-link__image {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; }

@supports (object-fit: cover) {
  .social-item-link__image {
    object-fit: cover;
    height: 100%; } }

.social-item-link__content {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
  transition: 0.25s cubic-bezier(0, 0, 0.58, 1);
  font-family: 'Roboto Slab', Helvetica, Arial, Verdana, serif;
  display: flex;
  align-items: flex-end;
  padding: 20px;
  opacity: 0;
  text-align: left; }

.content-section {
  padding: 75px 0;
  overflow: hidden; }
  .content-section + .content-section {
    padding-top: 0; }
  .content-section .link--back {
    position: relative;
    top: -45px; }
  @media (min-width: 768px) {
    .content-section {
      padding: 150px 0; }
      .content-section .link--back {
        top: -90px; } }

.content-section--white {
  background-color: #fff; }

.content-section--padding-none {
  padding: 0; }

.content-section--padding-bottom-none {
  padding-bottom: 0 !important; }

.content-section--padding-top-none {
  padding-top: 0 !important; }

.content-section--padding-small {
  padding-bottom: 30px; }
  @media (min-width: 768px) {
    .content-section--padding-small {
      padding-bottom: 75px; } }

.content-section--padding-small-top {
  padding-top: 30px; }
  @media (min-width: 768px) {
    .content-section--padding-small-top {
      padding-top: 75px; } }

.content-section--spacing {
  margin-top: 30px; }

.content-section--oh {
  overflow: hidden; }

.content-section--social {
  padding-top: 60px !important;
  margin-top: -30px; }

.content-section__image {
  margin-top: 40px;
  margin-bottom: 40px;
  z-index: 1;
  position: relative; }
  @media (min-width: 768px) {
    .content-section__image {
      margin-top: 125px;
      margin-bottom: 80px; } }

.app {
  position: relative;
  max-width: 290px;
  text-align: center;
  margin: 0 auto;
  padding-top: 60px; }
  .app a {
    display: block; }
    .app a:hover, .app a:focus {
      opacity: 0.8; }
  .app.text-left {
    margin: 0 0 0 -12px; }
  @media (min-width: 768px) {
    .app {
      padding-top: 0; } }

.app__logo {
  margin-bottom: 15px;
  margin-top: -20px; }

.app__title {
  font-family: 'Roboto Slab', Helvetica, Arial, Verdana, serif;
  font-weight: 700;
  color: #b3001b;
  position: absolute;
  top: 65px;
  width: 130px;
  text-transform: uppercase;
  text-align: center;
  line-height: 1.2em;
  right: -70px;
  letter-spacing: 1px;
  transform: scale(0.6);
  transform-origin: left; }
  .app__title .icon {
    fill: #b3001b;
    position: absolute;
    bottom: -50px;
    left: 40px;
    transform: rotate(-6deg); }
  @media (min-width: 1024px) {
    .app__title {
      transform: scale(1);
      margin: 0;
      right: -100px; } }
  @media (min-width: 1400px) {
    .app__title {
      right: -150px; } }

.app__apple a {
  padding: 6.5% 11%;
  transform: translateX(-7%); }

.tags {
  margin-top: 40px; }

.tag {
  font-family: 'Roboto Slab', Helvetica, Arial, Verdana, serif;
  font-size: 15px;
  font-size: 1.5rem;
  text-transform: uppercase;
  color: #b3001b;
  border: 1px solid #b3001b;
  border-radius: 5px;
  line-height: 29px;
  height: 30px;
  padding: 0 10px;
  display: inline-block;
  float: left;
  margin: 0 10px 10px 0; }

.image-grid {
  margin-top: -30px; }
  .image-grid .grid__item {
    align-items: flex-end; }

.image-grid__item {
  height: 0;
  padding-bottom: 65.625%;
  margin-bottom: 20px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%; }
  .image-grid__item img {
    display: none; }
  @media (min-width: 768px) {
    .medium--one-whole .image-grid__item {
      padding-bottom: 38.8888889%;
      background-position: bottom center; } }

.image-grid__item--portrait {
  padding-bottom: 119.047619048%; }

.image-grid__item--square {
  padding-bottom: 100%; }

.shop-button {
  height: 80px; }
  .shop-button a {
    position: fixed;
    border-radius: 10px;
    right: 50%;
    bottom: 20px;
    z-index: 10;
    width: 100px;
    overflow: hidden;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
    transform: translateX(50%); }
    .shop-button a:hover, .shop-button a:focus {
      box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.2); }
  @media (min-width: 768px) {
    .shop-button {
      height: auto; }
      .shop-button a {
        width: 150px;
        right: 40px;
        bottom: 45px;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
        transform: translateX(0); }
        .shop-button a:hover, .shop-button a:focus {
          box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2); } }

.shop-modal a {
  padding: 0; }
  .shop-modal a:after {
    display: none; }

.rounded {
  border-radius: 20px;
  overflow: hidden; }

/* =============================================================================
   PAGES
   ========================================================================== */
.brands-intro {
  padding-top: 50px;
  text-align: center; }
  @media (min-width: 768px) {
    .brands-intro {
      text-align: right; } }

.product-grid:hover .grid__item, .product-grid:focus .grid__item,
.product-grid .animating .grid__item {
  z-index: 1; }

.product-grid .grid__item {
  position: relative; }
  .product-grid .grid__item:after {
    content: '';
    position: absolute;
    right: 0;
    z-index: -1;
    top: 25px;
    bottom: 25px;
    background-image: linear-gradient(#b3001b 33%, rgba(255, 255, 255, 0) 0%);
    background-position: right;
    background-size: 3px 13px;
    background-repeat: repeat-y;
    width: 1px; }
  .product-grid .grid__item:hover, .product-grid .grid__item:focus, .product-grid .grid__item.hover {
    z-index: 2; }
  .product-grid .grid__item:nth-child(2n):after {
    display: none; }

@media (min-width: 768px) {
  .product-grid .grid__item:nth-child(2n):after {
    display: block; }
  .product-grid .grid__item:nth-child(3n):after {
    display: none; } }

@media (min-width: 1024px) {
  .product-grid .grid__item:nth-child(3n):after {
    display: block; }
  .product-grid .grid__item:nth-child(4n):after {
    display: none; } }

.product {
  position: relative;
  display: block;
  height: 0;
  padding-bottom: 78.125%; }
  .product:before, .product:after {
    content: '';
    position: absolute;
    z-index: -1;
    background-image: linear-gradient(to right, #b3001b 33%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 13px 3px;
    background-repeat: repeat-x;
    height: 1px;
    left: 0;
    right: 0;
    top: 0; }
  .product:after {
    top: 100%; }
  .product:hover .product__bg.loaded, .product:focus .product__bg.loaded,
  .hover .product .product__bg.loaded {
    opacity: 1; }
  .product:hover .product__logo, .product:focus .product__logo,
  .hover .product .product__logo {
    opacity: 0; }
  .product:hover .product__label, .product:focus .product__label,
  .hover .product .product__label {
    opacity: 0.9; }

.product__bg {
  position: absolute;
  top: 1px;
  right: 1px;
  bottom: 0;
  left: 1px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);
  opacity: 0;
  transition: 0.5s cubic-bezier(0.42, 0, 0.58, 1); }

.product__inner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden; }

.product__logo {
  z-index: 2;
  position: absolute;
  top: 1px;
  right: 1px;
  bottom: 0;
  left: 1px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: 0.5s cubic-bezier(0.42, 0, 0.58, 1); }
  .product__logo img {
    display: none; }
  .product__logo.loaded {
    opacity: 1; }

.product__label {
  background-color: #b3001b;
  color: #fff;
  display: block;
  height: 30px;
  line-height: 30px;
  text-transform: lowercase;
  text-align: center;
  font-family: 'Roboto Slab', Helvetica, Arial, Verdana, serif;
  font-weight: 700;
  font-size: 12px;
  font-size: 1.2rem;
  z-index: 3;
  position: absolute;
  width: 100%;
  right: 0;
  bottom: 0;
  transform-origin: center; }
  @media (min-width: 480px) {
    .product__label {
      height: 35px;
      line-height: 35px;
      transform: translate(34%, -56%) rotate(315deg);
      font-size: 14px;
      font-size: 1.4rem; } }
  @media (min-width: 768px) {
    .product__label {
      transform: translate(37%, -56%) rotate(315deg); } }

.product__label--bio {
  background-color: #1EB430;
  right: auto;
  bottom: auto;
  top: 0;
  left: 0;
  text-transform: uppercase; }
  @media (min-width: 480px) {
    .product__label--bio {
      transform: translate(-34%, 56%) rotate(315deg); } }
  @media (min-width: 768px) {
    .product__label--bio {
      transform: translate(-37%, 56%) rotate(315deg); } }

.brand-navigation {
  display: block;
  display: flex;
  position: fixed;
  top: 40%;
  z-index: 111;
  width: 50px;
  height: 50px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  text-transform: uppercase;
  text-decoration: none;
  align-items: center;
  transform: scale(1) translateY(-50%); }
  .brand-navigation i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }
    .brand-navigation i:before {
      font-size: 30px;
      font-size: 3rem; }
  .brand-navigation:hover, .brand-navigation:focus {
    opacity: 0.8; }
  .brand-navigation span {
    color: #b3001b;
    font-family: 'Roboto Slab', Helvetica, Arial, Verdana, serif;
    font-weight: 700;
    font-size: 14px;
    font-size: 1.4rem;
    justify-content: center;
    display: none;
    line-height: 1.4em; }
    :lang(fr) .brand-navigation span {
      font-size: 12px;
      font-size: 1.2rem; }
  @media (min-width: 768px) {
    .brand-navigation {
      width: 120px;
      height: 70px;
      transform: scale(0.8) translateY(-50%);
      top: 275px; }
      .brand-navigation span {
        display: block;
        display: flex; } }
  @media (min-width: 1024px) {
    .brand-navigation {
      transform: scale(1) translateY(-50%); } }

.brand-navigation--previous {
  left: 0;
  flex-direction: row-reverse;
  text-align: center;
  padding: 0 18px 0 15px;
  transform-origin: left; }
  .brand-navigation--previous:before {
    left: 0;
    right: 20px; }
  .brand-navigation--previous i {
    left: 15px; }
  @media (min-width: 768px) {
    .brand-navigation--previous i {
      left: 5px; } }

.brand-navigation--next {
  right: 0;
  text-align: center;
  padding: 0 15px 0 18px;
  transform-origin: right; }
  .brand-navigation--next i {
    right: 15px; }
  @media (min-width: 768px) {
    .brand-navigation--next i {
      right: 5px; } }

.product-hero {
  min-height: 350px;
  background-color: #fff; }
  .product-hero + .content-section {
    padding-top: 0; }

.product-hero__content {
  min-height: 350px;
  padding: 0 0 30px; }
  .product-hero__content .rte {
    padding-bottom: 15px; }
  .product-hero__content .container,
  .product-hero__content .grid,
  .product-hero__content .grid--rev,
  .product-hero__content .grid--full,
  .product-hero__content .grid--half,
  .product-hero__content .grid--x2,
  .product-hero__content .grid__item {
    height: 100%; }
  .product-hero__content .grid__item {
    display: flex;
    align-items: center; }
  .product-hero__content .medium--four-twelfths {
    position: relative; }

.product-hero__logo-wrap {
  height: 0;
  padding-bottom: calc(87.5% - 30px); }

.product-hero__logo {
  position: relative;
  text-align: center;
  max-width: 420px;
  margin-right: auto;
  margin-left: auto;
  width: 100%; }

.product-hero__link {
  color: #b3001b;
  text-decoration: none;
  margin-bottom: 0; }
  .product-hero__link span {
    text-decoration: underline;
    font-family: 'Roboto Slab', Helvetica, Arial, Verdana, serif; }

.product-hero__description {
  max-height: 90px;
  overflow: hidden;
  transition: max-height 0.5s cubic-bezier(0.42, 0, 1, 1);
  margin-top: 90px;
  margin-bottom: 10px; }
  .product-hero__description .rte {
    padding-bottom: 0; }
  .product-hero__description.active {
    max-height: 1000px; }
    .product-hero__description.active + .link .link__more {
      display: none; }
    .product-hero__description.active + .link .link__less {
      display: inline-block; }
  .product-hero__description + .link .link__more {
    display: inline-block; }
  .product-hero__description + .link .link__less {
    display: none; }

.product-header {
  overflow: hidden;
  text-align: center;
  position: relative;
  margin-bottom: 20px; }
  .product-header .decorated {
    color: rgba(179, 0, 27, 0.15);
    margin-bottom: 15px; }
  @media (min-width: 768px) {
    .product-header {
      min-height: 190px;
      margin-bottom: 0;
      display: flex;
      align-items: flex-end;
      justify-content: center; }
      .product-header .decorated {
        transform: translateY(23%);
        margin-bottom: 0; } }

.product-sheet__button {
  display: inline-block; }
  .product-sheet__button .button--download {
    margin-bottom: 0; }
  @media (min-width: 768px) {
    .product-sheet__button {
      position: absolute;
      bottom: 20px;
      right: 0;
      margin-bottom: 0; } }

.product-sheet__thumbnail {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  width: 50px;
  height: 50px;
  float: left; }
  .product-sheet__thumbnail + .button--download {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding-left: 20px;
    padding-right: 20px; }

.product-category {
  background-color: #fff;
  border: 4px solid rgba(179, 0, 27, 0.15);
  margin-bottom: 25px;
  min-height: 350px;
  border-radius: 5px;
  position: relative; }

.product-category__title {
  color: #b3001b;
  font-family: 'Roboto Slab', Helvetica, Arial, Verdana, serif;
  font-weight: 700;
  font-size: 18px;
  font-size: 1.8rem;
  text-align: center;
  padding-top: 20px; }
  .product-category__title span {
    display: block; }
  @media (min-width: 768px) {
    .product-category__title {
      position: absolute;
      left: 35px;
      width: 25px;
      height: 25px;
      bottom: 50%;
      transform: translateY(50%) rotate(270deg);
      display: flex;
      justify-content: center;
      white-space: nowrap;
      text-align: left;
      padding-top: 0; }
      .product-category__title span {
        width: 350px;
        top: 0;
        right: 50%; } }
  @media (min-width: 1024px) {
    .product-category__title {
      left: 65px; } }

.product-category__list {
  margin: 0;
  width: 100%;
  height: 100%;
  justify-content: center; }
  .product-category__list li {
    height: 100%;
    width: 50%; }
  @media (min-width: 480px) {
    .product-category__list li {
      width: 33.33%; } }
  @media (min-width: 768px) {
    .product-category__list {
      padding: 0 0 0 50px;
      justify-content: flex-start; }
      .product-category__list li {
        float: left;
        min-height: 350px;
        width: auto; } }
  @media (min-width: 1024px) {
    .product-category__list {
      padding: 0 0 0 100px; } }

.product-item {
  width: 100%;
  padding: 20px; }
  @media (min-width: 768px) {
    .product-item {
      width: 230px;
      padding: 30px 40px; } }

.product-item__image {
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  mix-blend-mode: multiply;
  height: 0;
  padding-bottom: calc((1200 / 800) * 100%); }
  .product-item__image img {
    display: none; }

.product-item__title {
  text-align: center;
  font-family: 'Roboto Slab', Helvetica, Arial, Verdana, serif;
  font-weight: 300;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.2em;
  color: #b3001b; }
  @media (min-width: 768px) {
    .product-item__title {
      font-size: 14px;
      font-size: 1.4rem;
      line-height: 1.4em; } }

.product-order {
  padding-top: 10px; }
  .product-order .contact-block {
    padding-top: 0;
    margin-top: -5px;
    text-align: center; }
  .product-order .arrow-label--left {
    display: inline-block;
    line-height: 1.4em;
    max-width: 200px; }
    .product-order .arrow-label--left .icon {
      transform: rotate(260deg);
      left: auto;
      right: -60px;
      bottom: -5px; }
  .product-order .app {
    padding-top: 0;
    margin-bottom: 40px; }
  @media (min-width: 768px) {
    .product-order .contact-block {
      text-align: left; }
    .product-order .app {
      margin-bottom: 0; }
    .product-order > .grid > .grid__item:first-child, .product-order > .grid--rev > .grid__item:first-child, .product-order > .grid--full > .grid__item:first-child, .product-order > .grid--half > .grid__item:first-child, .product-order > .grid--x2 > .grid__item:first-child {
      position: relative; }
      .product-order > .grid > .grid__item:first-child:after, .product-order > .grid--rev > .grid__item:first-child:after, .product-order > .grid--full > .grid__item:first-child:after, .product-order > .grid--half > .grid__item:first-child:after, .product-order > .grid--x2 > .grid__item:first-child:after {
        content: '';
        position: absolute;
        height: 100%;
        width: 1px;
        right: calc(-25% - 20px);
        top: 0;
        background-image: linear-gradient(#b3001b 33%, rgba(255, 255, 255, 0) 0%);
        background-position: right;
        background-size: 3px 13px;
        background-repeat: repeat-y;
        width: 1px; } }

.product-order__image {
  height: 0;
  padding-bottom: 50%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat; }
  .product-order__image img {
    display: none; }

.job {
  position: relative;
  padding: 25px 0; }
  .job:after {
    content: '';
    position: absolute;
    z-index: -1;
    background-image: linear-gradient(to right, #b3001b 33%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 13px 3px;
    background-repeat: repeat-x;
    height: 1px;
    height: 1px;
    left: 0;
    right: 0;
    bottom: 0; }
  .job .button {
    margin-top: 20px; }
  @media (min-width: 768px) {
    .job .button {
      margin-top: 0;
      float: right; }
    .job .grid, .job .grid--rev, .job .grid--full, .job .grid--half, .job .grid--x2 {
      display: flex;
      align-items: center; } }

.job__title {
  font-family: 'Roboto Slab', Helvetica, Arial, Verdana, serif;
  font-weight: 700;
  color: #b3001b;
  margin: 0; }

.job__location {
  font-family: 'Roboto Slab', Helvetica, Arial, Verdana, serif;
  font-weight: 700;
  color: #8D8D8D;
  margin: 0; }
  .job__location .icon {
    vertical-align: middle;
    margin-right: 5px; }
  .job__location span {
    vertical-align: middle; }

.job-graphic {
  max-width: 150px;
  margin: 40px auto 40px; }
  @media (min-width: 768px) {
    .job-graphic {
      max-width: 280px;
      margin-top: -65px;
      float: right; } }

.cta .button {
  padding-left: 35px;
  padding-right: 30px; }

.cta--center .cta__tagline {
  margin-bottom: 0;
  text-align: right; }

@media (min-width: 768px) {
  .cta--center .cta__tagline {
    margin-top: -40px; } }

.cta__tagline {
  font-family: 'Roboto Slab', Helvetica, Arial, Verdana, serif;
  font-weight: 700;
  color: #b3001b;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  clear: both;
  margin-bottom: -100px;
  text-align: right;
  padding-top: 40px; }
  .cta__tagline span {
    vertical-align: middle;
    margin-left: 15px; }
  .cta__tagline .icon {
    fill: #b3001b;
    transform: translateY(-20px) rotate(90deg) scale(1.1); }
  @media (min-width: 768px) {
    .cta__tagline {
      margin-bottom: -115px;
      transform: translate(35px, 40px);
      clear: none;
      text-align: left;
      padding-top: 0; }
      .cta__tagline .icon {
        transform: translateY(-7px) rotate(60deg) scale(1.1); } }

.contact-block {
  text-align: right;
  margin-bottom: 70px; }
  .contact-block h1 {
    font-size: 18px;
    line-height: 1.5em;
    margin-right: 0;
    margin-left: -10px;
    padding: 35px 0 0;
    margin-bottom: -15px; }
    @media screen and (min-width: 768px) {
      .contact-block h1 {
        font-size: calc(18px + 5 * (100vw - 768px) / 256); } }
    @media screen and (min-width: 1024px) {
      .contact-block h1 {
        font-size: 23px; } }

.contact-bg {
  height: 180px;
  margin-bottom: 40px;
  position: relative; }

.contact-bg__inner {
  position: absolute;
  top: 0;
  background-repeat: no-repeat;
  background-size: contain; }
  .contact-bg__inner:after {
    content: '';
    position: absolute;
    top: 0;
    background-repeat: no-repeat;
    background-size: contain;
    transform: scaleX(-1); }

.contact-bg--right {
  display: none; }
  .contact-bg--right .contact-bg__inner {
    height: 180px;
    width: 690px;
    left: 0;
    background-image: url("../images/bg-contact-1.png"); }
    .contact-bg--right .contact-bg__inner:after {
      background-image: url("../images/bg-contact-1.png");
      height: 180px;
      width: 690px;
      left: 100%; }
  @media (min-width: 768px) {
    .contact-bg--right {
      display: block; } }

.contact-bg--left {
  margin-right: 40px; }
  .contact-bg--left .contact-bg__inner {
    height: 180px;
    width: 717px;
    right: 0;
    background-image: url("../images/bg-contact-2.png"); }
    .contact-bg--left .contact-bg__inner:after {
      background-image: url("../images/bg-contact-2.png");
      height: 180px;
      width: 717px;
      right: 100%; }
  @media (min-width: 768px) {
    .contact-bg--left {
      margin-right: 0; } }

.landing .main {
  padding: 0; }

.landing h1 {
  color: #fff;
  font-family: 'CutOutJams2', Helvetica, Arial, Verdana, serif;
  font-size: 60px;
  font-size: 6rem;
  line-height: 0.85em;
  text-align: left;
  max-width: 265px;
  margin-left: auto;
  margin-right: auto;
  letter-spacing: 6px; }

.landing .logo-single {
  fill: #fff;
  width: 40px;
  height: 40px;
  margin-right: 6px; }

@media (min-width: 768px) {
  .landing h1 {
    font-size: 82px;
    font-size: 8.2rem;
    max-width: 340px; }
  .landing .logo-single {
    width: 55px;
    height: 55px; } }

.landing__bg {
  min-height: 100vh;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding: 60px 0;
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative; }
  @media (min-width: 768px) {
    .landing__bg {
      padding: 125px 100px; } }

.landing__logo {
  margin-bottom: 40px;
  display: inline-block; }
  .landing__logo .logo {
    width: 79px;
    height: 100px;
    fill: #fff; }
  @media (min-width: 768px) {
    .landing__logo {
      margin-bottom: 80px; }
      .landing__logo .logo {
        width: 119px;
        height: 150px; } }

.landing__shape {
  position: absolute; }
  .landing__shape img {
    position: absolute; }

.landing__shape--top,
.landing__shape--bottom {
  padding-bottom: 3.472222222%;
  height: 0;
  left: -1%;
  right: -1%; }
  .landing__shape--top img,
  .landing__shape--bottom img {
    right: 0;
    left: 0; }

.landing__shape--top {
  top: -1px; }
  .landing__shape--top img {
    top: 0; }

.landing__shape--bottom {
  bottom: -1px; }
  .landing__shape--bottom img {
    bottom: 0; }

.landing__shape--right,
.landing__shape--left {
  top: 0;
  bottom: 0;
  width: 100px;
  display: none; }
  .landing__shape--right img,
  .landing__shape--left img {
    top: 0;
    height: 100%; }
  @media (min-width: 768px) {
    .landing__shape--right,
    .landing__shape--left {
      display: block; } }

.landing__shape--right {
  right: 0; }
  .landing__shape--right img {
    right: 0; }

.landing__shape--left {
  left: 0; }
  .landing__shape--left img {
    left: 0; }

@media (max-width: 766px) {
  .about #content {
    padding-bottom: 0; }
    .about #content .content-section__image {
      margin-bottom: 20px; } }

.grid__item--bottom {
  display: flex;
  align-items: flex-end; }

.grid__item--center {
  display: flex;
  justify-content: center; }

.title--color-accent {
  font-size: 1.5em;
  font-size: 2.5em; }
  @media screen and (min-width: 480px) {
    .title--color-accent {
      font-size: calc(1.5em + 1 * (100vw - 480px) / 544); } }
  @media screen and (min-width: 1024px) {
    .title--color-accent {
      font-size: 2.5em; } }

.little-miracle h1:first-of-type {
  margin-bottom: 60px; }

@media (min-width: 768px) {
  .little-miracle h3 {
    margin-top: 30px; } }

.little-miracle ol {
  counter-reset: item; }
  .little-miracle ol li {
    display: block; }
    .little-miracle ol li:before {
      content: counter(item) ". ";
      counter-increment: item;
      font-weight: 700;
      font-family: 'Roboto Slab', Helvetica, Arial, Verdana, serif; }

@media (min-width: 1680px) {
  .little-miracle .hero--small {
    height: 800px; } }

@media (min-width: 768px) {
  .little-miracle .main-nav ul li:nth-child(1):after {
    content: '';
    height: 14px;
    width: 200px;
    display: inline-block;
    transition: 0.25s cubic-bezier(0, 0, 0.58, 1); } }

.little-miracle .grid__item--bottom .title--color-accent {
  margin: 30px 0; }
  @media (min-width: 768px) {
    .little-miracle .grid__item--bottom .title--color-accent {
      margin: 0;
      margin-bottom: 60px; } }

.little-miracle .container .grid:nth-child(n+2), .little-miracle .container .grid--rev:nth-child(n+2), .little-miracle .container .grid--full:nth-child(n+2), .little-miracle .container .grid--half:nth-child(n+2), .little-miracle .container .grid--x2:nth-child(n+2) {
  margin-top: 10px; }
  @media (min-width: 768px) {
    .little-miracle .container .grid:nth-child(n+2), .little-miracle .container .grid--rev:nth-child(n+2), .little-miracle .container .grid--full:nth-child(n+2), .little-miracle .container .grid--half:nth-child(n+2), .little-miracle .container .grid--x2:nth-child(n+2) {
      margin-top: 60px; } }

.little-miracle .grid__item--overlap {
  z-index: 1; }

.little-miracle .arrow-label, .little-miracle .giveaway__title .subtitle, .giveaway__title .little-miracle .subtitle {
  font-size: .7em; }
  @media (min-width: 768px) {
    .little-miracle .arrow-label, .little-miracle .giveaway__title .subtitle, .giveaway__title .little-miracle .subtitle {
      font-family: 'Roboto Slab', Helvetica, Arial, Verdana, serif;
      font-weight: 700;
      font-size: 100%; } }

.little-miracle .decorated {
  text-transform: none;
  font-size: 55px;
  white-space: normal; }
  @media screen and (min-width: 480px) {
    .little-miracle .decorated {
      font-size: calc(55px + 85 * (100vw - 480px) / 544); } }
  @media screen and (min-width: 1024px) {
    .little-miracle .decorated {
      font-size: 140px; } }
  .little-miracle .decorated:nth-child(2) {
    display: none; }
  @media (min-width: 768px) {
    .little-miracle .decorated:nth-child(2) {
      display: block;
      position: relative;
      bottom: -100px;
      margin-top: -100px; }
    .little-miracle .decorated:nth-child(1) {
      position: relative;
      bottom: -20px;
      margin-top: -20px; } }

.little-miracle .equal-h > .grid__item--seperated:first-child {
  position: relative; }
  .little-miracle .equal-h > .grid__item--seperated:first-child:after {
    content: '';
    position: absolute;
    height: 80%;
    width: 1px;
    right: -20px;
    top: 0;
    background-image: linear-gradient(#b3001b 33%, rgba(255, 255, 255, 0) 0%);
    background-position: right;
    background-size: 3px 13px;
    background-repeat: repeat-y;
    width: 1px; }

.little-miracle--success .button {
  margin: 0 10px; }

.little-miracle--success .decorated {
  margin-bottom: 60px; }
  @media (min-width: 768px) {
    .little-miracle--success .decorated {
      margin-bottom: 100px; } }

.arizona .upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block; }

.arizona .btn {
  border: 1px solid #b3001b;
  cursor: pointer;
  color: #b3001b;
  background-color: white;
  padding: 6px 14px;
  border-radius: 5px;
  height: 40px;
  font-size: 14px;
  font-size: 1.4rem; }

.arizona .upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer; }

.arizona .form__terms-conditions {
  margin-bottom: 0; }

.arizona .hero--small {
  height: 150px;
  padding-bottom: 30%; }

@media (min-width: 480px) {
  .arizona .hero--small {
    height: 150px; } }

@media (min-width: 768px) {
  .arizona .hero--small {
    height: 300px;
    padding-bottom: 0; } }

@media (min-width: 1024px) {
  .arizona .hero--small {
    height: 400px; } }

@media (min-width: 1680px) {
  .arizona .hero--small {
    height: 650px; } }

.giveaway__banner {
  background-color: #00a1db; }
  .giveaway__banner img {
    height: 75vh;
    object-fit: cover;
    margin: auto;
    display: block; }

/* =============================================================================
   PRINT
   Inlined to avoid required HTTP connection: h5bp.com/r
   ========================================================================== */
@media print {
  @page {
    margin: 2cm 0.5cm 1cm; }
  html {
    font-size: 10px; }
  * {
    background-color: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important; }
  .ir {
    text-indent: 0;
    overflow: visible;
    direction: inherit; }
  a,
  a:visited {
    text-decoration: underline; }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  .page-footer,
  .main-nav {
    display: none; }
  .medium--one-half {
    width: 50%; }
  .medium--two-thirds {
    width: 66.66%; }
  .medium--one-third {
    width: 33.33%; }
  .medium--three-quarters {
    width: 75%; }
  .medium--one-quarter {
    width: 25%; } }

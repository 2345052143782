.hero--giveaway {
  height: auto !important;
  min-height: 100vh;

  .hero__background {
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      opacity: 0.3;
    }
  }

  .hero__background--image {
    opacity: 0.3;
  }

  @media #{$maxMedium} {
    padding-bottom: unset !important;
  }
}

.giveaway__title {
  margin-bottom: 30px;

  h1 {
    @include fluid-type(font-size, $mediumSize, $largeSize, 18px, 23px);
    line-height: 1.5em;
    padding: 70px 0 0;
    text-transform: uppercase;
    margin-bottom: 0
  }

  .subtitle {
    @extend .arrow-label;
    @include fluid-type(font-size, $mediumSize, $largeSize, 14px, 16px);
    margin-top: 0.5em;
  }
}

.giveaway__content-wrapper {
  .content-section {
    overflow: visible;
  }

  @media #{$medium} {
    height: 100%;
    display: flex;
    align-items: center;
  }
}

.giveaway__info {
  font-size: 15px;
  max-width: 54ch;
}

.giveaway__logos {
  display: flex;
  align-items: baseline;
  gap: 30px;
}

.giveaway__content {
  position: relative;

  .button,
  input[type="submit"] {
    background-color: $accent;
    color: $white;
  }
}

.js-giveaway-step-2,
.js-giveaway-step-3 {
  display: none;
}

.form__info {
  @include fluid-type(font-size, $mediumSize, $largeSize, 12px, 14px);
  color: $accentHover;
}

.giveaway__active-o2-visual {
  display: none;

  @media #{$medium} {
    display: block;
    position: absolute;
    top: -30px;
    right: -180px;
    width: 200px;
    height: auto;
    transform: rotate(15deg);
    z-index: -1;
  }
}


/*============================================================================
  TYPOGRAPHY
==============================================================================*/

@font-face {
  font-family: 'CutOutJams2';
  src: url('../fonts/cutoutjams2-regular-webfont.woff2') format('woff2'),
  url('../fonts/cutoutjams2-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

//Light 300
//Regular 400
//Bold 700

// Font Stacks
@mixin default-font($size: 16, $weight: 400, $lineHeight: 1.5em) {
  font-family: 'Roboto', Helvetica, Arial, Verdana, sans-serif;
  @include fs($size);
  line-height: $lineHeight;
  font-weight: $weight;
}

@mixin display-font() {
  font-family: 'Roboto Slab', Helvetica, Arial, Verdana, serif;
  font-weight: 700;
}

@mixin roboto() {
  font-family: 'Roboto', Helvetica, Arial, Verdana, sans-serif;
}

@mixin roboto-mono() {
  font-family: 'Roboto Mono', Helvetica, Arial, Verdana, monospace;
}

@mixin roboto-slab() {
  font-family: 'Roboto Slab', Helvetica, Arial, Verdana, serif;
}

@mixin cut-out-jams() {
  font-family: 'CutOutJams2', Helvetica, Arial, Verdana, serif;
}



// Body

p {
  line-height: 1.8em;
  margin-bottom: 1.8em;
}

.text-huge {
  @include display-font;
  @include fluid-type(font-size, $mediumSize, $largerSize, 22px, 44px);
  margin-bottom: 0.2em;
  white-space: pre-line;
  line-height: 0;

  a {
    white-space: nowrap;
    line-height: 1.2em;
  }
}

strong {
  font-weight: 600;
  font-size: inherit;
}

em {
  font-style: italic;
  font-weight: inherit;
}

a, .link {
  font-weight: inherit; font-style: inherit;
  color: $linkColor;
  transition: $transitionDefault;
  text-decoration: underline;
  text-decoration-skip: ink;
  // @include smart-underline; cross browser text-decoration skip

  &:hover, &:focus {
    color: $linkColorHover;
  }

  &:focus, &:active {
    outline: 0;
  }

  &.tdn {
    text-decoration: none;

    &:hover, &:focus {
      text-decoration: underline;
    }
  }
}

button.link {
  @include display-font;
  text-decoration: none;
  @include fs(18);

  span {
    text-decoration: underline;
  }
}

.link--back {
  text-decoration: none;
  @include display-font;

  span {
    text-decoration: underline;
  }
}

a:not([href]) {
  color: $black;
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
  display: inline-block;
  vertical-align: middle;
}


// Headings

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  @include display-font;
  @include optimize-font;
  color: $accent;
  line-height: 1.2em;
  margin: 0 0 0.8em;

  a {
    text-decoration: none;
    outline: 0;
    font-weight: inherit;
    color: $accent;

    &:hover, &:focus {
      color: $accentHover;
    }
  }

  strong {
    color: $orange;
  }
}

h1, .h1 {
  @include fluid-type(font-size, $mediumSize, $largerSize, 25px, 50px);
}

h2, .h2 {
  @include fluid-type(font-size, $mediumSize, $largerSize, 25px, 50px);
  margin: 0 0 1.4em;
}

h3, .h3 {
  @include fluid-type(font-size, $mediumSize, $largeSize, 16px, 18px);
  margin: 0 0 1em;
}

h4, .h4 {
  @include fs(16);
}

h5, .h5 {  }

h6, .h6 {  }

.decorated {
  @include cut-out-jams;
  @include fluid-type(font-size, $smallSize, $largeSize, 55px, 236px);
  color: $accent;
  text-transform: lowercase;
  margin: 0;
  line-height: 0.8em;
  font-weight: 400;
  white-space: nowrap;

  a {
    text-decoration: none;
  }
}

.decorated--big {
  margin-bottom: 45px;

  @media #{$medium} {
    margin-bottom: 90px;
    margin-top: -40px;
  }
}

.decorated--small {
  @include fluid-type(font-size, $smallSize, $largeSize, 55px, 200px);
}

.decorated--left {
  @media #{$medium} {
    transform: translateX(60px);

    + h2, + .h2 {
      text-align: right;
    }
  }

  @media #{$large} {
    + h2, + .h2 {
      margin-right: 17%;
    }
  }
}

.decorated--right {
  @media #{$medium} {
    transform: translateX(-200px);
  }
}



// Lists

ul, ol {
  margin: 0 0 1.5em 16px;

  li {
    line-height: 1.5em;
    padding: 0;
  }
}

ul ul, ul ol, ol ol, ol ul {
  margin-bottom: 0;
}

ul {
  list-style: none;

  &.lined {
    @extend .unstyled;

    li {
      border-bottom: 1px solid #E3E3E3; padding: 5px 0;

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0;
        border-bottom: none;
      }
    }
  }
}

.rte ul, ul.styled {
  li {
    line-height: 1.8em;
    position: relative;
    font-weight: 400;

    &:before {
      position: absolute;
      content: '>';
      @include cut-out-jams;
      @include fs(15);
      left: -15px;
      font-weight: 300;
    }
  }
}

ol {
  list-style: decimal;
}

.unstyled, nav ul {
  margin: 0 0 1.5em;
  padding: 0;
  list-style: none;

  ul {
    list-style: none;
  }

  li {
    list-style: none;
    padding: 0;
    background: none;
  }
}

nav ul {
  @include clearfix;
}

dl {
  margin: 0 0 1.5em;
}

dt, dd {
  line-height: 1.5em;
}

dt {
  font-weight: 600;
}

dd {
  margin-bottom: 0.5em;
}


// Misc

hr {
  display: block;
  height: 1px;
  border: 0;
  margin: 40px 0;
  position: relative;

  @media #{$medium} {
    margin: 75px 0;
  }

  &:not(.ninja) {
    @include dashed-horizontal;
  }

  &.ninja {
    border-color: transparent;
    margin: 50px 0;
  }
}

blockquote {
  line-height: inherit;
  padding-left: 20px;
  border-left: 1px solid $gray;
  margin-bottom: 1.5em;
  @include fs(20);
  font-style: italic;

  p {
    margin-bottom: 0.5em;
  }

  cite {
    @include fs(14);
  }

  @media #{$medium} {
    padding-left: 40px;
  }
}

// Rich text editor

.rte {
  p {
    strong {
      @include display-font;
      color: $accent;
      @include fs(18);
    }
  }

  a, .link {
    @include display-font;
  }

  .link {
    text-decoration: none;
    @include fs(18);

    span {
      text-decoration: underline;
    }
  }

  small {
    @include fs(14);
  }
}




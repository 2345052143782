/*============================================================================
  #General Variables
==============================================================================*/
$siteWidth: 1600px;

$borderColor: #e5e5e5;
$accent: #b3001b;


/*============================================================================
  #General Variables
==============================================================================*/
.b2b-shop {
  padding: 0 20px;
  margin: 0 auto 40px;
  position: relative;
  max-width: $siteWidth;

  &.b2b-shop--preview-mode {
    .b2b-cart__line-items {
      display: none;
    }
    .b2b-shop__filters {
      display: none;
    }
  }

  a {
    text-decoration: underline;
  }

  @media #{$medium} {
    padding: 0 40px;
  }
}

/* osborne overrides */
.b2b-shop {
  .row {
    padding: 0;
    margin-left: -1.25em;
    margin-right: -1.25em;
    width: auto;
    max-width: inherit;
  }

  .divider {
    margin-bottom: 40px;
  }

  .breadcrumbs a {
    text-decoration: none;
  }

  .tags-table ul li {
    min-width: 100px;
    margin: 0 5px 20px;

    a {
      padding: 55px 25px 15px 25px;
      text-decoration: none;
    }
  }
}

.b2b-shop__header {
  text-align: center;
  margin-bottom: 20px;
  margin-top: 40px;

  @media #{$medium} {
    margin-bottom: 40px;
  }
}

.b2b-shop__title {
  margin-bottom: 0.4em;
}

.b2b-shop__header-body {
  margin-bottom: 20px;

  @media #{$medium} {
    margin-bottom: 50px;
  }
}

.b2b__vh {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px; width: 1px;
  margin: -1px; padding: 0; border: 0;
}

.b2b-shop__grid {
  position: relative;
  @include clearfix;
  margin: 40px 0 40px -40px;

  @media #{$medium} {
    margin-top: 60px;
  }
}

.b2b-shop__content,
.b2b-shop__sidebar {
  padding-left: 40px;
  float: left;
  width: 100%;
}

.b2b-shop__content {
  @media #{$medium} {
    width: 50%;
  }
}

.b2b-shop__sidebar {
  @media #{$medium} {
    width: 50%;
  }
}

.b2b-tags-row {
  margin-bottom: 20px;

  @media #{$medium} {
    margin-bottom: 40px;
    text-align: center;
  }
}

.b2b-tags-row__list {
  list-style: none;
  margin: 0 0 15px;
  padding: 0;

  li {
    display: inline-block;
    margin: 0 10px;
  }

  a {
    text-decoration: none;

    &:hover, &:focus {
      text-decoration: underline;
    }
  }

  @media #{$medium} {
    display: inline-block;

    + .b2b-tags-row__list {
      margin-left: 30px;
      padding: 0 0 0 30px;
      border-left: 1px solid #555;
    }
  }
}

.b2b-tags-row__list, .b2b-shop .tags-table {
  .active a {
    text-decoration: underline;
    position: relative;
    padding-right: 30px;

    &:before, &:after {
      content: ""; display: block;
      position: absolute;
      right: 0;
      top: 2px;
      width: 20px;
      height: 20px;
      transition: 0.3;
    }

    &:before {
      border: 1px solid #000;
      border-radius: 100%;
    }

    &:after {
      content: "\2715";
      font-size: 12px;
      text-align: center;
      line-height: 22px;
    }

    &:hover, &:focus {
      &:before, &:after {
        opacity: 0.7;
      }
    }
  }
}

.b2b-shop .tags-table {
  .active a {
    text-decoration: none;

    &:before, &:after {
      right: -10px;
      top: -10px;
    }
  }
}


.b2b-form {
  margin: 0 0 40px;
}

.b2b-form--preview-mode {
  .b2b-form__list-item:not(.b2b-form__list-item--active) {
    display: none;
  }

  .b2b-form__list-item--active .b2b-form__clear {
    visibility: hidden;
  }

  .b2b-form__select {
    pointer-events: none;
    cursor: auto;
    background: #fff;
  }
}

.b2b-form__list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.b2b-form__list-item {
  @include clearfix;
  border-bottom: 1px solid $borderColor;
  padding: 10px 10px 0;

  @media #{$medium} {
    display: flex;
  }
}

.b2b-form__product {

  @media #{$medium} {
    float: left;
    width: 100px;
    display: flex;
  }

  @media #{$huge} {
    width: 100px;
  }
}

.b2b-form__product-image {
  display: inline-block;
  margin-right: 15px;
  img {
    width: 50px;
  }
}

.b2b-form__variants {
  @include clearfix;
  list-style: none;
  padding: 0;
  margin: 0;
  float: left;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 10px;
  @media #{$medium} {
    flex: 1;
  }
}

.b2b-form__variants-item {
  float: left;
  margin-left: 15px;
  margin-bottom: 10px;
}

.b2b-form__label {
  margin-bottom: 5px;
  font-size: 14px;
}

.b2b-form__select {
  width: 90px;
  font-size: 14px;
  margin: 0;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: inner-spin-button !important;
    opacity: 1;
    margin-left: 10px;
  }

  &:disabled {
    background-color: #efefef;
  }
}

.b2b-form__actions {
  margin: 0;
  display: flex;
  margin-top: 15px;

  @media #{$medium} {
    margin-top: 28px;
    margin-left: 20px;
  }
}

.b2b-form__product-text {
  text-align: left;
}

.b2b-form__product-title {
  min-width: 200px;
  max-width: 300px;
}

.b2b-form__product-quantity {
  width: 33px; height: 33px;
  background-color: #efefef;
  border-radius: 100%;
  text-align: center;
  line-height: 33px;
  display: block;
  margin-right: 15px;
  font-size: 14px;
  border: 1px solid #efefef;
  visibility: hidden;
  display: none;
}

.b2b-form__list-item--active {
  .b2b-form__product-quantity {
    background-color: #fff;
    border-color: #e5e5e5;
    visibility: visible;
  }

  .b2b-form__clear {
    visibility: visible;
  }
}

.b2b-form__clear {
  background-color: #fff;
  width: auto;
  padding: 0;
  width: 20px;
  height: 20px;
  position: relative;
  display: block;
  margin: 6px 0 0 6px;
  visibility: hidden;

  &:before, &:after {
    content: ""; display: block;
    position: absolute; top: 2px; left: 2px;
    border: 1px solid #000;
    height: 16px; width: 1px;
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }

  &:hover, &:focus {
    background-color: #fff;
    opacity: 0.6;
  }
}

.b2b-pagination {
  margin-bottom: 40px;

  > span {
    display: inline-block;
    line-height: 1;
  }

  a {
    display: block;
  }

  a,
  .page.current {
    padding: 8px;
  }
}

.b2b-cart {
  border: 1px solid $borderColor;
  padding: 15px;
  font-size: 14px;

  label {
    font-size: 14px;
  }

  textarea, select {
    margin-bottom: 0;
  }

  textarea {
    height: 70px;
  }

  p {
    margin-bottom: 1.5em;
  }

}

.b2b-cart__form {
  position: relative;

  &:before, &:after {
    content: "";
    display: block;
    position: absolute;
    transition: 0.3s;
    visibility: hidden;
    opacity: 0;
    z-index: 100;
  }

  &:before {
    top: 0; left: 0; right: 0; bottom: 0;
    background-color: rgba(255,255,255,0.5);
  }

  &:after {
    width: 50px; height: 50px;
    bottom: 50px; left: 50%;
    margin: -25px 0 0 -25px;
    background: url("../images/b2b-loading-spinner.svg") top left no-repeat;
    background-size: cover;
  }
}

.b2b-cart__form--is-loading {
  &:before, &:after {
    opacity: 1;
    visibility: visible;
  }
}

.b2b-shop__error {
  padding: 10px;
  background-color: #fdf3f0;
  color: #bf0711;
  margin-bottom: 15px;
}

.b2b-cart__line-items {
  list-style: none;
  margin: 0 0 20px;
  padding: 0;

  li {
    border-bottom: 1px solid $borderColor;
    padding-bottom: 5px;
    margin-bottom: 5px;
    display: flex;
  }
}

.b2b-cart__line-items-quantity {
  min-width: 20px;
}

.b2b-cart__submit,
.b2b-cart__preview {
  width: 100%;
  text-align: center;
  margin: 0;
  text-decoration: none !important;
}

.b2b-cart__back-to-edit {
  text-align: center;
  margin: 20px 0 0;
}

.js-b2b-preview-mode {
  display: none;
}

.b2b-cart__info {
  font-style: italic;
  font-size: 12px;
  margin: 20px 0 0;

  p {
    margin: 0;
  }
}

.fancybox-button[disabled] {
  div {
    background: rgb(30,30,30);
  }
}

.b2b-cart__label{
  float: left;
  font-weight: bolder;
}

.b2b-form__brand-title {
  font-size: 18px;
  margin-top: 20px;

}

.b2b-form__packaging-title {
  margin-top: 10px;
  font-size: 14px;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 20px;
}

/**
 * OVERVIEW
 *
 * IMPORTS
 * reset, vars, mixins, core, plugins
 *
 * LAYOUT
 * Helpers
 * Header / Main / Footer
 * Components
 * Pages (page overrides)
 *
 * Hooks (IE, ...)
 * Print
 */

/**
 * GUIDELINES
 * CLASSES
 * Naming: BEM - http://getbem.com/
 * Abbreviations: Emmet - http://docs.emmet.io/cheat-sheet/ | example: .va-m
 *
 * MEDIAQUERIES
 * @media $small, $medium, $maxMedium, $large, $larger, $huge, $massive
 */

/* =============================================================================
   IMPORTS
   ========================================================================== */

// CSS Reset
@import "inc/reset";

// Core
@import "inc/vars";
@import "inc/mixins";
@import "inc/animations";
//@import "inc/custom-animations";

// Styled patterns and elements
@import "inc/grid";
@import "inc/type";
@import "inc/tables";
@import "inc/forms";

// Plugins
@import "plugins/validation-engine";
@import "plugins/modaal";

// Pages
@import "pages/little-miracle";
@import "pages/arizona";
@import "pages/giveaway";
@import "pages/b2b-shop";


/* =============================================================================
   LAYOUT
   ========================================================================== */

html {
  font-size: 62.5%;
}

body {
  @include default-font;
  color: $black;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: rgba(0, 0, 0, 0);
}

.content-editable {
  background-color: rgba(113, 255, 189, 0.3);

  &:focus {
    background-color: inherit;
  }
}

.page-wrap {
  position: relative;

  @media #{$medium} {
    min-height: 100vh;
    z-index: 2;
  }
}

.container {
  position: relative;
  max-width: 1160px;
  margin: 0 auto;
  padding: 0 20px;
  width: 100%;

  @media #{$large} {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.container--small {
  max-width: 620px;
}

.container--medium {
  max-width: 940px;
}

.container--large {
  max-width: 1385px;
}

.container--oh {
  overflow: hidden;
}


// Typekit async loading (all elements using $ typekit font)
.wf-loading {
  overflow: hidden;

  body {
    visibility: hidden;
  }
}

::selection {
  background-color: $selection;
  color: inherit;
}


/* =============================================================================
   HELPERS
   ========================================================================== */

// Icons
.icon {
  display: inline-block;
  vertical-align: middle;
}

.icon-hamburger,
.icon-hamburger:before,
.icon-hamburger:after {
  width: 20px;
  position: absolute;
  left: 0;
  height: 1px;
  border-top: 2px solid $accent;
  transition: $transitionDefault;
}

.icon-hamburger {
  position: relative;

  &:before,
  &:after {
    content: "";
    margin-left: 0;
  }

  &:before {
    margin-top: 3px;
  }

  &:after {
    margin-top: 8px;
  }
}

.icon-flag {
  width: 9px;
  height: 9px;
}

.icon-arrow-left {
  width: 40px;
  height: 33px;
}

.icon-arrow-right {
  width: 31px;
  height: 38px;
}

.icon-chevron-right {
  &:before {
    content: '>';
    @include cut-out-jams;
    @include fs(15);
    font-weight: 300;
    font-style: normal;
  }
}

.icon-chevron-left {
  @extend .icon-chevron-right;

  &:before {
    content: '<';
  }
}

// Exceptions: only add what you need!
// .mb0.mb0 { margin-bottom: 0; }
.mb10.mb10 {
  margin-bottom: 10px;
}

.mb50.mb50 {
  margin-bottom: 25px;

  @media #{$medium} {
    margin-bottom: 50px;
  }
}

.mb100.mb100 {
  margin-bottom: 50px;

  @media #{$medium} {
    margin-bottom: 100px;
  }
}

// .mt0.mt0 { margin-top: 0; }
// .mt20.mt20 { margin-top: 20px; }
// .mt40.mt40 { margin-top: 40px; }

.ttu {
  text-transform: uppercase;
}

.ttl {
  text-transform: lowercase;
}

.no-wrap {
  white-space: normal;
}

.rounded {
  border-radius: 20px;
}

// Google maps mw
.gm-style img {
  max-width: inherit;
}

// Responsive video
.video-container {
  position: relative;
  padding: 0 0 56.25%;
  height: 0;
  overflow: hidden;
  margin: 0 0 15px;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

// Equal height
.equal-h {
  display: flex;
  // overflow: hidden;
  flex-wrap: wrap;

  >* {
    display: flex;
  }

  &:before,
  &:after {
    display: none;
  }
}

html.no-flexbox {
  .equal-h {
    height: 100%;

    >div {
      float: left;
    }
  }
}

// Vertical align (not recommended)
// .va-wrap, .va-m, .va-b { display: table; width: 100%; height: 100%; }
// .va-wrap { table-layout: fixed; }
// .va-m, .va-b { display: table-cell; }
// .va-m { vertical-align: middle; }
// .va-b { vertical-align: bottom; }

// Block link
.block-link {
  position: relative;
  cursor: pointer;
}

.block-link__target {
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

// Global accent colors
@mixin accent-colors($color:$accent) {

  a,
  .link,
  .decorated,
  .rte p strong,
  .tag,
  .product-category__title,
  .product-item__title,
  .main-nav a,
  .language__trigger,
  .language__wrap li a,
  .hero__scroll-button,
  .hero__link-button,
  a:hover,
  a:focus,
  li.active a,
  .main-nav a:hover,
  .main-nav a:focus,
  .main-nav li.active a,
  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6,
  .arrow-label,
  input[type="text"],
  input[type="search"],
  input[type="password"],
  input[type="email"],
  input[type="tel"],
  input[type="date"],
  input[type="number"],
  textarea,
  select,
  input[type="reset"],
  input[type="submit"],
  .button,
  ::placeholder,
  .product-order.product-order .contact-block.contact-block a,
  .main-nav__trigger,
  .brand-navigation span {
    color: $color;
  }

  .main-nav a:hover,
  .main-nav a:focus,
  .main-nav li.active a {
    &:not(.no-line) {
      &:after {
        box-shadow: inset 0px 2px 0px 0px $color;
      }
    }
  }

  .product-sheet__button .button--download {
    background-color: $color;
  }

  .hero__scroll-button .icon,
  .hero__link-button .icon,
  .logo,
  .social__link,
  .arrow-label .icon,
  .brand-navigation .icon {
    fill: $color;
  }

  .tag,
  .language__wrap,
  .language__wrap:before,
  .language__wrap:after,
  .language__arrow,
  input[type="text"],
  input[type="search"],
  input[type="password"],
  input[type="email"],
  input[type="tel"],
  input[type="date"],
  input[type="number"],
  textarea,
  select,
  input[type="reset"],
  input[type="submit"],
  .button,
  .checkbox label:before,
  .checkbox label:after,
  .radio label:before,
  .radio label:after,
  .checkbox input[type="checkbox"]:checked+label:before,
  .checkbox input[type="radio"]:checked+label:before,
  .radio input[type="checkbox"]:checked+label:before,
  .radio input[type="radio"]:checked+label:before,
  .icon-hamburger,
  .icon-hamburger:before,
  .icon-hamburger:after {
    border-color: $color;
  }

  .product-category {
    border-color: rgba($color, 0.15);
  }

  .product-header .decorated {
    color: rgba($color, 0.15);
  }
}

.global-color-white {
  @include accent-colors($white);

  .language__wrap,
  .language__arrow {
    background-color: transparent;

    .menu-hidden & {
      background-color: $white !important;
    }
  }

  &.menu-hidden {

    .language__wrap,
    .language__arrow {
      background-color: $white;
      border-color: $accent;

      &:before,
      &:after {
        border-color: $accent;
      }

      a {
        color: $accent;
      }
    }
  }
}

.global-color-red {
  @include accent-colors($red);
}

.global-color-raspberry {
  @include accent-colors($raspberry);
}

.global-color-orange {
  @include accent-colors($orange);
}

.global-color-lemon {
  @include accent-colors($lemon);
}

.global-color-yellow {
  @include accent-colors($yellow);
}

.global-color-pink {
  @include accent-colors($pink);
}

.global-color-eggplant {
  @include accent-colors($eggplant);
}

.global-color-purple {
  @include accent-colors($purple);
}

.global-color-blue {
  @include accent-colors($blue);
}

.global-color-sky {
  @include accent-colors($sky);
}

.global-color-mint {
  @include accent-colors($mint);
}

.global-color-jade {
  @include accent-colors($jade);
}

.global-color-lime {
  @include accent-colors($lime);
}

.global-color-green {
  @include accent-colors($green);
}

.global-color-forest {
  @include accent-colors($forest);
}

.global-color-brown {
  @include accent-colors($brown);
}


// Individual accent colors
.color-red,
.color-red a {
  color: $red;
}

.color-raspberry,
.color-raspberry a {
  color: $raspberry;
}

.color-orange,
.color-orange a {
  color: $orange;
}

.color-lemon,
.color-lemon a {
  color: $lemon;
}

.color-yellow,
.color-yellow a {
  color: $yellow;
}

.color-pink,
.color-pink a {
  color: $pink;
}

.color-eggplant,
.color-eggplant a {
  color: $eggplant;
}

.color-purple,
.color-purple a {
  color: $purple;
}

.color-blue,
.color-blue a {
  color: $blue;
}

.color-sky,
.color-sky a {
  color: $sky;
}

.color-mint,
.color-mint a {
  color: $mint;
}

.color-jade,
.color-jade a {
  color: $jade;
}

.color-lime,
.color-lime a {
  color: $lime;
}

.color-green,
.color-green a {
  color: $green;
}

.color-forest,
.color-forest a {
  color: $forest;
}

.color-brown,
.color-brown a {
  color: $brown;
}

.color-white,
.color-white a {
  color: $white;
}

/* =============================================================================
   HEADER
   ========================================================================== */

.page-header {
  @include clearfix;
  height: 80px;
  position: fixed;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  z-index: 999;
  transition: $transitionDefault;
  display: block;

  .container {
    height: 100%
  }

  @media #{$maxMedium} {
    &.menu-hidden {
      background-color: $white;
      box-shadow: 0px 0px 25px 0px rgba(67, 68, 104, 0.05);

      &.global-color-white {
        .logo {
          fill: $accent;
        }
      }
    }
  }

  @media #{$medium} {
    height: 145px;

    &.small {
      height: 100px;
    }

    &.menu-hidden {

      &:hover,
      &:focus {
        background-color: $white;
        box-shadow: 0px 0px 25px 0px rgba(67, 68, 104, 0.05);

        &.global-color-white {
          .logo {
            fill: $accent;
          }
        }

        .main-nav,
        .language {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}

.page-header__logo {
  text-decoration: none;
  @include opacity(100);
  display: inline-block;
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  pointer-events: auto;

  img {
    display: block;
  }

  &:hover,
  &:focus {
    @include opacity(80);
  }

  .mobile-menu & {
    position: absolute;
    padding: 0;
  }

  @media #{$medium} {
    top: 30px;

    .small & {
      top: 10px;
    }
  }
}

.logo {
  width: 48px;
  height: 60px;
  fill: $accent;
  transition: $transitionDefault;

  @media #{$medium} {
    width: 60px;
    height: 75px;

    .small & {
      width: 60px;
      height: 75px;
    }
  }

  @media #{$large} {
    width: 80px;
    height: 100px;

    .small & {
      width: 60px;
      height: 75px;
    }
  }
}

.main-nav {
  text-transform: uppercase;
  @include optimize-font;
  color: $black;
  margin: 0;
  @include roboto-mono;
  font-weight: 700;
  @include fs(14);
  letter-spacing: 1px;

  a {
    text-decoration: none;
    color: $accent;
    padding: 5px 15px;
    display: block;
    pointer-events: auto;
    position: relative;

    &:after {
      height: 2px;
      content: '';
      position: absolute;
      bottom: 0;
      left: 15px;
      right: 15px;
      transition: $transitionDefault;
    }
  }

  a:hover,
  a:focus,
  li.active a {
    color: $accent;

    &:not(.no-line) {
      &:after {
        box-shadow: inset 0px 2px 0px 0px $accent;
      }
    }
  }

  .mobile-menu & {
    @include fs(18);

    li {
      margin-bottom: 15px;
    }
  }

  @media #{$medium} {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    display: block;
    width: 100%;
    text-align: center;
    opacity: 1;
    visibility: visible;
    transition: $transitionDefault;

    .menu-hidden & {
      opacity: 0;
      visibility: hidden;
    }

    li {
      float: left;

      &:nth-child(3):after {
        content: '';
        height: 14px;
        width: 105px;
        display: inline-block;
        transition: $transitionDefault;
      }
    }

    a {
      display: inline-block;
      padding: 5px 5px;

      .menu-hidden.global-color-white & {
        color: $accent;
      }

      &:after {
        left: 5px;
        right: 5px;
      }
    }

    a:hover,
    a:focus,
    li.active a {
      .menu-hidden.global-color-white & {
        color: $accent;

        &:after {
          box-shadow: inset 0px 2px 0px 0px $accent;
        }
      }
    }
  }

  @media #{$large} {
    li {
      &:nth-child(3):after {
        width: 235px;
      }
    }

    a {
      padding: 5px 15px;

      &:after {
        left: 15px;
        right: 15px;
      }
    }
  }
}

.main-nav__notification {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 6px;
    height: 6px;
    top: 1px;
    right: 10px;
    background-color: #FB5A99;
    border-radius: 50%;
  }

  @media #{$medium} {
    &:before {
      right: 0;
    }
  }

  @media #{$large} {
    &:before {
      right: 10px;
    }
  }
}

.main-nav__trigger {
  display: block;
  padding: 12px 52px 12px 20px;
  background-color: transparent;
  color: $accent;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  top: 18px;
  right: 20px;
  pointer-events: auto;
  @include roboto-mono;
  font-weight: 700;
  @include fs(14);
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-right: -20px;

  .mobile-menu & {
    display: none;
  }

  .icon-hamburger {
    position: absolute;
    left: auto;
    right: 20px;
    top: 16px;
  }

  &:hover,
  &:focus {
    background-color: transparent;
    box-shadow: none;
  }

  &:active {
    transform: none;
  }

  @media #{$medium} {
    display: none;
  }
}

.main-nav__wrap {
  display: none;
  margin: 0;
  transition: $transitionDefault;

  .mobile-menu & {
    display: block !important;
    padding: 80px 0 0;
  }

  @media #{$medium} {
    display: inline-block;
  }
}

.language {
  position: relative;
  float: left;
  margin-top: 23px;
  display: block;
  pointer-events: auto;
  transition: $transitionDefault;
  opacity: 1;
  visibility: visible;

  .mobile-menu & {
    display: block;
    float: none;
    margin-top: 0;
  }

  @media #{$medium} {
    margin-top: 52px;
    float: right;

    .menu-hidden & {
      opacity: 0;
      visibility: hidden;
    }

    .small & {
      margin-top: 29px;
    }
  }
}

.language__trigger {
  @include roboto-slab;
  @include fs(14);
  white-space: nowrap;
  color: $accent;
  background-color: transparent;
  padding: 5px 0;

  .icon-flag {
    position: relative;
    top: 1px;
  }

  span {
    vertical-align: middle;
    text-decoration: underline;
    margin-left: 5px;
  }

  @media #{$medium} {
    .menu-hidden.global-color-white & {
      color: $accent;
    }
  }

  @media #{$large} {
    min-width: 80px;
  }
}

.language__dropdown {
  padding-top: 10px;
  position: absolute;
  left: -11px;
  z-index: 999;
  width: 122px;

  .mobile-menu & {
    left: auto;
    right: 50%;
    transform: translateX(50%);
    margin-bottom: 30px;
  }

  @media #{$medium} {
    left: -45px;
  }

  @media #{$large} {
    left: -11px;
  }
}

.language__arrow {
  position: absolute;
  top: -7px;
  left: 42px;
  border-style: solid;
  border-color: $accent;
  border-width: 2px 0 0 2px;
  width: 11px;
  height: 11px;
  transform: rotate(45deg);
  z-index: 0;
  background-color: $white;
}

.language__wrap {
  list-style: none;
  margin: 0;
  border-style: solid;
  border-width: 0 2px 2px;
  border-color: $accent;
  border-top-color: transparent;
  border-radius: 5px;
  @include roboto-mono;
  @include fs(13);
  position: relative;
  display: none;
  background-color: $white;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: -2px;
    height: 15px;
    border-style: solid;
    border-color: $accent;
    z-index: 0;
  }

  &:before {
    right: -2px;
    border-width: 2px 2px 0 0;
    border-top-right-radius: 5px;
    width: 66px;
  }

  &:after {
    left: -2px;
    border-width: 2px 0 0 2px;
    border-top-left-radius: 5px;
    width: 43px;
  }

  li {
    white-space: nowrap;

    a {
      text-decoration: none;
      padding: 5px 9px;
      display: block;
      color: $accent;
      z-index: 2;
      position: relative;

      span {
        vertical-align: middle;
      }
    }

    &:nth-child(2) a {
      padding-top: 10px;
    }

    &:last-child a {
      padding-bottom: 10px;
    }
  }

  .mobile-menu & {
    text-align: left;

    li {
      margin: 0;

      a {
        text-transform: none;
        letter-spacing: 0;
      }
    }
  }
}


// Mobile menu
.mobile-menu {
  .modaal-close {
    border-radius: 0;
    background-color: transparent;

    &:before,
    &:after {
      background-color: $accent;
      border-radius: 3px;
    }
  }
}

.modaal-content-container {
  text-transform: uppercase;
  @include optimize-font;
  color: $black;
  margin: 0;
  @include roboto-mono;
  font-weight: 700;
  @include fs(18);
  letter-spacing: 1px;
  text-align: center;

  li {
    list-style: none;
    margin-bottom: 10px;
  }

  a {
    text-decoration: none;
    color: $accent;
    padding: 5px 15px;
    display: inline-block;
    pointer-events: auto;
    position: relative;

    &:after {
      height: 2px;
      content: '';
      position: absolute;
      bottom: 0;
      left: 15px;
      right: 15px;
      transition: $transitionDefault;
    }
  }

  a:hover,
  a:focus,
  li.active a {
    color: $accent;

    &:not(.no-line) {
      &:after {
        box-shadow: inset 0px 2px 0px 0px $accent;
      }
    }
  }
}

.skip-link {
  left: -999px;
  position: absolute;
  top: auto;
  overflow: hidden;
  z-index: -999;
  white-space: nowrap;

  &:focus {
    top: 0;
    left: 0;
    z-index: 999;
    background-color: #fff;
    padding: 0.5em 1em;
    @include fs(20);
  }
}


/* =============================================================================
   MAIN
   ========================================================================== */

.main {
  @media #{$medium} {
    padding: 0 0 450px;
  }
}


/* =============================================================================
   FOOTER
   ========================================================================== */

.page-footer {
  position: relative;
  color: $accent !important;

  @media #{$medium} {
    height: 450px;
    margin: -450px 0 0;
    z-index: 5;
  }
}

.page-footer__top {
  background-color: $grayLight;
  display: flex;
  align-items: center;
  padding: 20px 0;

  .social {
    margin: 0 0 0 -10px;
  }

  .social__item {
    padding: 0 0 10px 10px;
  }

  .social__link {
    width: 21px;
    height: 21px;
    fill: $accent;

    .icon {
      width: 21px;
      height: 21px;
    }
  }

  .grid__item {
    p:last-child {
      margin-bottom: 0;
    }
  }

  @media #{$medium} {
    height: 400px;

    .container {
      >.grid>.grid__item:first-child {
        position: relative;

        &:after {
          content: '';
          position: absolute;
          height: 100%;
          top: 0;
          right: -20px;
          @include dashed-vertical;
        }
      }
    }
  }
}

.page-footer__block {
  padding: 18px 0;
  width: 100%;

  .h3 {
    color: $accent !important;
  }

  a {
    color: $accent;
    text-decoration: none;

    &:hover &:focus {
      text-decoration: none;
    }
  }

  .page-footer & {
    a {
      color: $accent !important;
    }
  }

  @media #{$medium} {
    padding: 9px 0;
  }
}

.page-footer__bottom {
  background-color: $white;
  font-weight: 300;
  @include fs(14);
  display: flex;
  align-items: center;
  padding: 20px 0;
  text-align: center;

  a {
    text-decoration: underline;
    color: $accent !important;
  }

  ul {
    margin: 0 0 20px;
    @include clearfix;
    display: inline-block;
    padding-top: 1px;

    li {
      float: left;
      position: relative;

      +li {
        margin-left: 15px;

        &:before {
          position: absolute;
          content: '';
          height: 1px;
          width: 5px;
          background-color: $accent !important;
          left: -10px;
          top: calc(50% + 1px);
        }
      }
    }
  }

  @media #{$medium} {
    height: 50px;
    text-align: left;

    ul {
      margin: 0;
    }
  }
}

a.esign {
  width: 58px;
  height: 15px;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  transition: $transitionDefault;
  display: block;
  outline: 0;
  z-index: 9999;
  overflow: hidden;
  margin: 0 auto;

  &:hover,
  &:focus {
    opacity: 1;
  }

  @media #{$medium} {
    width: 11px;
    position: absolute;
    right: 40px;
    top: 6px;
    margin: 0;

    &:hover,
    &:focus {
      width: 58px;

      .esign__logo {
        fill: $accent;
      }
    }
  }
}

.esign__logo {
  width: 58px;
  height: 15px;
  fill: $accent;
}


/* =============================================================================
   COMPONENTS
   ========================================================================== */

// Rte
.large {
  @include fs(20);
}

.center-block {
  @include clearfix;
  text-align: center;
  margin: 20px 0;

  .button {
    float: none;
    display: inline-block;
  }
}


// Article
section {
  position: relative;
}

article {
  @include clearfix;
  margin: 0 0 20px;

  a {
    img {
      display: block;
      @include opacity(100);
    }

    &:hover,
    &:focus {
      img {
        @include opacity(80);
      }
    }
  }
}

.article-detail__image {
  margin-bottom: 1.5em;
}


// Note
.note {
  @include clearfix;
  margin: 0 0 20px;
  padding: 15px 20px;
  background: #efefef;
  border-radius: 5px;

  ul,
  p {
    margin-bottom: 1em;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.note--success {
  color: $success;
  background-color: $successBg;
}

.note--error {
  color: $error;
  background-color: $errorBg;
}

.response {
  @extend .note;
}

#mce-error-response {
  @extend .note--error;
  margin: 10px 0 0 !important;
}

#mce-success-response {
  @extend .note--success;
  margin: 10px 0 0 !important;
}

div.mce_inline_error {
  @extend .note;
  @extend .note--error;
  margin: 10px 0 0 !important;
}


// Arrow label
.arrow-label {
  @include display-font;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  color: $accent;
  position: relative;
  margin-bottom: 45px;
  padding-right: 15px;

  strong {
    color: $orange;
  }

  .icon-arrow-left,
  .icon-arrow-right {
    fill: $accent;
    position: absolute;
    bottom: -45px;
  }

  .icon-arrow-left {
    transform: rotate(337deg);
  }

  .icon-arrow-right {
    transform: rotate(20deg);
    bottom: -47px;
  }
}

.arrow-label--left {
  text-align: left;

  .icon {
    left: 35px;
  }
}

.arrow-label--right {
  text-align: right;

  .icon {
    right: 80px;
  }
}


// Slideshow
.cycle-slideshow {
  width: 100%;
  position: relative;
}

.cycle-pager {
  width: 100%;
  text-align: center;

  span {
    display: inline-block;
    margin: 0 3px;
    font-size: 36px;
    height: 20px;
    width: 20px;
    line-height: 14px;
    cursor: pointer;
    border: 2px solid #fff;
    border-radius: 20px;
    color: #fff;
    overflow: hidden;
    text-indent: -500px;
    transition: $transitionDefault;

    &:hover,
    &:focus {
      transition: none;
    }
  }

  span:hover,
  span:focus,
  .cycle-pager-active {
    text-indent: 0;
  }
}

// Hero
.hero {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 0;
  padding-bottom: 100%;
  position: relative;
  overflow: hidden;

  &:before,
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    height: 0;
  }

  &:before {
    border-top: 1px solid $white;
  }

  &:after {
    padding-bottom: 1.388888888%;
    background-image: url('../images/shape-hero-trim.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  @media #{$medium} {
    height: 100vh;
    padding-bottom: 0;
  }
}

.hero--dark {

  .hero__link-button,
  .hero__link-button span,
  .hero__scroll-button {
    color: $white;

    .icon {
      fill: $white;
    }
  }
}

.hero--small {
  &:after {
    padding-bottom: 1.04166667%;
    background-image: url('../images/shape-hero-trim-product.png');
  }

  @media #{$medium} {
    height: 550px;
    padding-bottom: 0;
  }
}

.hero--medium {
  &:after {
    padding-bottom: 1.04166667%;
    background-image: url('../images/shape-hero-trim-product.png');
  }

  @media #{$medium} {
    height: 60vh;
    padding-bottom: 0;
  }
}

.hero__background {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.hero__link-button {
  display: inline-block;
  @include display-font;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  position: absolute;
  bottom: calc(12.5% - 40px);
  left: calc(8% - 20px);
  padding: 30px;
  transform: scale(0.5) translate3d(0, 0, 0);
  transform-origin: left;

  span {
    max-width: 140px;
    display: block;
  }

  .icon {
    transform: translate3d(0, 0, 0) rotate(160deg) scale(0.95);
    fill: $accent;
    top: -22px;
    left: 85px;
    position: absolute;
    pointer-events: none;
    transition: $transitionDefault;
  }

  &:hover,
  &:focus {
    transform: translate3d(0, 0, 0) scale(1.3);

    .icon {
      transform: translate3d(10px, -10px, 0) rotate(175deg) scale(0.95);
    }
  }

  @media #{$medium} {
    transform: scale(1) translate3d(0, 0, 0);
    left: calc(8% - 40px);
  }
}

.hero__title {
  margin: 50px 0;
}

.hero__text {
  max-width: 100ch;
}

#form-the-gutsy-captain-kombucha {
  margin-bottom: 50px;
}

.hero__scroll-button {
  background-color: transparent;
  color: $accent;
  width: 50px;
  height: 50px;
  position: absolute;
  bottom: 15px;
  left: 50%;
  margin-left: -25px;

  .icon {
    fill: $accent;
    width: 15px;
    height: 20px;
  }

  @media #{$medium} {
    bottom: 30px;
  }
}

.hero__scroll-button-2 {
  @include display-font;
  background-color: transparent;
  color: $white;
  text-transform: uppercase;
  text-decoration: none;
  width: 170px;
  bottom: 100px;
  left: 50%;
  position: absolute;
  margin-left: -85px;
  transform: scale(0) translate3d(0, 0, 0);
  letter-spacing: 1.5px;
  display: block;

  .hero--small & {
    bottom: 70px;
  }

  .icon {
    fill: $white;
    position: absolute;
    display: block;
    transform: rotate(310deg) scale(0.9);
    bottom: -45px;
    left: 63px;
  }

  @media #{$maxMedium} {
    bottom: 50px;

    .hero--small & {
      bottom: 50px;
    }

    span {
      transform: scale(0.5);
      display: block;
    }

    .icon {
      bottom: -35px;
      transform: rotate(310deg) scale(0.7);
    }
  }
}

// Jumbotron
.jumbotron {
  overflow: hidden;
}

.jumbotron__wrap {
  max-width: 1600px;
  margin: 0 auto;
}

.jumbotron__item {
  position: relative;

  .button {
    float: none;
    display: inline-block;
    color: $white;
    border-color: $white;
    text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.15);
    box-shadow: 3px 3px 3px 0px rgba(0, 0, 0, 0.15);
    position: relative;

    .icon {
      position: absolute;
      fill: $white;
      transition: $transitionDefault;
    }
  }

  .decorated {
    @include fluid-type(font-size, $smallSize, $largeSize, 70px, 150px);
    transition: $transitionDefault;
  }

  @media #{$medium} {
    .button {
      transform: scale(1.4);
    }
  }

  @media #{$large} {
    .button {
      transform: scale(1.3);
    }
  }

  @media #{$larger} {
    .button {
      transform: scale(1.2);
    }
  }

  @media #{$huge} {
    .button {
      transform: scale(1);
    }
  }
}

.jumbotron__item--drinks {
  .button {
    background-color: rgba($orange, 0.35%);

    .icon {
      transform: rotate(120deg) scale(0.9);
      bottom: 60px;
      left: calc(50% - 12px);
      filter: drop-shadow(-1px -3px 1px rgba(0, 0, 0, 0.15));
    }
  }

  .jumbotron__bg {
    opacity: 0.8;
    right: calc(-50% - 100px);
    left: -50%;
  }

  .jumbotron__bg--rev {
    transform: translateX(-100%) scaleX(-1);
  }

  .jumbotron__image {
    max-width: 390px;
    margin-bottom: 65px;
    padding-left: 30px;
  }

  .decorated {
    opacity: 0.5;
    margin-bottom: 40px;
  }

  @media #{$medium} {
    margin-right: -18%;

    .jumbotron__inner {
      padding-bottom: 93.867924528%;
    }

    .jumbotron__content {
      margin-left: -100px;
    }

    .jumbotron__bg {
      right: 0;
      left: 0;
    }
  }
}

.jumbotron__item--snacks {
  .button {
    background-color: rgba($accent, 0.35%);
    margin-bottom: 85px;

    .icon {
      transform: rotate(315deg) scale(0.9);
      top: 60px;
      left: calc(50% - 12px);
      filter: drop-shadow(1px 3px 1px rgba(0, 0, 0, 0.15));
    }
  }

  .jumbotron__link {
    padding-top: 55px;
  }

  .jumbotron__bg {
    opacity: 0.8;
    right: -50%;
    left: calc(-50% - 100px);
  }

  .jumbotron__bg--rev {
    transform: translateX(calc(100% - 2px)) scaleX(-1);
  }

  .jumbotron__image {
    max-width: 432px;
  }

  .decorated {
    opacity: 0.65;
  }

  @media #{$medium} {
    margin-left: -23%;

    .jumbotron__inner {
      padding-bottom: 90.06772009%;
    }

    .jumbotron__content {
      margin-right: -100px;
    }

    .jumbotron__bg {
      left: 0;
      right: 0;
    }
  }
}

.jumbotron__bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  z-index: -1;
}

.jumbotron__image {
  width: 100%;
  margin: 0 auto;
  transition: $transitionDefault;
}

.jumbotron__content {
  height: 100%;
  text-align: center;
  padding: 75px 40px;

  @media #{$medium} {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: scale(0.6);
  }

  @media #{$large} {
    transform: scale(0.7);
  }

  @media #{$larger} {
    transform: scale(0.8);
  }

  @media #{$huge} {
    transform: scale(1);
  }
}

.jumbotron__inner {
  @media #{$medium} {
    height: 0;
  }
}

.jumbotron__link {
  text-decoration: none;

  &:hover,
  &:focus {
    .decorated {
      transform: rotate(-2deg) scale(1.05);
    }

    .button {
      transform: translateY(2px);
      text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.15);
      box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.15);

      .jumbotron__item--drinks & {
        .icon {
          transform: translateY(-15px) rotate(120deg) scale(0.9);
        }
      }

      .jumbotron__item--snacks & {
        .icon {
          transform: translateY(15px) rotate(315deg) scale(0.9);
        }
      }
    }

    .jumbotron__image {
      transform: translateY(-5px);
    }
  }
}

// Social
.social {
  @include clearfix;
  list-style: none;
  margin: 0 0 0 -15px;
  display: inline-block;
}

.social__item {
  padding: 0 0 15px 15px;
  float: left;
}

.social__link {
  height: 28px;
  width: 28px;
  line-height: 0;
  display: block;
  fill: $accent;
  text-decoration: none;
  overflow: hidden;

  .icon {
    height: 28px;
    width: 28px;
  }
}

.social-grid__item {
  img {
    height: 250px;
    width: 250px;
    object-fit: cover;
  }
  &.link {
    position: relative;
    display: block;
    overflow: hidden;
    height: 0;
    padding-bottom: 100%;
    margin-bottom: 20px;
  }
}

// Social widget
.social-widget {

  &:hover,
  &:focus {
    .decorated {
      transform: translateY(-10px);
    }
  }

  .grid {
    margin-bottom: 60px;
    min-height: 100px;
  }

  .decorated {
    margin-bottom: 0px;
    transition: $transitionDefault;
    display: flex;
    justify-content: center;
  }

  @media #{$medium} {
    .decorated {
      margin-bottom: -50px;
    }
  }

  @media #{$large} {
    .decorated {
      margin-bottom: -75px;
    }
  }
}

.social-item-link {
  position: relative;
  display: block;
  overflow: hidden;
  height: 0;
  padding-bottom: 100%;
  margin-bottom: $gutter / 2;
  box-shadow: 10px 10px 15px 0px rgba(0, 0, 0, 0.15);

  &:hover,
  &:focus {
    .social-item-link__content {
      opacity: 1;
    }
  }
}

.social-item-link__image {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

@supports (object-fit: cover) {
  .social-item-link__image {
    object-fit: cover;
    height: 100%;
  }
}

.social-item-link__content {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
  transition: $transitionDefault;
  @include roboto-slab;
  display: flex;
  align-items: flex-end;
  padding: $gutter / 2;
  opacity: 0;
  text-align: left;
}

// Content section
.content-section {
  padding: 75px 0;
  overflow: hidden;

  +.content-section {
    padding-top: 0;
  }

  .link--back {
    position: relative;
    top: -45px;
  }

  @media #{$medium} {
    padding: 150px 0;

    .link--back {
      top: -90px;
    }
  }
}

.content-section--white {
  background-color: $white;
}

.content-section--padding-none {
  padding: 0;
}

.content-section--padding-bottom-none {
  padding-bottom: 0 !important;
}

.content-section--padding-top-none {
  padding-top: 0 !important;
}

.content-section--padding-small {
  padding-bottom: 30px;

  @media #{$medium} {
    padding-bottom: 75px;
  }
}

.content-section--padding-small-top {
  padding-top: 30px;

  @media #{$medium} {
    padding-top: 75px;
  }
}

.content-section--spacing {
  margin-top: 30px;
}

.content-section--oh {
  overflow: hidden;
}

.content-section--social {
  padding-top: 60px !important;
  margin-top: -30px;
}

.content-section__image {
  margin-top: 40px;
  margin-bottom: 40px;
  z-index: 1;
  position: relative;

  @media #{$medium} {
    margin-top: 125px;
    margin-bottom: 80px;
  }
}


// App
.app {
  position: relative;
  max-width: 290px;
  text-align: center;
  margin: 0 auto;
  padding-top: 60px;

  a {
    display: block;

    &:hover,
    &:focus {
      opacity: 0.8;
    }
  }

  &.text-left {
    margin: 0 0 0 -12px;
  }

  @media #{$medium} {
    padding-top: 0;
  }
}

.app__logo {
  margin-bottom: 15px;
  margin-top: -20px;
}

.app__title {
  @include display-font;
  color: $accent;
  position: absolute;
  top: 65px;
  width: 130px;
  text-transform: uppercase;
  text-align: center;
  line-height: 1.2em;
  right: -70px;
  letter-spacing: 1px;
  transform: scale(0.6);
  transform-origin: left;

  .icon {
    fill: $accent;
    position: absolute;
    bottom: -50px;
    left: 40px;
    transform: rotate(-6deg);
  }

  @media #{$large} {
    transform: scale(1);
    margin: 0;
    right: -100px;
  }

  @media #{$huge} {
    right: -150px;
  }
}

.app__apple {
  a {
    padding: 6.5% 11%;
    transform: translateX(-7%);
  }
}

.tags {
  margin-top: 40px;
}

.tag {
  @include roboto-slab;
  @include fs(15);
  text-transform: uppercase;
  color: $accent;
  border: 1px solid $accent;
  border-radius: 5px;
  line-height: 29px;
  height: 30px;
  padding: 0 10px;
  display: inline-block;
  float: left;
  margin: 0 10px 10px 0;
}

// Image grid
.image-grid {
  margin-top: -30px;

  .grid__item {
    align-items: flex-end;
  }
}

.image-grid__item {
  height: 0;
  padding-bottom: 65.625%;
  margin-bottom: $gutter / 2;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;

  img {
    display: none;
  }

  .medium--one-whole & {
    @media #{$medium} {
      padding-bottom: 38.8888889%;
      background-position: bottom center;
    }
  }
}

.image-grid__item--portrait {
  padding-bottom: 119.047619048%;
}

.image-grid__item--square {
  padding-bottom: 100%;
}

.shop-button {
  height: 80px;

  a {
    position: fixed;
    border-radius: 10px;
    right: 50%;
    bottom: 20px;
    z-index: 10;
    width: 100px;
    overflow: hidden;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
    transform: translateX(50%);

    &:hover, &:focus {
      box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.2);
    }
  }

  @media #{$medium} {
    height: auto;

    a {
      width: 150px;
      right: 40px;
      bottom: 45px;
      box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
      transform: translateX(0);

      &:hover, &:focus {
        box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);
      }
    }
  }
}

.shop-modal {
  a {
    padding: 0;

    &:after {
      display: none;
    }
  }
}

.rounded {
  border-radius: 20px;
  overflow: hidden;
}

/* =============================================================================
   PAGES
   ========================================================================== */

// Brands
.brands-intro {
  padding-top: 50px;
  text-align: center;

  @media #{$medium} {
    text-align: right;
  }
}

.product-grid {

  &:hover,
  &:focus,
  .animating {
    .grid__item {
      z-index: 1;
    }
  }

  .grid__item {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      right: 0;
      z-index: -1;
      top: 25px;
      bottom: 25px;
      @include dashed-vertical;
    }

    &:hover,
    &:focus,
    &.hover {
      z-index: 2;
    }

    &:nth-child(2n) {
      &:after {
        display: none;
      }
    }
  }

  @media #{$medium} {
    .grid__item {
      &:nth-child(2n) {
        &:after {
          display: block;
        }
      }

      &:nth-child(3n) {
        &:after {
          display: none;
        }
      }
    }
  }

  @media #{$large} {
    .grid__item {
      &:nth-child(3n) {
        &:after {
          display: block;
        }
      }

      &:nth-child(4n) {
        &:after {
          display: none;
        }
      }
    }
  }
}

.product {
  position: relative;
  display: block;
  height: 0;
  padding-bottom: 78.125%;

  &:before,
  &:after {
    content: '';
    position: absolute;
    z-index: -1;
    @include dashed-horizontal;
    left: 0;
    right: 0;
    top: 0;
  }

  &:after {
    top: 100%;
  }

  &:hover,
  &:focus,
  .hover & {
    .product__bg.loaded {
      opacity: 1;
    }

    .product__logo {
      opacity: 0;
    }

    .product__label {
      opacity: 0.9;
    }
  }
}

.product__bg {
  position: absolute;
  top: 1px;
  right: 1px;
  bottom: 0;
  left: 1px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.2);
  opacity: 0;
  transition: $transitionSlow;
}

.product__inner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
}

.product__logo {
  z-index: 2;
  position: absolute;
  top: 1px;
  right: 1px;
  bottom: 0;
  left: 1px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: $transitionSlow;

  img {
    display: none;
  }

  &.loaded {
    opacity: 1;
  }
}

.product__label {
  background-color: $accent;
  color: $white;
  display: block;
  height: 30px;
  line-height: 30px;
  text-transform: lowercase;
  text-align: center;
  @include display-font;
  @include fs(12);
  z-index: 3;
  position: absolute;
  width: 100%;
  right: 0;
  bottom: 0;
  transform-origin: center;

  @media #{$small} {
    height: 35px;
    line-height: 35px;
    transform: translate(34%, -56%) rotate(315deg);
    @include fs(14);
  }

  @media #{$medium} {
    transform: translate(37%, -56%) rotate(315deg);
  }
}

.product__label--bio {
  background-color: $green;
  right: auto;
  bottom: auto;
  top: 0;
  left: 0;
  text-transform: uppercase;

  @media #{$small} {
    transform: translate(-34%, 56%) rotate(315deg);
  }

  @media #{$medium} {
    transform: translate(-37%, 56%) rotate(315deg);
  }
}

// Brand detail
.brand-navigation {
  display: block;
  display: flex;
  position: fixed;
  top: 40%;
  z-index: 111;
  width: 50px;
  height: 50px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  text-transform: uppercase;
  text-decoration: none;
  align-items: center;
  transform: scale(1) translateY(-50%);

  i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    &:before {
      @include fs(30);
    }
  }

  &:hover,
  &:focus {
    opacity: 0.8;
  }

  span {
    color: $accent;
    @include display-font;
    @include fs(14);
    justify-content: center;
    display: none;
    line-height: 1.4em;

    :lang(fr) & {
      @include fs(12);
    }
  }

  @media #{$medium} {
    width: 120px;
    height: 70px;
    transform: scale(0.8) translateY(-50%);
    top: 275px;

    span {
      display: block;
      display: flex;
    }
  }

  @media #{$large} {
    transform: scale(1) translateY(-50%);
  }
}

.brand-navigation--previous {
  left: 0;
  flex-direction: row-reverse;
  text-align: center;
  padding: 0 18px 0 15px;
  transform-origin: left;

  &:before {
    left: 0;
    right: 20px;
  }

  i {
    left: 15px;
  }

  @media #{$medium} {
    i {
      left: 5px;
    }
  }
}

.brand-navigation--next {
  right: 0;
  text-align: center;
  padding: 0 15px 0 18px;
  transform-origin: right;

  i {
    right: 15px;
  }

  @media #{$medium} {
    i {
      right: 5px;
    }
  }
}

.product-hero {
  min-height: 350px;
  background-color: $white;

  +.content-section {
    padding-top: 0;
  }
}

.product-hero__content {
  min-height: 350px;
  padding: 0 0 30px;

  .rte {
    padding-bottom: 15px;
  }

  .container,
  .grid,
  .grid__item {
    height: 100%;
  }

  .grid__item {
    display: flex;
    align-items: center;
  }

  .medium--four-twelfths {
    position: relative;
  }
}

.product-hero__logo-wrap {
  height: 0;
  padding-bottom: calc(87.5% - 30px);
}

.product-hero__logo {
  position: relative;
  @extend .block-link;
  text-align: center;
  max-width: 420px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
}

.product-hero__link {
  color: $accent;
  @extend .block-link__target;
  text-decoration: none;
  margin-bottom: 0;

  span {
    text-decoration: underline;
    @include roboto-slab;
  }
}

.product-hero__description {
  max-height: 90px;
  overflow: hidden;
  transition: max-height 0.5s $easeIn;
  margin-top: 90px;
  margin-bottom: 10px;

  .rte {
    padding-bottom: 0;
  }

  &.active {
    max-height: 1000px;

    +.link {
      .link__more {
        display: none;
      }

      .link__less {
        display: inline-block;
      }
    }
  }

  +.link {
    .link__more {
      display: inline-block;
    }

    .link__less {
      display: none;
    }
  }
}

.product-header {
  overflow: hidden;
  text-align: center;
  position: relative;
  margin-bottom: 20px;

  .decorated {
    color: rgba($accent, 0.15);
    margin-bottom: 15px;
  }

  @media #{$medium} {
    min-height: 190px;
    margin-bottom: 0;
    display: flex;
    align-items: flex-end;
    justify-content: center;

    .decorated {
      transform: translateY(23%);
      margin-bottom: 0;
    }
  }
}

.product-sheet__button {
  display: inline-block;

  &:hover,
  &:focus {
    .button--download {
      @extend .button--download:hover;
    }
  }

  .button--download {
    margin-bottom: 0;
  }

  @media #{$medium} {
    position: absolute;
    bottom: 20px;
    right: 0;
    margin-bottom: 0;
  }
}

.product-sheet__thumbnail {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  width: 50px;
  height: 50px;
  float: left;

  +.button--download {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.product-category {
  background-color: $white;
  border: 4px solid rgba($accent, 0.15);
  margin-bottom: 25px;
  min-height: 350px;
  border-radius: 5px;
  position: relative;
}

.product-category__title {
  color: $accent;
  @include display-font;
  @include fs(18);
  text-align: center;
  padding-top: 20px;

  span {
    display: block;
  }

  @media #{$medium} {
    position: absolute;
    left: 35px;
    width: 25px;
    height: 25px;
    bottom: 50%;
    transform: translateY(50%) rotate(270deg);
    display: flex;
    justify-content: center;
    white-space: nowrap;
    text-align: left;
    padding-top: 0;

    span {
      width: 350px;
      top: 0;
      right: 50%;
    }
  }

  @media #{$large} {
    left: 65px;
  }
}

.product-category__list {
  margin: 0;
  width: 100%;
  height: 100%;
  justify-content: center;

  li {
    height: 100%;
    width: 50%;
  }

  @media #{$small} {
    li {
      width: 33.33%;
    }
  }

  @media #{$medium} {
    padding: 0 0 0 50px;
    justify-content: flex-start;

    li {
      float: left;
      min-height: 350px;
      width: auto;
    }
  }

  @media #{$large} {
    padding: 0 0 0 100px;
  }
}

.product-item {
  width: 100%;
  padding: 20px;

  @media #{$medium} {
    width: 230px;
    padding: 30px 40px;
  }
}

.product-item__image {
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  mix-blend-mode: multiply;
  height: 0;
  padding-bottom: calc((1200 / 800) * 100%);

  img {
    display: none;
  }
}

.product-item__title {
  text-align: center;
  @include roboto-slab;
  font-weight: 300;
  @include fs(12);
  line-height: 1.2em;
  color: $accent;

  @media #{$medium} {
    @include fs(14);
    line-height: 1.4em;
  }
}

.product-order {
  padding-top: 10px;

  .contact-block {
    padding-top: 0;
    margin-top: -5px;
    text-align: center;
  }

  .arrow-label--left {
    display: inline-block;
    line-height: 1.4em;
    max-width: 200px;

    .icon {
      transform: rotate(260deg);
      left: auto;
      right: -60px;
      bottom: -5px;
    }
  }

  .app {
    padding-top: 0;
    margin-bottom: 40px;
  }

  @media #{$medium} {
    .contact-block {
      text-align: left;
    }

    .app {
      margin-bottom: 0;
    }

    >.grid>.grid__item:first-child {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        height: 100%;
        width: 1px;
        right: calc(-25% - 20px);
        top: 0;
        @include dashed-vertical;
      }
    }
  }
}

.product-order__image {
  height: 0;
  padding-bottom: 50%;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;

  img {
    display: none;
  }
}


// Jobs
.job {
  position: relative;
  padding: 25px 0;
  @extend .block-link;

  &:after {
    content: '';
    position: absolute;
    z-index: -1;
    @include dashed-horizontal;
    height: 1px;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .button {
    margin-top: 20px;
    @extend .block-link__target;
  }

  @media #{$medium} {
    .button {
      margin-top: 0;
      float: right;
    }

    .grid {
      display: flex;
      align-items: center;
    }
  }
}

.job__title {
  @include display-font;
  color: $accent;
  margin: 0;
}

.job__location {
  @include display-font;
  color: $grayDark;
  margin: 0;

  .icon {
    vertical-align: middle;
    margin-right: 5px;
  }

  span {
    vertical-align: middle;
  }
}

.job-graphic {
  max-width: 150px;
  margin: 40px auto 40px;

  @media #{$medium} {
    max-width: 280px;
    margin-top: -65px;
    float: right;
  }
}

.cta {
  .button {
    padding-left: 35px;
    padding-right: 30px;
  }
}

.cta--center {
  .cta__tagline {
    margin-bottom: 0;
    text-align: right;
  }

  @media #{$medium} {
    .cta__tagline {
      margin-top: -40px;
    }
  }
}

.cta__tagline {
  @include display-font;
  color: $accent;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  clear: both;
  margin-bottom: -100px;
  text-align: right;
  padding-top: 40px;

  span {
    vertical-align: middle;
    margin-left: 15px;
  }

  .icon {
    fill: $accent;
    transform: translateY(-20px) rotate(90deg) scale(1.1);
  }

  @media #{$medium} {
    margin-bottom: -115px;
    transform: translate(35px, 40px);
    clear: none;
    text-align: left;
    padding-top: 0;

    .icon {
      transform: translateY(-7px) rotate(60deg) scale(1.1);
    }
  }
}


// Contact
.contact-block {
  @extend .page-footer__block;
  text-align: right;
  margin-bottom: 70px;

  h1 {
    @include fluid-type(font-size, $mediumSize, $largeSize, 18px, 23px);
    line-height: 1.5em;
    margin-right: 0;
    margin-left: -10px;
    padding: 35px 0 0;
    margin-bottom: -15px;
  }
}

.contact-bg {
  height: 180px;
  margin-bottom: 40px;
  position: relative;
}

.contact-bg__inner {
  position: absolute;
  top: 0;
  background-repeat: no-repeat;
  background-size: contain;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    background-repeat: no-repeat;
    background-size: contain;
    transform: scaleX(-1);
  }
}

.contact-bg--right {
  display: none;

  .contact-bg__inner {
    height: 180px;
    width: 690px;
    left: 0;
    background-image: url('../images/bg-contact-1.png');

    &:after {
      background-image: url('../images/bg-contact-1.png');
      height: 180px;
      width: 690px;
      left: 100%;
    }
  }

  @media #{$medium} {
    display: block;
  }
}

.contact-bg--left {
  margin-right: 40px;

  .contact-bg__inner {
    height: 180px;
    width: 717px;
    right: 0;
    background-image: url('../images/bg-contact-2.png');

    &:after {
      background-image: url('../images/bg-contact-2.png');
      height: 180px;
      width: 717px;
      right: 100%;
    }
  }

  @media #{$medium} {
    margin-right: 0;
  }
}


// Landing page
.landing {
  .main {
    padding: 0;
  }

  h1 {
    color: $white;
    @include cut-out-jams;
    @include fs(60);
    line-height: 0.85em;
    text-align: left;
    max-width: 265px;
    margin-left: auto;
    margin-right: auto;
    letter-spacing: 6px;
  }

  .logo-single {
    fill: $white;
    width: 40px;
    height: 40px;
    margin-right: 6px;
  }

  @media #{$medium} {
    h1 {
      @include fs(82);
      max-width: 340px;
    }

    .logo-single {
      width: 55px;
      height: 55px;
    }
  }
}

.landing__bg {
  min-height: 100vh;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding: 60px 0;
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;

  @media #{$medium} {
    padding: 125px 100px;
  }
}

.landing__logo {
  margin-bottom: 40px;
  display: inline-block;

  .logo {
    width: 79px;
    height: 100px;
    fill: $white;
  }

  @media #{$medium} {
    margin-bottom: 80px;

    .logo {
      width: 119px;
      height: 150px;
    }
  }
}

.landing__shape {
  position: absolute;

  img {
    position: absolute;
  }
}

.landing__shape--top,
.landing__shape--bottom {
  padding-bottom: 3.472222222%;
  height: 0;
  left: -1%;
  right: -1%;

  img {
    right: 0;
    left: 0;
  }
}

.landing__shape--top {
  top: -1px;

  img {
    top: 0;
  }
}

.landing__shape--bottom {
  bottom: -1px;

  img {
    bottom: 0;
  }
}

.landing__shape--right,
.landing__shape--left {
  top: 0;
  bottom: 0;
  width: 100px;
  display: none;

  img {
    top: 0;
    height: 100%;
  }

  @media #{$medium} {
    display: block;
  }
}

.landing__shape--right {
  right: 0;

  img {
    right: 0;
  }
}

.landing__shape--left {
  left: 0;

  img {
    left: 0;
  }
}


// ABOUT
.about {
  @media #{$maxMedium} {
    #content {
      padding-bottom: 0;

      .content-section__image {
        margin-bottom: 20px;
      }
    }
  }
}

// Little miracles
.grid__item--bottom {
  display: flex;
  align-items: flex-end;
}

.grid__item--center {
  display: flex;
  justify-content: center;
}

.title--color-accent {
  @include fluid-type(font-size, $smallSize, $largeSize, 1.5em, 2.5em);
  font-size: 2.5em;
}

.little-miracle {
  h1:first-of-type {
    margin-bottom: 60px;
  }

  h3 {
    @media #{$medium} {
      margin-top: 30px;
    }
  }

  ol {
    counter-reset: item;

    li {
      display: block;

      &:before {
        content: counter(item) ". ";
        counter-increment: item;
        font-weight: 700;
        @include roboto-slab;
      }
    }
  }

  .hero--small {
    @media #{$massive} {
      height: 800px;
    }
  }

  .main-nav {
    ul {
      li {
        @media #{$medium} {
          &:nth-child(1):after {
            content: '';
            height: 14px;
            width: 200px;
            display: inline-block;
            transition: $transitionDefault;
          }
        }
      }
    }
  }

  .grid__item--bottom {
    .title--color-accent {
      margin: 30px 0;

      @media #{$medium} {
        margin: 0;
        margin-bottom: 60px;
      }
    }
  }

  .container {
    .grid:nth-child(n+2) {
      margin-top: 10px;

      @media #{$medium} {
        margin-top: 60px;
      }
    }
  }

  .grid__item--overlap {
    z-index: 1;
  }

  .arrow-label {
    font-size: .7em;

    @media #{$medium} {
      @include display-font;
      font-size: 100%;
    }
  }

  .decorated {
    text-transform: none;
    @include fluid-type(font-size, $smallSize, $largeSize, 55px, 140px);
    white-space: normal;

    &:nth-child(2) {
      display: none;
    }

    @media #{$medium} {
      &:nth-child(2) {
        display: block;
        @include overlap(100px);
      }

      &:nth-child(1) {
        @include overlap(20px);
      }
    }

  }

  .equal-h>.grid__item--seperated:first-child {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      height: 80%;
      width: 1px;
      right: -20px;
      top: 0;
      @include dashed-vertical;
    }
  }
}

.little-miracle--success {
  .button {
    margin: 0 10px;
  }

  .decorated {
    margin-bottom: 60px;

    @media #{$medium} {
      margin-bottom: 100px;
    }
  }
}

.arizona {

  .upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
  }

  .btn {
    border: 1px solid $accent;
    cursor: pointer;
    color: $accent;
    background-color: white;
    padding: 6px 14px;
    border-radius: 5px;
    height: 40px;
    @include fs(14);
  }

  .upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
  }

  .form__terms-conditions {
    margin-bottom: 0;
  }

  .hero--small {
    height: 150px;
    padding-bottom: 30%;
  }

  @media #{$small} {
    .hero--small {
      height: 150px;
    }
  }

  @media #{$medium} {
    .hero--small {
      height: 300px;
      padding-bottom: 0;
    }
  }

  @media #{$large} {
    .hero--small {
      height: 400px;
    }
  }
  @media #{$massive} {
    .hero--small {
      height: 650px;
    }
  }

}

.giveaway__banner {
  background-color: #00a1db;

  img {
    height: 75vh;
    object-fit: cover;
    margin: auto;
    display: block;
  }
}

/* =============================================================================
   PRINT
   Inlined to avoid required HTTP connection: h5bp.com/r
   ========================================================================== */
@media print {
  @page {
    margin: 2cm 0.5cm 1cm;
  }

  html {
    font-size: 10px;
  }

  * {
    background-color: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }

  .ir {
    text-indent: 0;
    overflow: visible;
    direction: inherit;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  .page-footer,
  .main-nav {
    display: none;
  }

  .medium--one-half {
    width: 50%;
  }

  .medium--two-thirds {
    width: 66.66%;
  }

  .medium--one-third {
    width: 33.33%;
  }

  .medium--three-quarters {
    width: 75%;
  }

  .medium--one-quarter {
    width: 25%;
  }
}
